import axios from 'axios'
import saveError from '../Utilities/saveError'
import { createQueryString } from "../Utilities/refineOptions";
const API_URL='/api/users/';
const config = {params: {relations:['role']}};
const refineOptionList = [
    "filterBy",
    "filterId"
  ];
const UserAPI = {
    getUsers: async () => {
      return new Promise((resolve, reject) => {
        axios(API_URL, config)
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject)
        });
      })
    },

    getUser: async (id) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + id)
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject)
        });
      })
    },

    filterUsers: async customQuery => {
      return new Promise((resolve, reject) => {
        const queryString = customQuery
          ? customQuery
          : createQueryString("user", refineOptionList);
        axios.get(API_URL + queryString, config)
          .then(response => resolve(response.data))
          .catch(error => {
            saveError(error, reject)
          });
        })
      },

    getUserByEmail: async (email) => {
      return new Promise((resolve, reject) => {
        axios('/auth/' + email)
        .then(response => resolve(response))
        .catch(error => {
            saveError(error, reject)
        });
      })
    },
    
    saveUser: async (url, method, values) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + url, {
            method: method,
            data: JSON.stringify(values),
            headers: {
              'Content-Type': 'application/json'
            },
        })
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject);
        });
      })
    },
}
export default UserAPI;
