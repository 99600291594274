import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {upperFirst, debounce} from 'lodash';

import Auth from '../Auth/Auth'
import FilterSelect from '../Components/FilterSelect'
import SortCard from '../Components/SortCard'

import EditionAPI from '../Editions/EditionAPI';
import SpeakerAPI from './SpeakerAPI'
import Loading from '../Components/Loading';
import { getRefineOptions, verifyId } from '../Utilities/refineOptions';
import { getCurrentEdition } from '../Utilities/currentEdition';
import { showVisibilityState } from '../Utilities/format';
import NotificationMessage from "../Components/NotificationMessage";

const refineOptionList = ['keyword', 'sortBy', 'sortDir', 'filterBy', 'filterId'];
const filterBy = ['edition', 'status'];
const filterByIndices = { edition: 0, status: 1 };

class SpeakerList extends Component {
    constructor(props) {
        super(props);
        let message;
        const refineOptions = getRefineOptions('speaker', refineOptionList);
        this.debounceSearch = debounce(this.searchSpeakers, 350)
        if (this.props.location.state && this.props.location.state.deletedObject) {
            message = 'Speaker successfully deleted';
        }
        const filterId = ['All', 'All'];
        this.state = {
            message,
            speakers: null,
            filterBy,
            filterId,
            editions: [{ "id": "none", "name": "" }],
            statuses: [{ "id": "draft", "name": "Draft" }, { "id": "published", "name": "Published" }, { "id": "scheduled", "name": "Scheduled" }],
            sortBy: 'name',
            sortDir: 'ASC',
            keyword: '',
            refineOptions,
        };
        this.debounceSearch = debounce(this.searchSpeakers, 300)
        this.notify = React.createRef();
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }
    async componentDidMount() {
        document.title = `Speakers | ${process.env.REACT_APP_TITLE}`;

        await EditionAPI.getEditions()
        .then(async res => {
            await this.setStateAsync({ editions: this.state.editions.concat(res.editions)});
        })
        .catch(err => this.sendNotification(`Could not load edition options (${err.response.status})`, "error"));

        let filterId = this.state.filterId;
        filterId[filterByIndices.edition] = await getCurrentEdition();
        await this.setStateAsync({ filterId });

        for (const i in refineOptionList) {
            const option = refineOptionList[i];
            const key = 'speaker' + upperFirst(option);
            if (!localStorage[key]) {
                localStorage.setItem(key, this.state[option]);
            } else if (this.state[option] !== this.state.refineOptions[option]) {
                await this.setStateAsync({ [option]: this.state.refineOptions[option] });
            }
            if (option === 'filterId') {
                let verifiedIds = this.state.filterId.slice();
                const skipIds = ['All', 'none'];
                for (const j in this.state.filterId) {
                    if (filterBy[j] === 'edition' && !skipIds.includes(verifiedIds[j])) {
                        verifiedIds[j] = verifyId(this.state.editions, verifiedIds[j]);
                    }
                }
                if (verifiedIds !== this.state.filterId) {
                    this.setState({ filterId: verifiedIds });
                    localStorage.setItem(key, verifiedIds);
                }
            }
        }

        this.fetchSpeakers()
        .then(() => this.setState({mounted: true}))
        .catch(err => {
          this.setState({mounted: true})
          this.sendNotification(`Could not load speakers (${err.response.status})`, "error")
        });
    }

    fetchSpeakers = async () => {
      return new Promise((resolve, reject) => {
        SpeakerAPI.filterSpeakers()
        .then(res => {
            this.setState({ speakers: res.speakers });
            resolve(res)
        })
        .catch(err => {reject(err)});
      })
    }

    setFilter = (value, type) => {
        const index = filterByIndices[type];
        const ids = this.state.filterId;
        let newIds = ids;
        newIds[index] = value;
        localStorage.setItem('speakerFilterId', newIds);
        this.fetchSpeakers()
        .then(() => {
            this.setState({filterId: newIds});
        })
        .catch((err) => {
            this.sendNotification('Could not filter sepeakers', 'error')
            localStorage.setItem('speakerFilterId', this.state.filterId);
        })
    }

    onSortAttribute = (attribute, direction) => {
        if (this.state.sortBy === attribute && this.state.sortDir === direction) return;

        localStorage.setItem('speakerSortBy', attribute);
        localStorage.setItem('speakerSortDir', direction);

        this.fetchSpeakers()
        .then(() => this.setState({sortBy: attribute, sortDir: direction}))
        .catch((err) => {
            this.sendNotification('Could not sort speakers', 'error')
            localStorage.setItem('speakerSortBy', this.state.sortBy);
            localStorage.setItem('speakerSortDir', this.state.sortDir);
        })
    }
    
    searchSpeakers = () => {
        const regex = new RegExp(/[.*+?^${}()|[\]\\]/);
        if (regex.test(this.state.keyword)) {
            this.setState({ speakers: null})
        } else {
            localStorage.setItem('speakerKeyword', this.state.keyword);
            this.fetchSpeakers()
            .catch(err => this.sendNotification('Could not search speakers', 'error'));
        }
    }

    updateKeyword = (e) => {
      this.setState({
          keyword: e.target.value
      });
      this.debounceSearch()
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }

    render = () => {
        const Card = (speaker) => (
            <div className="column">
                <div className="card">
                    <div className="card-divider grid-x">
                        <div className="fluxible_event_type cell small-9">{speaker.edition ? speaker.edition.name : ''}</div>
                        <div className="cell small-3">{showVisibilityState(speaker.publishedOn)}</div>
                    </div>
                    <div className="card-section text-centered">
                        <h4><Link to={`/speakers/${speaker.id}/edit`}>{speaker.name}</Link></h4>
                        <p><em>{speaker.jobPosition ? speaker.jobPosition : ''}</em></p>
                    </div>
                </div>
            </div>

        );

        const speakerCards = this.state.speakers && this.state.speakers.map((speaker) =>
            <Card {...speaker} key={speaker.id} />);

        const sortAttributes = ['name', 'uploadedOn'];
        const sortType = { attribute: this.state.sortBy, direction: this.state.sortDir }
        const sortCards = sortAttributes.map((attribute) => <SortCard attribute={attribute} sortType={sortType} onSortAttribute={this.onSortAttribute} key={`sortcard${attribute}`} />);


        return (
            <div>
                {!this.state.mounted && <Loading />}

                <div className="grid-x">
                  <div className="cell small-4">
                    {Auth.hasRole(['admin', 'editor']) ?
                      (<Link id="addSpeakerButton" to="/speakers/new" className="button">Add Speaker</Link>) : null
                    }
                  </div>
                  <div className="cell small-8">
                    <NotificationMessage message={this.state.message} small={true} ref={this.notify} />
                  </div>
                </div>

                <div> {this.state.message}</div>

                <form>
                    <input type="text" className="search" placeholder="Search either by speaker name OR role/affiliation" value= {this.state.keyword} onChange={this.updateKeyword} />
                </form>

                <label>
                    Sort by attribute:
                </label>
                <div className="grid-x grid-padding-x">
                    {sortCards}
                </div>

                <div className="grid-x grid-padding-x">
                    <div className="medium-3 cell">
                        <label>Filter by Edition:
                            <FilterSelect options={this.state.editions} setSelectedFilter={this.setFilter} type="edition" value={this.state.filterId[filterByIndices.edition]} />
                        </label>
                    </div>
                    <div className="medium-3 cell">
                        <label>Filter by Status:
                            <FilterSelect options={this.state.statuses} setSelectedFilter={this.setFilter} type='status' value={this.state.filterId[filterByIndices.status]} />
                        </label>
                    </div>
                </div>

                <div className="row small-up-1 medium-up-2 large-up-3 align-center">
                    {speakerCards}
                </div>

            </div>
        )
    }
}

export default SpeakerList;
