import React, { Component } from 'react';
import { Router } from "react-router-dom";
import './Styles/app.scss';
import "react-widgets/scss/styles.scss";
import history from './Utilities/history'
import Menu from './Components/Menu'
import Navbar from './Components/Navbar'
import { MakingMainRoutes } from './Utilities/routes'
import Auth from './Auth/Auth'

import $ from 'jquery';
import Foundation from 'foundation-sites';
window.jQuery = window.$ = $;
Foundation.addToJquery($);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingSession: true,
    }
  }

  async componentDidMount() {
    $(document).foundation();
    if (history.location.pathname === '/callback') {
       this.setState({checkingSession:false});
       return;
    }
    try {
      await Auth.silentAuth();
      this.forceUpdate();
    } catch (err) {
      if (err.error !== 'login_required') console.log(err.error);
    }
    this.setState({checkingSession:false});
  }

  render() {
    return (
      <Router history={history}>
        <div className="App grid-container full">
          <Navbar />
          <div className="grid-x grid-padding-x grid-padding-y text-left">
            <div className="cell medium-2">
              <nav className="sticky-container" data-sticky-container>
                <div className="sticky" data-sticky data-anchor="content" data-sticky-on="medium">
                  <Menu />
                </div>
              </nav>
            </div>
            <div id="content" className="cell medium-10">
              <MakingMainRoutes checkingSession={this.state.checkingSession} />
            </div>
          </div>
        </div>
      </Router>    
    );
  } 
}

export default App;
