import React from 'react';
import { SpeakerForm, SpeakerList } from "./index"

export const ListSpeakers = (props) => (
    <div>
        <h2>Speakers</h2>
        <SpeakerList {...props} />
    </div>
);

export const AddSpeaker = () => (
    <div>
        <h2>Add a speaker</h2>
        <SpeakerForm />
    </div>
);

export const EditSpeaker = ({ match }) => (
    <div>
      <h2>Edit a speaker</h2>
      <SpeakerForm id={match.params.id} />
    </div>
);
