import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SecuredRoute from './securedRoute';
import Home from '../Home/Home'
import Callback from '../Callback/Callback';
import { AddSpeaker, EditSpeaker, ListSpeakers } from "../Speakers";
import { AddEvent, EditEvent, ListEvents } from '../Events'
import { AddLocation, EditLocation, ListLocations } from "../Locations";
import { AddFestivalInfrastructure, EditFestivalInfrastructure, ListFestivalInfrastructure } from "../FestivalInfrastructure";
import { AddSponsor, EditSponsor, ListSponsors } from "../Sponsors";
import { AddMedia, EditMedia, ListMedia } from "../Media";
import { AddEventType, EditEventType, ListEventTypes } from '../EventTypes'
import { AddSponsorType, EditSponsorType, ListSponsorTypes } from '../SponsorTypes'
import { AddEdition, EditEdition, ListEditions } from '../Editions';
import { ListTags } from '../Tags';
import { ListTestimonials } from '../Testimonials';
import { AddUser, EditUser, ListUsers } from '../Users';
import { AddBlogPost, EditBlogPost, ListBlogPosts } from '../BlogPosts';
import { AddPage, EditPage, ListPages } from '../Pages';
import { CurrentEditionPage } from '../CurrentEdition'
import { BuildInfoPage } from '../Build';

export function MakingMainRoutes({ checkingSession }) {
  return (
    <Switch>
      <Route exact path="/" component={Home}/>
      <SecuredRoute path="/speakers/new" component={AddSpeaker} checkSession={checkingSession}/>
      <SecuredRoute path="/speakers/:id/edit" component={EditSpeaker} checkSession={checkingSession}/>
      <SecuredRoute exact={true} path="/speakers" component={ListSpeakers} checkSession={checkingSession}/>
      <SecuredRoute exact={true} path="/locations" component={ListLocations} checkSession={checkingSession}/>
      <SecuredRoute path="/locations/new" component={AddLocation} checkSession={checkingSession} />
      <SecuredRoute path="/locations/:id/edit" component={EditLocation} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/FestivalInfrastructure" component={ListFestivalInfrastructure} checkSession={checkingSession} />
      <SecuredRoute path="/FestivalInfrastructure/new" component={AddFestivalInfrastructure} checkSession={checkingSession} />
      <SecuredRoute path="/FestivalInfrastructure/:id/edit" component={EditFestivalInfrastructure} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/sponsors" component={ListSponsors} checkSession={checkingSession} />
      <SecuredRoute path="/sponsors/new" component={AddSponsor} checkSession={checkingSession} />
      <SecuredRoute path="/sponsors/:id/edit" component={EditSponsor} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/media" component={ListMedia} checkSession={checkingSession} />
      <SecuredRoute exact={true} path="/media/new" component={AddMedia} checkSession={checkingSession} />
      <SecuredRoute path="/media/:id/edit" component={EditMedia} checkSession={checkingSession} />
      <SecuredRoute path="/media/:page" component={ListMedia} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/events/new" component={AddEvent} checkSession={checkingSession} />
      <SecuredRoute path="/events/:id/edit" component={EditEvent} checkSession={checkingSession} />
      <SecuredRoute exact={true} path="/events" component={ListEvents} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/eventTypes/new" component={AddEventType} checkSession={checkingSession} />
      <SecuredRoute path="/eventTypes/:id/edit" component={EditEventType} checkSession={checkingSession} />
      <SecuredRoute exact={true} path="/eventTypes" component={ListEventTypes} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/sponsorTypes/new" component={AddSponsorType} checkSession={checkingSession} />
      <SecuredRoute path="/sponsorTypes/:id/edit" component={EditSponsorType} checkSession={checkingSession} />
      <SecuredRoute exact={true} path="/sponsorTypes" component={ListSponsorTypes} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/editions/new" component={AddEdition} checkSession={checkingSession} />
      <SecuredRoute path="/editions/:id/edit" component={EditEdition} checkSession={checkingSession} />
      <SecuredRoute exact={true} path="/editions" component={ListEditions} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/tags" component={ListTags} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/testimonials" component={ListTestimonials} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/blogPosts" component={ListBlogPosts} checkSession={checkingSession} />
      <SecuredRoute path="/blogPosts/new" component={AddBlogPost} checkSession={checkingSession} />
      <SecuredRoute path="/blogPosts/:id/edit" component={EditBlogPost} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/pages" component={ListPages} checkSession={checkingSession} />
      <SecuredRoute path="/pages/new" component={AddPage} checkSession={checkingSession} />
      <SecuredRoute path="/pages/:id/edit" component={EditPage} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/users" component={ListUsers} checkSession={checkingSession} />
      <SecuredRoute path="/users/new" component={AddUser} checkSession={checkingSession} />
      <SecuredRoute path="/users/:id/edit" component={EditUser} checkSession={checkingSession} />

      <SecuredRoute exact={true} path="/build" component={BuildInfoPage} checkSession={checkingSession} />
      <SecuredRoute exact={true} path="/currentEdition" component={CurrentEditionPage} checkSession={checkingSession}/>
      <Route path="/callback" component={Callback} />
    </Switch>
  );
}
