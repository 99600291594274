import React from 'react';
import { Link } from 'react-router-dom'
import moment from 'moment-timezone';
import {upperFirst, debounce} from 'lodash';

import Auth from '../Auth/Auth'
import EditionAPI from '../Editions/EditionAPI';
import EventAPI from './EventAPI'
import EventTypeAPI from '../EventTypes/EventTypeAPI';
import Loading from '../Components/Loading';
import FilterSelect from '../Components/FilterSelect'
import SortCard from '../Components/SortCard'
import LocationAPI from '../Locations/LocationAPI';
import { getRefineOptions, verifyId } from '../Utilities/refineOptions';
import { getCurrentEdition } from '../Utilities/currentEdition';
import { showVisibilityState } from '../Utilities/format';
import NotificationMessage from "../Components/NotificationMessage";

const refineOptionList = ['keyword', 'sortBy', 'sortDir', 'filterBy', 'filterId'];
const filterBy = ['eventType', 'edition', 'location', 'status'];
const filterByIndices = { eventType: 0, edition: 1, location: 2, status: 3 };

export default class EventList extends React.Component {
    constructor(props) {
        super(props);
        let message;
        const refineOptions = getRefineOptions('event', refineOptionList);
        this.debounceSearch = debounce(this.searchEvents, 350)
        if (this.props.location.state && this.props.location.state.deletedObject) {
            message = 'Event successfully deleted';
        }
        const filterId = ['All', 'All', 'All', 'All']
        this.state = { 
          events: null, 
          eventTypes: [{"id": "none", "name": ""}], 
          editions: [{"id": "none", "name": ""}], 
          locations: [{"id": "none", "name": ""}], 
          statuses: [{"id": "draft", "name": "Draft"}, {"id": "published", "name": "Published"}, {"id": "scheduled", "name": "Scheduled"}],
          message,
          filterBy,
          filterId,
          sortBy: 'name',
          sortDir: 'ASC',
          keyword: '',
          refineOptions
        };
        this.debounceSearch = debounce(this.searchEvents, 300)
        this.notify = React.createRef();
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    async componentDidMount() {
        document.title = `Events | ${process.env.REACT_APP_TITLE}`

        await EventTypeAPI.getEventTypes()
        .then(async res => {
            await this.setStateAsync({ eventTypes: this.state.eventTypes.concat(res.eventTypes)});
        })
        .catch(err => this.sendNotification(`Could not load event type options (${err.response.status})`, 'error'));
        
        await EditionAPI.getEditions()
        .then(async res => {
          await this.setStateAsync({ editions: this.state.editions.concat(res.editions)});
        })
        .catch(err => this.sendNotification(`Could not load edition options (${err.response.status})`, 'error'));

        await LocationAPI.getLocations()
        .then(async res =>{
            await this.setStateAsync({ locations: this.state.locations.concat(res.locations) });
        })
        .catch(err => this.sendNotification(`Could not load location options (${err.response.status})`, 'error'));

        let filterId = this.state.filterId;
        filterId[filterByIndices.edition] = await getCurrentEdition();
        await this.setStateAsync({ filterId });

        for (const i in refineOptionList) {
            const option = refineOptionList[i];
            const key = 'event' + upperFirst(option);
            if (!localStorage[key]) {
                localStorage.setItem(key, this.state[option]);
            } else if (this.state[option] !== this.state.refineOptions[option]) {
                await this.setStateAsync({ [option]: this.state.refineOptions[option] });
            }
            //check if any of the selected filters have since been deleted and reset to All if so
            if (option === 'filterId') {
                let verifiedIds = this.state.filterId.slice();
                const skipIds = ['All', 'none'];
                for (const j in this.state.filterId) {
                    if (filterBy[j] === 'eventType' && !skipIds.includes(verifiedIds[j])) {
                        verifiedIds[j] = verifyId(this.state.eventTypes, verifiedIds[j]);
                    } else if (filterBy[j] === 'edition' && !skipIds.includes(verifiedIds[j])) {
                        verifiedIds[j] = verifyId(this.state.editions, verifiedIds[j]);
                    } else if (filterBy[j] === 'location' && !skipIds.includes(verifiedIds[j])) {
                        verifiedIds[j] = verifyId(this.state.locations, verifiedIds[j]);
                    }
                }
                if (verifiedIds !== this.state.filterId) {
                    this.setState({ filterId: verifiedIds });
                    localStorage.setItem(key, verifiedIds);
                }
            }
        }
        this.fetchEvents()
        .then(() => this.setState({mounted: true}))
        .catch(err => {
          this.setState({mounted: true})
          this.sendNotification(`Could not load events (${err.response.status})`, 'error')
        });

    }

    fetchEvents = async () => {
      return new Promise((resolve, reject) => {
        EventAPI.filterEvents()
        .then(res => {
            this.setState({ events: res.events });
            resolve(res)
        })
        .catch(err => {
          reject(err)
        });
      })
    }

    removeEvent = (event) => {
        EventAPI.deleteEvent(event).then(() => {
            let events = this.state.events.filter(item => item.id !== event);
            this.setState({
                events
            });
        });
    }

    onSortAttribute = (attribute, direction) => {
        if (this.state.sortBy === attribute && this.state.sortDir === direction) return;

        localStorage.setItem('eventSortBy', attribute);
        localStorage.setItem('eventSortDir', direction);

        this.fetchEvents()
            .then(res => this.setState({ sortBy: attribute, sortDir: direction }))
            .catch(err => {
                this.sendNotification('Could not sort event list', 'error')
                localStorage.setItem('eventSortBy', this.state.sortBy);
                localStorage.setItem('eventSortDir', this.state.sortDir);
            });
    }

    setFilter = (value, type) => {
        const index = filterByIndices[type];
        const ids = this.state.filterId;
        let newIds = ids;
        newIds[index] = value;
        localStorage.setItem('eventFilterId', newIds);
        this.fetchEvents()
        .then(() => {
            this.setState({filterId: newIds});
        })
        .catch((err) => {
          this.sendNotification('Could not filter event list', 'error')
            localStorage.setItem('eventFilterId', this.state.filterId);
        })
    }

    searchEvents = () => {
        const regex = new RegExp(/[.*+?^${}()|[\]\\]/);
        if (regex.test(this.state.keyword)) {
            this.setState({ events: null})
        } else {
            localStorage.setItem('eventKeyword', this.state.keyword);
            this.fetchEvents()
            .catch((err) => {
              this.sendNotification('Could not search event list', 'error')
            });
        }
    }

    updateKeyword = (e) => {
      this.setState({
          keyword: e.target.value
      });
      this.debounceSearch()
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }

    render = () => {
        const getSpeakerNames = (event) => {
            let speakerNames = [];
            event.speakers && event.speakers.map((speaker) => speakerNames.push(speaker.name));
            return speakerNames;
        }

        const Card = (event) => (
            <div className="cell medium-10">
                <div className="card">
                    <div className="card-divider">
                        <section className="grid-x" style={{ width: '100%' }}>
                            <div className="cell small-10">
                                <div className="fluxible_event_type">{event.eventType ? event.eventType.name : ''}</div>
                                {event.startDate ?
                                    <div>
                                        <strong>{moment.tz(event.startDate, event.edition.timezone).format("dddd")}</strong>&nbsp;
									{moment.tz(event.startDate, event.edition.timezone).format("MMM D YYYY")},&nbsp;
									<strong>{moment.tz(event.startDate, event.edition.timezone).format("h:mm a")}</strong> to&nbsp;
									{moment.tz(event.endDate, event.edition.timezone).format("h:mm a")}
                                    </div>
                                    : ''}
                            </div>
                            <div className="cell small-2  text-right">
                                <div className="fluxible_event_state">{showVisibilityState(event.publishedOn)}</div>
                            </div>
                        </section>
                    </div>
                    <div className="card-section">
                        <h4><Link to={`/events/${event.id}/edit`}>{event.name}</Link></h4>
                        <p><em>{event.speakers ? getSpeakerNames(event).sort().join(', ') : ''}</em></p>
                    </div>
                </div>
            </div>
        );

        const cards = this.state.events && this.state.events.map((event) =>
            <Card {...event} key={event.id} />);

        const sortAttributes = ['name', 'eventType', 'startDate', 'speakers'];
        const sortType = { attribute: this.state.sortBy, direction: this.state.sortDir }
        const sortCards = sortAttributes.map((attribute) => <SortCard attribute={attribute} sortType={sortType} onSortAttribute={this.onSortAttribute} key={`sortcard${attribute}`} />);

        return (
            <div>
                {!this.state.mounted && <Loading />}

                <div className="grid-x">
                  <div className="cell small-4">
                    {Auth.hasRole(['admin', 'editor']) ?
                        (<Link to="/events/new" className="button">Add Event</Link>) : null
                    }
                  </div>
                  <div className="cell small-8">
                    <NotificationMessage message={this.state.message} small={true} ref={this.notify} />
                  </div>
                </div>

                <div> {this.state.message}</div>

                <form onSubmit= {(e)=> {this.searchEvents(e)}}>
                    <input type="text" className="search" placeholder="Search either by event name OR speaker name" value= {this.state.keyword} onChange={this.updateKeyword} />
                </form>

                <label>
                    Sort by attribute:
                </label>
                <div className="grid-x grid-padding-x">
                    {sortCards}
                </div>

                <div className="grid-x grid-padding-x">
                    <div className="medium-3 cell">
                        <label>Filter by Event Type:
                        <FilterSelect options={this.state.eventTypes} setSelectedFilter={this.setFilter} type='eventType' value={this.state.filterId[filterByIndices.eventType]} />
                        </label>
                    </div>

                    <div className="medium-3 cell">
                        <label>Filter by Edition:
                        <FilterSelect options={this.state.editions} setSelectedFilter={this.setFilter} type='edition' value={this.state.filterId[filterByIndices.edition]} />
                        </label>
                    </div>

                    <div className="medium-3 cell">
                        <label>Filter by Location:
                        <FilterSelect options={this.state.locations} setSelectedFilter={this.setFilter} type='location' value={this.state.filterId[filterByIndices.location]} />
                        </label>
                    </div>

                    <div className="medium-3 cell">
                        <label>Filter by Status:
                            <FilterSelect options={this.state.statuses} setSelectedFilter={this.setFilter} type='status' value={this.state.filterId[filterByIndices.status]} />
                        </label>
                    </div>
                </div>
                {this.state.message &&
                    <p>Message: {this.state.message}</p>}

                <div id="eventList" className="grid-x">
                    {cards}
                </div>
            </div>
        )
    };
}
