import React from 'react';
import { SponsorForm, SponsorList } from "./index"

export const ListSponsors = (props) => (
    <div>
        <h2>Sponsors</h2>
        <SponsorList {...props} />
    </div>
);

export const AddSponsor = () => (
    <div>
        <h2>Add a sponsor</h2>
        <SponsorForm />
    </div>
);

export const EditSponsor = ({ match }) => (
    <div>
        <h2>Edit a sponsor</h2>
        <SponsorForm id={match.params.id} />
    </div>
);