import React from 'react'

const IconBlock = ({ icon, text, link, size = "sm" }) => (
    <>
        <span className="icon">
            <i className={`fa fa-${icon} fa-${size}`}></i>
        </span>
        <span className="fluxible_icon_block">
            { link ? (<a href={link}>{text}</a>) : text }
        </span>
    </>
)

const SocialMediaBlock = ({ icon, link }) => (
    <a href={link}>
        <span className="icon">
            <i className={`fa fa-${icon} fa-2x`}></i>
        </span>
    </a>
)

const ButtonBlock = ({ icon, action, name = '', title = ''}) => (
    <div data-name={name} className="input-group-button" title={title}>
        <button name={name} type="submit" className="button" onClick={action}>
            <i className={`fa fa-${icon}`}></i>
        </button>
    </div>
)


export { ButtonBlock, IconBlock, SocialMediaBlock }
