import React, { Component } from 'react';
import swal from '@sweetalert/with-react';

import { tagSchema } from 'shared';
import Auth from '../Auth/Auth'
import TagCard from '../Components/TagCard';
import TagAPI from './TagAPI';
import Loading from '../Components/Loading';
import NotificationMessage from "../Components/NotificationMessage";

class TagList extends Component {
    constructor(props) {
        super(props);
        this.state = { tags: [], newTag: '', message: '', error: '' };
        this.notify = React.createRef();
    }
    
    componentDidMount() {
        document.title = `Tags | ${process.env.REACT_APP_TITLE}`;
        this.fetchTags();
    }

    fetchTags = () => {
        TagAPI.getTags()
        .then(res => {
            this.setState({ tags: res.tags ? res.tags : [], mounted: true });
        })
        .catch(err => {
          this.setState({mounted: true})
          this.sendNotification(`Could not load tags (${err.response.status})`, "error")
        });
    }

    submit = () => {
        tagSchema.validate({name: this.state.newTag})
        .then(value => {
            TagAPI.saveTag('', 'POST', value)
            .then(data => {
                this.fetchTags();
                this.setState({ newTag: '', message: data.message, error: ''});
            })
            .catch(error => {
                this.setState({ error: error.toString() });
                this.sendNotification(error.toString(), "error")
            })
        })
        .catch((error) => {
            this.setState({error: error.message});
            this.sendNotification(error.message, "error")
        })
    }

    enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if(code === 13) { //13 is the enter keycode
            this.submit();
        } 
    }

    updateTag = (event) => {
        this.setState({ newTag: event.target.value, error: '' })
    }

    deleteCard = (id) => {
        const deleting = () => (
            swal({
                content:
                <div>
                    <h2>Delete Tag</h2>
                    <div>Once deleted, this tag will be completely removed from the database and unavailable for use.</div>
                </div>,
                buttons: ["Cancel", "OK"],
                icon: "warning",
                dangerMode: true,
            })
            .then(willDelete => {
                if (willDelete) {
                    TagAPI.deleteTag(id)
                    .then(() => this.fetchTags())
                    .catch(error => {
                        this.setState({message: error.toString()});
                        this.sendNotification(error.toString(), "error")
                    })
                }
            })
            .catch(err => {
              console.log(err)
              this.sendNotification(err.toString(), "error")
            })
        );
        return deleting
    }

    newCard = () => {
        return Auth.hasRole(['admin', 'editor', 'writer', 'contributor']) ? (
            <div className="column" id="newTagContainer">
                <div className="card">
                    <div className="card-section">
                    <div className="input-group">
                        <input
                        id="newTagInput"
                        className="input-group-field"
                        type='text'
                        placeholder='For example, "Speaker Announcement"'
                        onChange={this.updateTag}
                        value={this.state.newTag}
                        onKeyPress={this.enterPressed}
                        />
                        <div className="input-group-button">
                            <input id="newTagSaveButton" type="submit" className="button" value="Save" onClick={this.submit}/>
                        </div>
                    </div>
                    {this.state.error && <label id="newTagError" className="form-error is-visible">{this.state.error}</label>}
                    </div>
                </div>
            </div>
        ) : null
    }
    
    tagCards = () => {
        return (
            <div id="tagList" className="row small-up-1 medium-up-2 large-up-3 align-center">
                {this.state.tags.map((tag) => <TagCard name={tag.name} id={tag.id} key={tag.id} delete={this.deleteCard(tag.id)}/>)}
            </div>
        )
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }

    render = () => {
        return (
            <div>
                {!this.state.mounted && <Loading />}
                {this.state.mounted && this.newCard()}
                <div className="grid-x">
                  <div className="cell small-4" />
                  <div className="cell small-8">
                    <NotificationMessage message={this.state.message} small={true} ref={this.notify} />
                  </div>
                </div>
                {this.state.message && <><div>{this.state.message}</div><br/></>}
                {this.tagCards()}
            </div>
    )};
}

export default TagList;
