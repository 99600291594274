import React, { Component } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Formik, Field, Form } from "formik";
import { mdToDraftjs, draftjsToMd } from "draftjs-md-converter";
import { animateScroll as scroll } from "react-scroll";

import Auth from "../Auth/Auth";
import BlogPostAPI from "./BlogPostAPI";
import Loading from "../Components/Loading";
import { ErrorMessage } from "../Components/ErrorMessage";
import FluxRTE from "../Components/FluxRTE";
import PublishModule from "../Components/PublishModule"
import ImagePicker from "../Components/ImagePicker";
import { blogPostSchema } from "shared";
import history from "../Utilities/history";
import {
  changesSavedPrompt,
  removeObjectPrompt,
  unsavedChangesPrompt,
  Wrapper
} from "../Utilities/formPrompts";
import { currentTimeRounded, formatDateTime } from "../Utilities/format";
import Moment from "../Utilities/moment";
import MediaAPI from "../Media/MediaAPI";
import EditionTypesAPI from "../EditionTypes/EditionTypesAPI";
import { SelectField } from "../Components/SelectField";
import TagApi from "../Tags/TagAPI";
import UserApi from "../Users/UserAPI";
import NotificationMessage from "../Components/NotificationMessage";
import { slugify } from "../Utilities/format"

const blogPostFields = {
  title: "",
  slug: "",
  summary: "",
  content: "",
  tags: "",
  publish: "draft",
  publishedOn: "",
  publishDate: "",
  authorId: Auth.getUser().id,
  editionTypeId: "",
  featuredImageId: "",
  users: "",
  editorState: EditorState.createEmpty()
};

class BlogPostForm extends Component {
  _mounted = false;

  constructor(props) {
    super(props);
    this.state = this.defaultState = {
      data: blogPostFields,
      id: "",
      slug: "",
      message: "",
      editionTypes: [{ "id": null, "name": "" }],
      featuredImage: null,
      tagList: [],
      viewPublishState: false,
      viewPublishDate: false,
      users: [],
      authorId: Auth.getUser().id,
      next: null,
      nextReady: false
    };
    this.formik = React.createRef();
    this.unsavedChanges = new Wrapper(false);
    this.submittingFromModal = new Wrapper(false);
    this.nextLocation = history.location;
    this.formik = React.createRef();
    this.notify = React.createRef();
  }

  safeSetState(object, func) {
    if (this._mounted) {
      this.setState(object, func)
    }
  }

  async componentDidMount() {
    this._mounted = true;
    if (this.props.id) {
      await BlogPostAPI.getBlogPost(this.props.id)
        .then(res => {
          const blogPost = { ...res.blogPost };
          this.getBuildInformation(blogPost.id, blogPost.publishedOn);
          document.title = `${
            blogPost.title
            } | Blog Post | ${process.env.REACT_APP_TITLE}`;
          if (blogPost.publishedOn !== null) {
            blogPost.publishDate = Moment(blogPost.publishedOn).toDate();
            blogPost.publishedOn = formatDateTime(blogPost.publishedOn);
            blogPost.publish = "publish";
          } else {
            const now = currentTimeRounded();
            blogPost.publishDate = Moment(now).toDate();
            blogPost.publishedOn = "";
            blogPost.publish = "draft";
          }

          if (blogPost.authorId !== null) {
            this.safeSetState({
              authorId: blogPost.authorId
            })
          }

          if (blogPost.featuredImageId === null) {
            blogPost.featuredImageId = "";
          } else {
            blogPost.featuredImage.preview =
              process.env.REACT_APP_MEDIA_URL + blogPost.featuredImage.name;
          }
          if (blogPost.tags)
            blogPost.tags = blogPost.tags.map(tag => ({
              value: tag.id,
              label: tag.name
            })).sort((a, b) => { return a.label === b.label ? 0 : a.label > b.label ? 1 : -1 });
          this.oldFeaturedImage = blogPost.featuredImage;

          blogPost.editorState = blogPost.content
            ? EditorState.createWithContent(
              convertFromRaw(mdToDraftjs(blogPost.content))
            )
            : EditorState.createEmpty();

          !this.isCancelled &&
            this.safeSetState({
              data: blogPost,
              id: blogPost.id,
              mounted: true,
              featuredImage: blogPost.featuredImage
            }, () => {
              // send message when brand new blog post after history push happens.
              if (this.state.mounted && history.location.state && history.location.state.notify.message !== null) {
                this.sendNotification(history.location.state.notify.message, history.location.state.notify.type);
                history.replace({ state: null });
              }
            });
        })
        .catch(err => {
          console.log(err)
          this.sendNotification(err.toString(), 'error')
        });
    } else {
      document.title = `New Blog Post | ${process.env.REACT_APP_TITLE}`;
      const blogPost = { ...this.state.data };
      const now = currentTimeRounded();

      blogPost.publishDate = Moment(now).toDate();

      this.safeSetState({ mounted: true, data: blogPost });
    }

    EditionTypesAPI.getEditionTypes()
      .then(res => {
        this.setState({ editionTypes: this.state.editionTypes.concat(res.editionTypes) });
      })
      .catch((err) => {
        console.log(err)
        this.sendNotification(err.toString(), 'error')
      });

    TagApi.getTags()
      .then(res => {
        this.safeSetState({ tagList: res.tags });
      })
      .catch(err => {
        console.log(err)
        this.sendNotification(err.toString(), 'error')
      });

    UserApi.getUsers()
      .then(res => {

        // order list by key name
        // https://stackoverflow.com/a/16481400
        let userList = res.users;
        userList.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });

        this.safeSetState({ userList: userList });
      })
      .catch(err => {
        console.log(err)
        this.sendNotification(err.toString(), 'error')
      });

    this.unblock = history.block(nextLocation => {
      this.nextLocation = nextLocation;
      if (this.unsavedChanges.get()) {
        unsavedChangesPrompt(
          nextLocation,
          this.unsavedChanges,
          this.formik.current,
          this.submittingFromModal
        );
        return false;
      } else return true;
    });
  }

  componentWillUnmount() {
    this._mounted = false
    this.isCancelled = true;
    this.unblock();
  }

  onDrop = (files, rejected, setFieldValue) => {
    const data = new FormData();
    data.append("file", files[0]);
    MediaAPI.upload(data).then(res => {
      let image = res.images[0]
      setFieldValue("featuredImageId", image.id);
      image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
      this.safeSetState({
        featuredImage: image
      });
    })
    .catch(err => this.sendNotification("Could not upload image", "error"));
  };

  cleanPost(values) {
    let url = this.state.id ? this.state.id : "";
    let method = this.state.id ? "put" : "post";

    const blogPost = { ...values };

    if (
      blogPost.publish === "publish" ||
      (!blogPost.publish && blogPost.publishedOn)
    ) {
      blogPost.publishedOn = Moment(blogPost.publishDate).toISOString();
    } else {
      blogPost.publishedOn = null;
    }

    if (blogPost.slug !== "") {
      blogPost.slug = slugify(blogPost.slug)
    }

    if (blogPost.editionTypeId === "") blogPost.editionTypeId = null

    if (blogPost.tags) blogPost.tags = blogPost.tags.map(tag => tag.value);
    delete blogPost.publish;
    delete blogPost.editorState;
    delete blogPost.featuredImage;
    this.oldFeaturedImage = this.state.featuredImage;

    blogPost.content = draftjsToMd(
      convertToRaw(values.editorState.getCurrentContent())
    );

    if (this.state.featuredImage) {
      MediaAPI.saveMedia(this.state.featuredImage.id, "put", { ...this.state.featuredImage })
    }
    return { url, method, blogPost };
  }

  onSubmit = (values) => {
    const { url, method, blogPost } = this.cleanPost(values);

    BlogPostAPI.saveBlogPost(url, method, blogPost)
      .then(data => {
        this.sendNotification(data.message, 'success')
        this.unsavedChanges.set(false);
        this.safeSetState({
          data: values,
          id: data.blogPost.id,
          slug: data.slug
        });
        scroll.scrollToTop();
        this.getBuildInformation(data.blogPost.id, data.blogPost.publishedOn);
        history.replace({
          pathname: `/blogPosts/${this.state.id}/edit`,
          state: { notify: { message: data.message, type: 'success' } }
        });
      })
      .catch(error => {
        this.sendNotification(error.toString(), 'error')
        scroll.scrollToTop();
      })
      .then(() => {
        if (this.submittingFromModal.get()) {
          changesSavedPrompt(this.nextLocation, this.unsavedChanges);
        }
        this.submittingFromModal.set(false);
      });
  }

  getBuildInformation = (id, date) => {
    const momentDate = Moment(date)
    if (momentDate.isValid()) {
      BlogPostAPI.getBlogPostBuilds(id, momentDate.format())
        .then(res => {
          this.setState({ next: res.next, nextReady: true });
        })
        .catch(err => {
          this.sendNotification(err.toString(), 'error')
        })
    }
  };

  removePhoto = setFieldValue => {
    setFieldValue("featuredImageId", "");
    this.safeSetState({
      featuredImage: null
    });
  };

  setPhotoFromLibrary = (setFieldValue, newImage) => {
    const image = { ...newImage }
    setFieldValue("featuredImageId", image.id);
    image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
    this.safeSetState({
      featuredImage: image
    });
  };

  createBlogPost = () => {
    this.safeSetState({
      data: blogPostFields
    });
    history.replace("/blogPosts/new");
  };

  revertChanges = () => {
    this.formik.current.resetForm();
    this.sendNotification("Reverted changes", 'warning')
    this.safeSetState({
      featuredImage: this.oldFeaturedImage
    });
    scroll.scrollToTop();
  };

  updateViewPublishState = () => {
    this.safeSetState((state, props) => ({
      viewPublishState: !state.viewPublishState
    }));
  };

  updateViewPublishDate = () => {
    this.safeSetState((state, props) => ({
      viewPublishDate: !state.viewPublishDate
    }));
  };

  publishChanges = () => {
    const values = this.formik.current.values;
    const { url, method, blogPost } = this.cleanPost(values);

    BlogPostAPI.saveBlogPost(url, method, blogPost)
      .then(data => {
        this.unsavedChanges.set(false);
        this.sendNotification(data.message, 'success');

        BlogPostAPI.publishBlogPost(data.blogPost.id)
          .then(publishData => {
            this.setState({
              data: values,
              id: data.blogPost.id,
            });
            this.sendNotification(publishData.message, 'success');

            scroll.scrollToTop();
            this.getBuildInformation(data.blogPost.id, data.blogPost.publishedOn);
          })
          .catch(error => {
            this.safeSetState({
              data: values,
              id: data.blogPost.id,
            });
            this.sendNotification("Your changes are saved, but there was an error when trying to publish.", 'error')
            scroll.scrollToTop();
            this.sendNotification(`Publish ${error.toString()}`, 'error');
          });
      })
      .catch(error => {
        this.sendNotification(error.toString(), 'error')
        scroll.scrollToTop();
      });
  };

  removeBlogPost = () => {
    removeObjectPrompt(
      "blogPost",
      BlogPostAPI.deleteBlogPost,
      "/blogPosts",
      this.state.id,
      message => this.sendNotification(message, 'error')
    );
  };

  onChangeAltText = (e) => {
    e.preventDefault()
    let featuredImage = this.state.featuredImage
    featuredImage.altText = e.target.value
    this.safeSetState({ featuredImage: featuredImage })
  }

  onPermalinkBlur = (e, setFieldValue) => {
    let slug = slugify(e.target.value)
    setFieldValue("slug", slug)
  }

  // This custom setValue lets us prevent publishing without required values
  customSetValue = (key, value, setValue, formValues) => {
    if(key === "publish" && value === "publish") {
      if(!formValues.editorState.getCurrentContent().hasText()){
        this.sendNotification("Blog posts cannot be published without content", "warning")
      } else {
        setValue(key, value)
      }
    } else {
      setValue(key, value)
    }
  }

  sendNotification(message, type) {
    if(this.notify.current) {
      this.notify.current.sendMessage(message, type)
    }
  }

  renderForm = ({
    dirty,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    isSubmitting
  }) => {
    this.unsavedChanges.set(dirty && !isSubmitting);

    if(isSubmitting && !this.haveSentErrors) {
      for (let [key, value] of Object.entries(errors)) {
        setTimeout(this.sendNotification(value, "error"), 400)
        this.haveSentErrors = true;
      }
    }
    if(this.haveSentErrors && !isSubmitting) this.haveSentErrors = false;

    const tagOptions =
      this.state.tagList &&
      this.state.tagList.map(tag => ({ value: tag.id, label: tag.name }));
    return (
      <Form>
        <div className="grid-x grid-padding-x">
          <div className="cell small-8">
            {this.state.id && (
              <button
                type="button"
                className="hollow button"
                onClick={this.createBlogPost}
              >
                New Blog Post
              </button>
            )}
            <label>
              Title
              <Field
                type="text"
                name="title"
                placeholder="Speaker Spotlight"
              />
            </label>
            <ErrorMessage name="title" />
            <label>
              Permalink
              <Field
                type="text"
                name="slug"
                placeholder="If left empty it'll auto-generate from title"
                onBlur={e => this.onPermalinkBlur(e, setFieldValue)}
              />
            </label>

            <label>
              Author
              <Field
                as="select"
                name="authorId"
                disabled={!Auth.hasRole(["admin", "editor"])}
              >
                {this.state.userList &&
                  this.state.userList.map((user, idx) => (
                    <option value={user.id} key={idx}>
                      {user.name} ({user.email})
                    </option>
                  ))}
              </Field>
            </label>

            <label>Edition Type</label>
            <Field component="select" name="editionTypeId">
              {this.state.editionTypes && this.state.editionTypes.map((type, idx) => (
                <option value={type.id} key={idx}>
                  {type.name}
                </option>
              ))}
            </Field>
            <ErrorMessage name="editionTypeId" />

            <label>
              Summary
              <Field
                type="text"
                name="summary"
                placeholder="The Speaker Spotlight series features interviews with upcoming conference speakers and presenters."
              />
            </label>

            <label>Content</label>
            <FluxRTE
              editorState={values.editorState}
              onChange={setFieldValue}
              onBlur={handleBlur}
              placeholder="We asked Di some questions about her work, philosophy, and her role as a design advocate. Here is what she said: "
            />

            <Field type="hidden" name="featuredImageId" />
            <p>Featured image</p>
            {this.state.featuredImage ? (
              <div className="panel clearfix">
                <img
                  alt="Preview"
                  key={this.state.featuredImage.preview}
                  src={this.state.featuredImage.preview}
                  className="image-preview"
                />
                <br />
                <div className="row">
                  <label>Alt-text:</label>
                </div>
                <div className="row">
                  <input type="text" id="altTextInput" value={this.state.featuredImage.altText} onChange={this.onChangeAltText} />
                </div>
                <div className="row">
                  <button
                    type="button"
                    id="removePhotoButton"
                    className="button alert"
                    onClick={() => {
                      this.removePhoto(setFieldValue);
                    }}
                  >
                    Remove photo
                  </button>
                </div>
              </div>
            ) : (
                <ImagePicker
                  onDrop={this.onDrop}
                  setImage={this.setPhotoFromLibrary}
                  setFieldValue={setFieldValue}
                />
              )}

            <label>Tags</label>
            <Field
              name="tags"
              options={tagOptions}
              component={SelectField}
              placeholder="Choose a tag by typing or using the dropdown..."
            />
          </div>
          <div className="cell small-4  sidebar">
            <PublishModule
              setFieldValue={(key, value) => this.customSetValue(key, value, setFieldValue, values)}
              setFieldTouched={setFieldTouched}
              values={values}
              publishChanges={this.publishChanges}
              revertChanges={this.revertChanges}
              removeObject={this.removeBlogPost}
              objectId={this.state.id}
              type="blogPost"
              next={this.state.next}
              nextReady={this.state.nextReady}
              currentPublishState={values.publish}
            />

            <NotificationMessage message={this.state.message} ref={this.notify} />

          </div>
        </div>
      </Form>
    );
  };

  componentDidUpdate(nextState) {
    this.updateClass();
  }

  updateClass = () => {
    return 'sidebar__message--pop';
  }

  render() {
    return (
      <div>
        {!this.state.mounted && <Loading />}

        {this.state.data ? (
          <Formik
            initialValues={this.state.data}
            enableReinitialize={true}
            onSubmit={this.onSubmit}
            validationSchema={blogPostSchema}
            innerRef={this.formik}
          >
            {this.renderForm}
          </Formik>
        ) : null}
      </div>
    );
  }
}

export default BlogPostForm;
