import axios from 'axios'
import saveError from '../Utilities/saveError';
const API_URL = '/api/editionTypes/';
const EditionType = {
    getEditionTypes: async () => {
      return new Promise((resolve, reject) => {
        return axios(API_URL)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    getEditionType: async (id) => {
      return new Promise((resolve, reject) => {
        return axios(API_URL + id)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    setCurrentEdition: async (id, currentEditionId) => {
      return new Promise((resolve, reject) => {
        return axios.put(`${API_URL}currentEdition/${id}`, { currentEditionId })
            .then(response => resolve(response.data))
            .catch(error => {
              saveError(error, reject)
            });
          })
    }
}

export default EditionType;
