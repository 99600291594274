import React from 'react';

const FilterSelect = ( {options, setSelectedFilter, type, value} ) => (
    <select onChange={(e) => setSelectedFilter(e.target.value, type)} value={value}>
        <option key={0} value='All'>All</option>
        {options.map((option, idx) => (
            <option value={option.id} key={idx + 1}>
                {option.name === '' ? '--' : option.name}
            </option>
        ))}
    </select>
)

export default FilterSelect;