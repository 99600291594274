import React, { Component } from 'react';

import '../Styles/app.scss';
import history from '../Utilities/history';
import Auth from '../Auth/Auth'
import Collapsible from 'react-collapsible';

export default class Menu extends Component {
    state = { activeTab: '' };
    // eslint-disable-next-line
    tabs = tabSets[tabSets[process.env.REACT_APP_PRODUCT] ? process.env.REACT_APP_PRODUCT : 'default']

    goTo = (route) => {
        history.push(`/${route}`);
        this.forceUpdate()
    }

    camelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }

    componentDidMount() {
      Auth.registerComponentToUpdate(() => this.forceUpdate())
    }

    componentDidUpdate() {
        this.highlightTab();
    }

    highlightTab = () => {
        const tab = this.tabs.filter(tab => history.location.pathname.includes(tab.link));
        const activeTab = tab.length === 0 ? '' : tab[0].name;

        if (this.state.activeTab !== activeTab) this.setState({ activeTab })
    };

    render() {
        return Auth.isAuthenticated() && (
            <ul className="vertical menu" data-accordion-menu>
                {this.tabs.map((tab, idx, tabs) => {
                    if(tab.link && !tab.group){
                        return(
                            <li id={this.camelize(tab.link)} className={`menu-text object-menu-text${`/${tab.link}` === history.location.pathname ? ' active' : ''}`} onClick={() => this.goTo(tab.link)} key={idx} >
                                {tab.name}
                            </li>
                        )
                    } else if (tab.isGroup) {
                        return (
                            <Collapsible
                                trigger={tab.name}
                                transitionTime={160}
                                key={idx}
                                className={this.camelize(`group${tab.name}`)}
                            >
                                {tabs.map((innerTab, idx) => {
                                    return(innerTab.group === tab.name &&
                                    <li id={this.camelize(innerTab.link)} className={`menu-text object-menu-text nested${`/${tab.link}` === history.location.pathname ? ' active' : ''}`} onClick={() => this.goTo(innerTab.link)} key={idx} >
                                        {innerTab.name}
                                    </li>
                                    )
                                })}
                            </Collapsible>
                        )
                    }
                    return ("")
                })}
            </ul>
        );
    }
}

const fluxibleTabs = [
    { name: 'Blog Posts', link: 'blogPosts' },
    { name: 'Speakers', link: 'speakers' },
    { name: 'Events', link: 'events' },
    { name: 'Sponsors', link: 'sponsors' },
    { name: 'Locations', link: 'locations' },
    { name: 'Media', link: 'media/1' },
    { name: 'Event Types', link: 'eventTypes', group: "Categories" },
    { name: 'Sponsor Types', link: 'sponsorTypes', group: "Categories" },
    { name: 'Editions', link: 'editions', group: "Categories" },
    { name: 'Blog Tags', link: 'tags', group: "Categories" },
    { name: 'Current Edition', link: 'currentEdition', group: "Categories" },
    { name: 'Users', link: 'users', group: 'Extras' },
    { name: 'Festival Infrastructure', link: 'festivalInfrastructure', group: "Extras" },
    { name: 'Pages', link: 'pages', group: "Extras" },
    { name: 'Testimonials', link: 'testimonials', group: "Extras" },
    { name: 'Categories', isGroup: true },
    { name: 'Extras', isGroup: true },
];

const defaultTabs = [
    { name: 'Speakers', link: 'speakers' },
    { name: 'Locations', link: 'locations' },
    { name: 'Festival Infrastructure', link: 'festivalInfrastructure' },
    { name: 'Sponsors', link: 'sponsors' },
    { name: 'Events', link: 'events' },
    { name: 'Event Types', link: 'eventTypes' },
    { name: 'Editions', link: 'editions' },
    { name: 'Media', link: 'media/1' },
    { name: 'Blog Posts', link: 'blogPosts' },
    { name: 'Blog Tags', link: 'tags' },
    { name: 'Pages', link: 'pages' },
    { name: 'Testimonials', link: 'testimonials' },
    { name: 'Users', link: 'users' },
    { name: 'Current Edition', link: 'currentEdition' },
];

const zeitspaceTabs = [
    { name: 'Blog Posts', link: 'blogPosts' },
    { name: 'Events', link: 'events' },
    { name: 'Speakers', link: 'speakers' },
    { name: 'Media', link: 'media/1' },
    { name: 'Locations', link: 'locations', group: "Extras" },
    { name: 'Event Types', link: 'eventTypes', group: "Categories" },
    { name: 'Editions', link: 'editions', group: "Categories" },
    { name: 'Blog Tags', link: 'tags', group: "Categories" },
    { name: 'Pages', link: 'pages', group: "Extras" },
    { name: 'Users', link: 'users', group: 'Extras' },
    { name: 'Testimonials', link: 'testimonials', group: "Extras" },
    { name: 'Categories', isGroup: true },
    { name: 'Extras', isGroup: true }
];

const tabSets = { zeitspace: zeitspaceTabs, fluxible: fluxibleTabs, default: defaultTabs }