import React from 'react';
import { FestivalInfrastructureForm, FestivalInfrastructureList }from './index';

export const AddFestivalInfrastructure = () => (
    <div>
        <h2>Add festival infrastructure</h2>
        <FestivalInfrastructureForm />
    </div>
);

export const EditFestivalInfrastructure = ({ match }) => (
    <div>
        <h2>Edit festival infrastructure</h2>
        <FestivalInfrastructureForm id={match.params.id} />
    </div>
);

export const ListFestivalInfrastructure = (props) => (
    <div>
        <h2>Festival infrastructure</h2>
        <FestivalInfrastructureList {...props} />
    </div>
);
