import React, { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export default class NotificationMessage extends Component {
  _mounted = false

  static defaultProps = {
    small: false
  }

  constructor(props) {
    super(props);
    this.prevMessage = null;
    this.state = {
      listItems: [],
      idCount: 0
    };
  }

  componentDidMount() {
    this._mounted = true
  }

  componentWillUnmount() {
    this._mounted = false
  }

  sendMessage = (message, type) => {
    let uid = this.state.idCount + 1;
    this.setState(prevState => ({
      listItems: [
        ...prevState.listItems,
        {
          message,
          type,
          id: uid
        }
      ],
      idCount: uid
    }));
    setTimeout(() => {
      if (this._mounted) {
        this.setState(prevState => ({
          listItems: prevState.listItems.filter(item => item.id !== uid)
        }))
      }
    }, 5000);
  };

  render() {
    const { small } = this.props
    return (
      <TransitionGroup className={small ? "small-notification-message" : "notification-message"}>
        {this.state.listItems.length > 0 &&
          this.state.listItems.map((item, index) => (
            <CSSTransition
              timeout={600}
              classNames={{
                enter: "notification-message__item--enter",
                enterActive: "notification-message__item--enter-active",
                exit: "notification-message__item--exit",
                exitActive: "notification-message__item--exit-active"
              }}
              unmountOnExit
              key={index}
            >
              <div className={`${small ? 'small-notification-message__item' : 'notification-message__item'}  notification-message__item--${item.type}`}>{item.message}</div>
            </CSSTransition>
          ))}
      </TransitionGroup>
    );
  }
}
