import React, { Component } from 'react';
import DateTimePicker from "react-widgets/DatePicker";
import { ErrorMessage } from "../Components/ErrorMessage";
import Moment from "../Utilities/moment";
import PublishText from './PublishText';
import axios from 'axios';
import Auth from "../Auth/Auth";

export default class PublishModule extends Component {

    constructor(props) {
        super(props)
        this.state = {
            viewPublishState: false,
            viewPublishDate: false,
            id_token: ""
        }
    }

    updateViewPublishState = () => {
        this.setState(state => ({
            viewPublishState: !state.viewPublishState
        }));
    };

    updateViewPublishDate = () => {
        this.setState(state => ({
            viewPublishDate: !state.viewPublishDate
        }));
    };

    preview = () => {
        axios('/preview/', {
            method: "PUT",
            data: { expDate: Moment(new Date(new Date().getTime()).toISOString()).add(5, 'm').toDate() },
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                window.open(`${process.env.REACT_APP_PREVIEW_URL}preview/?key=${response.data.key}&type=${this.props.type}&id=${this.props.objectId}`, '_blank')
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        const { setFieldValue, setFieldTouched, values } = this.props
        return (
            <div>
                {Auth.hasRole(["admin", "editor"]) ? (
                    <>
                        <label>
                            {/* STATUS */}
                            <div className="sidebar__section">
                                <span className="sidebar__legend">status:</span>
                                <span className="sidebar__value">
                                    {values.publish === "publish" && Moment().diff(values.publishDate) > 0 && `${values.publish}ed`}
                                    {values.publish === "publish" && Moment().diff(values.publishDate) < 0 && `${values.publish}`}
                                    {values.publish === "draft" && values.publish}
                                </span>
                                {!this.state.viewPublishDate &&
                                    !this.state.viewPublishState && (
                                        <span
                                            className="sidebar__cta"
                                            onClick={this.updateViewPublishState}
                                        >
                                            Edit
                                        </span>
                                    )}
                            </div>

                            {this.state.viewPublishState && (
                                <div className="row medium-collapse align-middle sidebar__section--form">
                                    <div className="small-6 columns">
                                        <select
                                            name=""
                                            id=""
                                            value={values.publish}
                                            onChange={(e) => {
                                                this.props.setFieldValue("publish", e.target.value);
                                                this.updateViewPublishState();
                                            }}
                                        >
                                            <option value="draft">draft</option>
                                            <option value="publish">publish</option>
                                        </select>
                                    </div>
                                    <div className="small-3 columns  text-center">
                                        <button
                                            className="button hollow secondary"
                                            onClick={this.updateViewPublishState}
                                        >
                                            Close
                        </button>
                                    </div>
                                </div>
                            )}

                            {/* DATE */}

                            <div className="sidebar__section">
                                <span className="sidebar__legend">date:</span>
                                <span className="sidebar__value">
                                    {values.publish !== "publish" && "Not published yet"}
                                    {values.publish === "publish" && Moment(values.publishDate).format("dddd MMM Do, YYYY h:mm a")}
                                </span>

                                {values.publish === "publish" &&
                                    !this.state.viewPublishDate &&
                                    !this.state.viewPublishState && (
                                        <span
                                            className="sidebar__cta"
                                            onClick={this.updateViewPublishDate}
                                        >Edit</span>
                                    )}
                            </div>

                            {this.state.viewPublishDate && (
                                <div className="row medium-collapse   align-middle    sidebar__section--form">
                                    <div className="small-7 columns">
                                        <DateTimePicker
                                            name="publishDate"
                                            value={Moment(values.publishDate).toDate()}
                                            valueEditFormat={{dateStyle: 'short', timeStyle: "short"}}
                                            valueDisplayFormat={{dateStyle: 'medium', timeStyle: "short"}}
                                            placeholder={"31/12/2021 11:15 AM"}
                                            inputProps={{ id: "publishDate", autoComplete: "off" }}
                                            onChange={v => {
                                                setFieldTouched("publishDate");
                                                setFieldValue("publishDate", v);
                                            }}
                                            onBlur={this.updateViewPublishDate}
                                            includeTime
                                        />
                                        <ErrorMessage name="publishDate" />
                                    </div>
                                    <div className="small-3 columns  text-center">
                                        <button
                                            className="button hollow secondary"
                                            onClick={this.updateViewPublishDate}
                                        >
                                            Close
                        </button>
                                    </div>
                                </div>
                            )}
                        </label>
                    </>
                ) : null}

                {Auth.hasRole(["admin", "editor", "writer", "contributor"]) && (
                    <>
                        {/* SAVE BUTTON */}
                        <button
                            type="submit"
                            className="button"
                            disabled={
                                this.state.viewPublishState || this.state.viewPublishDate
                            }
                        >
                            Save
                        </button>

                        {/* PREVIEW, REVERT, DELTE BUTTONS */}
                        {this.props.objectId && this.props.type && (<button className="button" onClick={this.preview}>Preview</button>)}
                        <button type="button" className="hollow button" disabled={this.state.viewPublishState} onClick={this.props.revertChanges}>Revert</button>
                        {this.props.objectId && <button type="button" disabled={this.state.viewPublishState} className="hollow button" onClick={this.props.removeObject}>Move to trash</button>}

                        {/* PUBLISH STATE AND BUILD NOW */}
                        {Auth.hasRole(["admin", "editor", "writer", "contributor"]) && (
                            <>
                                <PublishText id={this.props.objectId} next={this.props.next} nextReady={this.props.nextReady} currentPublishState={this.props.currentPublishState} />
                                {this.props.currentPublishState === 'publish' && <button type="button" className="button  warning" disabled={this.state.viewPublishState || this.state.viewPublishDate} onClick={this.props.publishChanges}>Save & Build Now</button>}
                            </>
                        )}
                    </>
                )}
            </div>
        );
    }
}