import React from 'react';
import { Link } from 'react-router-dom'

import Auth from '../Auth/Auth'
import SponsorTypeAPI from './SponsorTypeAPI'
import Loading from '../Components/Loading';
import NotificationMessage from "../Components/NotificationMessage";

export default class SponsorTypeList extends React.Component {
    constructor(props) {
        super(props);
        let message = '';
        if (this.props.location.state && this.props.location.state.deletedObject) {
            message = 'Sponsor Type successfully deleted';
        }
        this.state = { eventTypes: null, message };
        this.notify = React.createRef();
    }
    
    componentDidMount() {
        document.title = `Sponsor Types | ${process.env.REACT_APP_TITLE}`
        SponsorTypeAPI.getSponsorTypes()
        .then(res => this.setState({ sponsorTypes: res.sponsorTypes, mounted: true }))
        .catch(err => {
          this.setState({mounted: true})
          this.sendNotification(`Could not load sponsor types (${err.response.status})`, "error")
        });
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }

    render = () => {
        const Row = (sponsorType) => (
            <tr>
                <td><strong><Link to={`/sponsorTypes/${sponsorType.id}/edit`}>{sponsorType.name}</Link></strong></td>
                <td>{sponsorType.visible ? 'True' : 'False'}</td>
            </tr>
        );
    
        const tableRows = this.state.sponsorTypes && this.state.sponsorTypes.map((sponsorType) =>
                <Row {...sponsorType} key={sponsorType.id} />);
        return (
            <div>
                {!this.state.mounted && <Loading />}

                <div className="grid-x">
                  <div className="cell small-4">
                    {Auth.hasRole(['admin', 'editor']) ?
                      (<Link to="/sponsorTypes/new" className="button">Add Sponsor Type</Link>) : null
                    }
                  </div>
                  <div className="cell small-8">
                    <NotificationMessage message={this.state.message} small={true} ref={this.notify} />
                  </div>
                </div>

                <div>{this.state.message}</div>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Visible</th>
                        </tr>
                    </thead>
                    <tbody>
                        { tableRows }
                    </tbody>
                </table>
            </div>
        )
    };
}
