import React from "react";

import { MediaForm, MediaList } from "./index"

export const AddMedia = () => (
    <div>
        <h2>Add new Media</h2>
        <MediaForm />
    </div>
);

export const EditMedia = ({ match }) => (
    <div>
        <h2>Edit Media</h2>
        <MediaForm id={match.params.id} />
    </div>
);

export const ListMedia = (props) => (
    <div>
        <h2>Media</h2>
        <MediaList {...props} />
    </div>
)