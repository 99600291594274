import _ from 'lodash';
import swal from '@sweetalert/with-react';
import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import history from './history';

export const changesSavedPrompt = (nextLocation, unsavedChanges) => {
    swal({
        content: <div>{
            unsavedChanges.get()
                ? <>
                    <h2>Oops</h2>
                    <div>Looks like your changes didn't save.</div></>
                : <>
                    <h2>Changes saved</h2>
                    <div>Your changes were saved! Continue {readablePathname(nextLocation.pathname)}?</div>
                </>
        }</div>,
        buttons: ['Cancel', 'Continue'],
        icon: unsavedChanges.get() ? "warning" : "success",
    })
        .then((response) => {
            if (response) {
                unsavedChanges.set(false);
                history.replace(nextLocation);
            }
        })
}

export const unsavedChangesPrompt = (nextLocation, unsavedChanges, formik, submittingFromModal) => {
    let buttonContents = {
        leave: {
            text: 'Continue',
            value: 'leave',
        },
        save: {
            text: 'Save changes',
            value: 'save',
        },
        stay: {
            text: 'Keep Editing',
            value: 'stay',
        },
    }
    if (formik.state && !_.isEmpty(formik.state.errors)) delete buttonContents.save;

    if (nextLocation.state && nextLocation.state.deletedObject && nextLocation.state.deletedObject === true) {
        unsavedChanges.set(false);
        history.replace(nextLocation);
        return null
    } else {
        return swal({
            content:
            <div>
                <h2>Leave Page?</h2>
                <div>It looks like you have unsaved changes on this page... Would you like to continue {readablePathname(nextLocation.pathname)} anyway?</div>
                { formik.state && !_.isEmpty(formik.state.errors) 
                    && <><br/><div>Note: There is also missing or incorrect information that needs to be looked at before saving is possible</div></> }
            </div>,
            buttons: buttonContents,
            icon: "warning",
        })
            .then((response) => {
                unsavedChanges.set(false);
                switch (response) {
                    case 'leave':
                        history.replace(nextLocation);
                        break;
                    case 'save':
                        unsavedChanges.set(true);
                        submittingFromModal.set(true);
                        formik.submitForm();
                        break;
                    case 'stay':
                    default:
                        unsavedChanges.set(true);
                }
                return response;
            });
    }
}

export const removeObjectPrompt = async (objectType, objectDelete, pathname, id, setMessage) => {
    const readableName = readablePathname(objectType, false);
    return swal({
        content:
            <div>
                <h2>Delete {readableName}</h2>
                <div>Once deleted, this {_.lowerCase(readableName)} will be completely removed from the database and unavailable for use.</div>
            </div>,
        buttons: ["Cancel", "OK"],
        icon: "warning",
        dangerMode: true,
    })
    .then(willDelete => {
        if (willDelete) {
            objectDelete(id)
            .then((res) => {
                history.replace({pathname: pathname, state: {'deletedObject': true}});
            })
            .catch(error => {
              const errorMsg = error.response && error.response.data && error.response.data.message ? error.response.data.message : error.toString()
              setMessage(errorMsg);
              scroll.scrollToTop();
            });
        }
    }); 
}

const readablePathname = (pathname, isPath = true) => {
    pathname = _.trimStart(pathname, '/');
    pathname = _.split(pathname, '/')[0];
    pathname = _.startCase(pathname);
    return isPath ? (pathname === '' ? 'navigating' : 'to ' + pathname) : pathname;
}

export function Wrapper(val) {
    var value = val;

    this.set = (newValue) => {
        value = newValue;
    }

    this.get = () => {
        return value;
    }
}