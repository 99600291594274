import React from "react";

import { SponsorTypeForm, SponsorTypeList } from "./index"

export const AddSponsorType = ()  => (
    <div>
        <h2>Add a sponsor type</h2>
        <SponsorTypeForm />
    </div>
);

export const EditSponsorType = ({ match })  => (
    <div>
        <h2>Edit a sponsor type</h2>
        <SponsorTypeForm id={match.params.id} />
    </div>
);

export const ListSponsorTypes = (props) => (
    <div>
        <h2>Sponsor Types</h2>
        <SponsorTypeList {...props} />
    </div>
)
