import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { min, max, range, debounce, upperFirst } from 'lodash';

import Auth from '../Auth/Auth'
import FilterSelect from '../Components/FilterSelect'
import SortCard from '../Components/SortCard'

import MediaAPI from './MediaAPI'
import Loading from '../Components/Loading';
import { getRefineOptions } from '../Utilities/refineOptions';
import { typeIcons } from './utils';
import history from '../Utilities/history';
import PaginationInput from '../Components/PaginationInput'

const refineOptionList = ['keyword', 'sortBy', 'sortDir', 'filterBy', 'filterId'];
const filterBy = 'mediaType';

class MediaList extends Component {
    _mounted = false;

    constructor(props) {
        super(props);

        if(!this.props.match.params.page){
          history.replace('/media/1')
        }

        let message;
        const refineOptions = getRefineOptions('media', refineOptionList);
        this.debounceSearch = debounce(this.searchMedia, 350)
        if (this.props.location.state && this.props.location.state.deletedObject) {
            message = 'Media successfully deleted';
        }
        const filterId = 'All';

        const pageInt = parseInt(this.props.match.params.page)
        this.state = {
            message,
            media: null,
            currentPage: !isNaN(pageInt) ? pageInt : 1,
            isFirst: false,
            isLast: false,
            filterBy,
            filterId,
            sortBy: 'uploadedOn',
            sortDir: 'DESC',
            keyword: '',
            refineOptions,
            mediaTypes: [
                { "id": "image", "name": "Images" },
                { "id": "audio", "name": "Audio" },
                { "id": "video", "name": "Videos" },
                { "id": "document", "name": "Documents" },
                { "id": "other", "name": "Other" }],
        };
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.safeSetState(state, resolve)
        });
    }

    safeSetState(object, func) {
        if (this._mounted) {
            this.setState(object, func)
        }
    }

    async componentDidMount() {

        this._mounted = true;
        document.title = `Media | ${process.env.REACT_APP_TITLE}`;

        let filterId = this.state.filterId;
        await this.setStateAsync({ filterId });

        for (const i in refineOptionList) {
            const option = refineOptionList[i];
            const key = 'media' + upperFirst(option);
            if (!localStorage[key]) {
                localStorage.setItem(key, this.state[option]);
            } else if (this.state[option] !== this.state.refineOptions[option]) {
                await this.setStateAsync({ [option]: this.state.refineOptions[option] });
            }
        }

        this.filterMedia()
            .then(() => this.safeSetState({ mounted: true }))
            .catch(err => console.log(err));
    }

    componentWillUnmount() {
        this._mounted = false
    }

    componentDidUpdate() {
      const pageInt = parseInt(this.props.match.params.page)
      if(!isNaN(pageInt) && this.state.currentPage !== pageInt) {
        this.setState({currentPage: pageInt}, () => {
          this.filterMedia()
          .then(res => window.scrollTo(0, 0))
        })
      }
    }

    filterMedia = async (params = {}) => {
        if (!params.sortBy) params.sortBy = this.state.sortBy
        if (!params.sortDir) params.sortDir = this.state.sortDir
        if (!params.searchTerm && this.state.keyword !== '') params.searchTerm = this.state.keyword
        if (!params.filterId) params.filterId = this.state.filterId
        if (!params.page) params.page = this.state.currentPage

        MediaAPI.filterMedia(params)
            .then(res => {
                const currentPage = parseInt(res.page)
                const numPages = parseInt(res.count)
                const isFirst = (currentPage === 1)
                const isLast = (currentPage === numPages)
                this.safeSetState({ media: res.media, currentPage, numPages, isFirst, isLast });
            })
            .catch(err => { console.log(err) });
    }

    setFilter = (value) => {
        let oldId = this.state.filterId
        let newId = value;
        localStorage.setItem('mediaFilterId', newId);

        this.safeSetState({ filterId: newId }, () => {
            history.replace(`/media/1`) 
            this.filterMedia()
                .catch((err) => {
                    console.log(err);
                    localStorage.setItem('mediaFilterId', oldId);
                    this.safeSetState({ filterId: oldId })
                })
        });
    }

    onSortAttribute = (attribute, direction) => {
        if (this.state.sortBy === attribute && this.state.sortDir === direction) return;

        localStorage.setItem('mediaSortBy', attribute);
        localStorage.setItem('mediaSortDir', direction);

        this.safeSetState({ sortBy: attribute, sortDir: direction }, () => {
            this.filterMedia()
                .catch((err) => {
                    console.log(err);
                    localStorage.setItem('mediaSortBy', this.state.sortBy);
                    localStorage.setItem('mediaSortDir', this.state.sortDir);
                })
        })
    }

    changePage = (newNum) => {
        this.setState({currentPage: newNum}, () => {
          history.replace(`/media/${newNum}`)  
        })
    }

    inputUpdatePage =(page) => {
      const newPage = page <= this.state.numPages ?
        page : this.state.numPages
      this.changePage(newPage)
    }

    updateSearchTerm = (e) => {
        this.safeSetState({
            keyword: e.target.value
        });
        this.debounceSearch()
    }

    searchMedia = () => {
        localStorage.setItem('mediaKeyword', this.state.keyword);
        history.replace(`/media/1`) 
        this.filterMedia()
            .catch(err => console.log(err));
    }

    render = () => {
        const Card = (media) => {
            const splitName = media.name.split('/')
            const shortName = splitName[splitName.length - 1]

            let image = (<></>)

            let types = typeIcons

            if (RegExp(/^image\/.*/).test(media.contentType)) {
                image = (<img alt={media.altText} src={process.env.REACT_APP_MEDIA_URL + media.name} />)
            } else if (RegExp(/^video\/.*/).test(media.contentType)) {
                image = (<i className={`fa fa-file-video fa-4x`} />)
            } else if (RegExp(/^audio\/.*/).test(media.contentType)) {
                image = (<i className={`fa fa-file-audio fa-4x`} />)
            } else if (types[media.contentType] !== undefined) {
                image = (<i className={`fa fa-file-${types[media.contentType]} fa-4x`} />)
            } else {
                image = (<i className={`fa fa-file fa-4x`} />)
            }

            return (
                <div className="column">
                    <div className="card">
                        <div className="card-divider grid-x">
                            <div className="cell"><Link to={`/media/${media.id}/edit`}>{shortName}</Link></div>
                        </div>
                        <div className="card-section text-centered">
                            {image}
                        </div>
                    </div>
                </div>

            )
        };

        const mediaCards = this.state.media && this.state.media.map((media) =>
            <Card {...media} key={media.id} />);

        const sortAttributes = ['filename', 'uploadedOn'];
        const sortType = { attribute: this.state.sortBy, direction: this.state.sortDir }
        const sortCards = sortAttributes.map((attribute) => <SortCard attribute={attribute} sortType={sortType} onSortAttribute={this.onSortAttribute} key={`sortcard${attribute}`} />);

        const currentPage = this.state.currentPage;
        const numPages = this.state.numPages;

        
        let start = currentPage <= 4 ? 1 : currentPage < (numPages - 3) ? currentPage - 3 : max([numPages - 6, 1]);
        let end = currentPage <= 4 ? min([8, numPages + 1]) : currentPage < (numPages - 3) ? currentPage + 4 : numPages + 1;
        let pages = start === end ? [1] : range(start, end);

        return (
            <div>
                {!this.state.mounted && <Loading />}
                <div> {this.state.message}</div>

                {Auth.hasRole(['admin', 'editor']) ?
                    (<Link id="addMediaButton" to="/media/new" className="button">Add Media</Link>) : null
                }
                <input type="text" className="search" placeholder="Search by filename or alt-text" value={this.state.keyword} onChange={this.updateSearchTerm} />

                <label>Sort by attribute:</label>
                <div className="grid-x grid-margin-x">
                    {sortCards}
                </div>

                <div className="grid-x grid-padding-x">
                    <div className="medium-3 cell">
                        <label>
                            Filter by Media Type:
                            <FilterSelect options={this.state.mediaTypes} setSelectedFilter={this.setFilter} type="mediaType" value={this.state.filterId} />
                        </label>
                    </div>
                </div>

                <div className="row small-up-1 medium-up-2 large-up-3 align-center">
                    {mediaCards}
                </div>

                <nav aria-label="Pagination">
                    <ul className="pagination">
                        <li className={`pagination-previous${!this.state.isFirst ? '' : ' disabled'}`}>
                            {this.state.isFirst ?
                                (<>Previous <span className="show-for-sr">page</span></>) :
                                <a href="#top" onClick={() => this.changePage(this.state.currentPage - 1)}>
                                    Previous <span className="show-for-sr">page</span>
                                </a>
                            }
                        </li>
                        {(start !== 1) ?
                            (<li className="ellipsis"></li>)
                          :
                          (<li className="invisible ellipsis" aria-hidden="true"></li>)
                        }
                        {Array.from(pages, i => (
                            (this.state.currentPage === i) ?
                                (<li className="current-with-input" key={`library${i}`}>
                                  <span className="show-for-sr">You're on page {i}</span>
                                  <PaginationInput placeholder={i} updatePage={this.inputUpdatePage}/>
                                </li>) :
                                (<li key={`library${i}`}>
                                    <a href="#top" onClick={() => this.changePage(i)}>
                                        {i}
                                    </a>
                                </li>)
                        ))}
                        {(end <= numPages) ?
                          (<li className="ellipsis"></li>)
                          :
                          (<li className="invisible ellipsis" aria-hidden="true"></li>)
                        }
                        <li className={`pagination-next${!this.state.isLast ? '' : ' disabled'}`}>
                            {this.state.isLast ?
                                (<>Next <span className="show-for-sr">page</span></>) :
                                <a href="#top" onClick={() => this.changePage(this.state.currentPage + 1)}>
                                    Next <span className="show-for-sr">page</span>
                                </a>
                            }
                        </li>
                    </ul>
                </nav>

            </div>
        )
    };
}

export default MediaList;
