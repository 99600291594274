import React, { Component } from "react";
import axios from "axios";
import loading from '../Assets/loading.svg';
import { publish } from "../Utilities/build";
import NotificationMessage from "../Components/NotificationMessage";

class BuildInfoPages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publishState: "",
      nextBuildList: {},
      lastBuildList: {},
      deployments: [],
      buildData: {},
      last: null,
      next: null,
      projectUuid: null,
      loading: true
    };
  }

  launchBuild = (id) => {
    publish(id)
      .then(publishData => {
        this.state.buildData[id].notify.current.sendMessage("Build started successfully.", "success")
      })
      .catch(error => {
        console.log(error);
        this.state.buildData[id].notify.current.sendMessage("Could not start build.", "error")
      });
  };

  getDeploymentList = () => {
    axios.get("/services/getDeployments")
      .then(res => {
        const deployments = res.data.deployments

        this.setState({ deployments }, this.getBuildData)
      })
  }

  getBuildData = () => {
    this.setState({ loading: true })
    let promises = []
    this.state.deployments.forEach(deployment => {
      let thing = axios.get(`/services/getBuild/${deployment.id}`)
      promises.push(thing)
    })

    Promise.all(promises).then(responses => {
      let { buildData } = this.state
      responses.forEach(res => {
        buildData[res.data.deployment] = res.data
        buildData[res.data.deployment].notify = React.createRef()
      })
      this.setState({ buildData, loading: false })
    })

  }

  componentDidMount() {
    //this.getBuildInformation();
    this.getDeploymentList()

    this.interval = setInterval(() => {
      this.getBuildData();
    }, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="row">
        <div className="small-12 columns">
          <h1 className="subheader">Build Info</h1>
          <p>information is refreshed every 1 minute. {this.state.loading && <img src={loading} alt="loading icon" className="inline-loading-image" />}</p>

          {this.state.deployments.map((deployment, idx) =>
            <div key={idx}>
              <h2>{deployment.name}</h2>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <strong>Latest build</strong>
                    </td>
                    <td>
                      {this.state.buildData[deployment.id] ? this.state.buildData[deployment.id].last : ""}
                    </td>
                    <td>
                      {this.state.buildData[deployment.id] &&
                        (deployment.type === "codeship" ?
                          (<img
                            src={`https://app.codeship.com/projects/${
                              this.state.buildData[deployment.id].projectUuid
                              }/status?branch=master`}
                            alt="status"
                          />) :
                          this.state.buildData[deployment.id].status
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Next build</strong>
                    </td>
                    <td>
                      {this.state.buildData[deployment.id] ? this.state.buildData[deployment.id].next : ""}
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
              <div className="spacer-bottom row">
                <div className="columns small-2">
                  <button
                    type="button"
                    className="button warning"
                    onClick={() => this.launchBuild(deployment.id)}
                  >
                    Build Now
                </button>
                </div>
                <div className="columns">
                  <NotificationMessage message={this.state.message} small={true} ref={this.state.buildData[deployment.id] ? this.state.buildData[deployment.id].notify : null} />
                </div>
              </div>
            </div>
          )}

          <div className="spacer-bottom">
            {this.state.publishState && (
              <div className="sidebar__message">{this.state.publishState}</div>
            )}
          </div>
          <div className="spacer-bottom">
            <h3>FAQ</h3>
            <h4>What is the Build action?</h4>
            <ul>
              <li>
                The <strong>Build action</strong> will publish the whole website
                with the latest changes.
              </li>
              <li>
                Items with a publish date will only be included in the changes
                if the <em>publish date</em> is not in the future.
              </li>
            </ul>

            <h4>
              What is the difference between Save action and Build action?
            </h4>
            <ul>
              <li>
                <strong>Save</strong> will store your changes in the database,
                but website users will not see them until the next Build
                happens.
              </li>
              <li>
                <strong>Build</strong> will publish all the latest changes and
                expose them to the website users.
              </li>
            </ul>
            <h4>How to Build?</h4>
            <ul>
              <li>
                You can Build the website from this page by clicking the
                {" "}<strong>Build Now</strong> button.
              </li>
              <li>
                You can Build the website from an item that you just modified by
                clicking the <strong>Save and Build</strong> button.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default BuildInfoPages;
