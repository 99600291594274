import React, { Component } from 'react';

import '../Styles/app.scss';
import history from '../Utilities/history'
import Auth from '../Auth/Auth'
import BuildInfoHeader from '../Build/BuildInfoHeader'

export default class Navbar extends Component {
    goTo = (route) => {
        history.replace(`/${route}`)
    }

    login = () => {
        Auth.login();
    }

    logout = () => {
        Auth.logout();
        Auth.forceLogout();
    }

    getProfile = () => {
        return Auth.getProfile().name;
    }

    componentDidMount() {
      Auth.registerComponentToUpdate(() => this.forceUpdate())
    }
    
    render() {
        return Auth.isAuthenticated() && (
            <div className="top-bar">
                <div className="top-bar-left">
                    <ul className="menu">
                        <li>
                            <button className="button" onClick={() => this.goTo('')} >
                                Home
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="top-bar-right">
                    <ul className="menu">
                        { Auth.isAuthenticated() ? (
                            <>
                                <li>
                                    <BuildInfoHeader goTo={this.goTo}/>
                                </li>
                                <li className="menu-text">                       
                                    <label>{this.getProfile()}</label>
                                </li>
                                <li>
                                    <button className="button" id="qsLogoutBtn" onClick={this.logout} >
                                        Log Out
                                    </button>
                                </li>
                            </>
                        ) : (
                            <li>
                                <button className="button" id="qsLoginBtn" onClick={this.login} >
                                    Log In
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        );
    }
}
