import React from 'react';
import { AtomicBlockUtils, CompositeDecorator, Editor, EditorState, RichUtils, getDefaultKeyBinding } from 'draft-js';
import { mediaBlockRenderer } from "./entities/mediaBlockRenderer";
import { linkStrategy, Link } from "./entities/linkRenderer";
import { IconBlock } from '../Components/Icon'
import 'draft-js/dist/Draft.css';
import '../Styles/richTextEditor.css';
import MediaAPI from '../Media/MediaAPI'

class FluxRTE extends React.Component {
    decorator = new CompositeDecorator([
        {
          strategy: linkStrategy,
          component: Link,
        },
    ]);

    constructor(props) {
        super(props);
        
        this.state = {
            editorState: EditorState.createEmpty(this.decorator),
            showURLInput: false,
            urlValue: '',
        };

        this.focus = () => this.refs.editor.focus();
        this.onChange = (editorState) => {
            this.props.onChange('editorState', editorState);
            this.setState({editorState});
        }

        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
        this.onAddImage = this._onAddImage.bind(this);
        this.onAddLink = this._onAddLink.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const newState = EditorState.set(nextProps.editorState, {decorator: this.decorator});
        this.setState({editorState: newState});
    }

    _onAddImage = async (e) => {
        e.preventDefault();
        const editorState = this.state.editorState;
        const files = Array.from(e.target.files);
        const formData = new FormData();
        formData.append('file', files[0]);

        MediaAPI.upload(formData)
        .then((res) => {
            return res.image;
        })
        .then((image) => {
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity(
                "IMAGE", 
                "IMMUTABLE",
                { src: image.name }
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(
                editorState,
                { currentContent: contentStateWithEntity },
                "create-entity"
            );
            this.setState({
                editorState: AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")},
                () => { setTimeout(() => this.focus(), 0);}
            );
        });
    };

    _onAddLink = (e) => {
        e.preventDefault();
        const editorState = this.state.editorState;
        const selection = editorState.getSelection();
        let url = '';
        // if the selection has a link associated, set the default prompt url
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = editorState.getSelection().getStartKey();
            const startOffset = editorState.getSelection().getStartOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            
            if (linkKey) {
              const linkInstance = contentState.getEntity(linkKey);
              url = linkInstance.getData().url;
            }
        }
        const link = window.prompt('Paste URL, or delete to remove link', url)
        if (!link) {
            this.setState({
                editorState: RichUtils.toggleLink(editorState, selection, null)},
                () => { setTimeout(() => this.refs.editor.focus(), 0);}
            );
            return 'handled';
        }
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity('LINK', 'MUTABLE', { url: link });
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentWithEntity },  "create-entity");
        
        this.setState({
            editorState: RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey)},
            () => { setTimeout(() => this.refs.editor.focus(), 0);}
        );
    };

    _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    _mapKeyToEditorCommand(e) {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                this.state.editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== this.state.editorState) {
                this.onChange(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    }

    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    render() {
        const {editorState} = this.state;

        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        let className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            <div className="RichEditor-root form-margin-bottom">
                <BlockStyleControls
                editorState={editorState}
                onToggle={this.toggleBlockType}
                />
                <InlineStyleControls
                editorState={editorState}
                onToggle={this.toggleInlineStyle}
                onAddImage={this.onAddImage}
                onAddLink={this.onAddLink}
                />
                <div className={className} onClick={this.focus}>
                    <Editor
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        keyBindingFn={this.mapKeyToEditorCommand}
                        onChange={this.onChange}
                        placeholder={this.props.placeHolder ? this.props.placeHolder : ''}
                        ref="editor"
                        spellCheck={true}
                        blockRendererFn={mediaBlockRenderer}
                    />
                </div>
            </div>
        );
    }
}

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        default: return null;
    }
}

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.label}
            </span>
        );
    }
}

const BLOCK_TYPES = [
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
    {label: 'Blockquote', style: 'blockquote'},
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    {label: 'Code Block', style: 'code-block'},
];

const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                key={type.label}
                active={type.style === blockType}
                label={type.label}
                onToggle={props.onToggle}
                style={type.style}
                />
            )}
        </div>
    );
};

var INLINE_STYLES = [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
    {label: 'Monospace', style: 'CODE'},
];

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map((type) =>
                <StyleButton
                key={type.label}
                active={currentStyle.has(type.style)}
                label={type.label}
                onToggle={props.onToggle}
                style={type.style}
                />
            )}

            <span className='RichEditor-styleButton' onMouseDown={props.onAddLink}>
                <IconBlock icon="link" size="lg" />
            </span>

            <span className='RichEditor-styleButton'>
                <label htmlFor="pic" className="picIcon">
                    <IconBlock icon="image" size="lg" />
                </label>
                <input type='file' id='pic' onChange={props.onAddImage} accept="image/*" hidden /> 
            </span>
        </div>
    );
};

export default FluxRTE;