import React, { Component } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Formik, Field, FieldArray, Form } from 'formik';
import { mdToDraftjs, draftjsToMd } from 'draftjs-md-converter';
import { animateScroll as scroll } from 'react-scroll';

import Auth from '../Auth/Auth'
import EditionAPI from '../Editions/EditionAPI';
import FluxRTE from '../Components/FluxRTE';
import MediaAPI from '../Media/MediaAPI';
import SponsorAPI from './SponsorAPI';
import SponsorTypeAPI from '../SponsorTypes/SponsorTypeAPI';
import Loading from '../Components/Loading';
import { ErrorMessage } from '../Components/ErrorMessage';
import PublishModule from "../Components/PublishModule"
import ImagePicker from '../Components/ImagePicker';
import { parseSocialMediaInput } from 'shared';
import { sponsorSchema } from 'shared';
import { currentTimeRounded, formatDateTime } from '../Utilities/format';
import history from '../Utilities/history';
import { changesSavedPrompt, removeObjectPrompt, unsavedChangesPrompt, Wrapper } from '../Utilities/formPrompts';
import Moment from '../Utilities/moment';
import NotificationMessage from "../Components/NotificationMessage";

const initialValues = {
    name: '',
    description: '',
    socialMedia: [],
    imageId: "",
    editionId: '',
    sponsorType: '',
    publish: 'draft',
    publishedOn: '',
    publishDate: '',
    editorState: EditorState.createEmpty(),
}

class SponsorForm extends Component {
    constructor(props) {
        super(props);
        this.formik = React.createRef();
        this.unsavedChanges = new Wrapper(false);
        this.submittingFromModal = new Wrapper(false);
        this.nextLocation = history.location;
        this.notify = React.createRef();
        this.state = this.defaultState = {
            data: initialValues,
            id: '',
            message: '',
            logo: null,
            editions: [{ "id": "", "name": "" }],
            sponsorTypes: [{ "id": "", "name": "" }],
            viewPublishState: false,
            viewPublishDate: false,
            next: null,
            nextReady: false
        };
        this.formik = React.createRef();
    }

    componentDidMount() {
        if (this.props.id) {
            SponsorAPI.getSponsor(this.props.id)
                .then(res => {
                    if (!this.isCancelled) {
                        const sponsor = { ...res.sponsor };
                        this.getBuildInformation(sponsor.id, sponsor.publishedOn);
                        document.title = `${sponsor.name} | Sponsor | ${process.env.REACT_APP_TITLE}`;

                        if (sponsor.editionId === null) sponsor.editionId = '';
                        if (sponsor.imageId === null) {
                            sponsor.imageId = "";
                        } else {
                            sponsor.logo.preview = process.env.REACT_APP_MEDIA_URL + sponsor.logo.name;
                        }

                        this.oldLogo = sponsor.logo;

                        if (sponsor.publishedOn !== null) {
                            sponsor.publishDate = Moment(sponsor.publishedOn).toDate();
                            sponsor.publishedOn = formatDateTime(sponsor.publishedOn);
                            sponsor.publish = 'publish'
                        } else {
                            const now = currentTimeRounded();
                            sponsor.publishDate = Moment(now).toDate();
                            sponsor.publishedOn = '';
                            sponsor.publish = 'draft';
                        }

                        sponsor.editorState = sponsor.description ?
                            EditorState.createWithContent(convertFromRaw(mdToDraftjs(sponsor.description)))
                            : EditorState.createEmpty();

                        this.setState({ data: sponsor, id: sponsor.id, logo: sponsor.logo, mounted: true }, () => {
                            // send message when brand new blog post after history push happens.
                            if (this.state.mounted && history.location.state && history.location.state.notify.message !== null) {
                                this.sendNotification(history.location.state.notify.message, history.location.state.notify.type);
                                history.replace({ state: null });
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.sendNotification(error.toString(), 'error')
                })
        } else {
            const sponsor = { ...this.state.data };
            const now = currentTimeRounded();

            sponsor.publishDate = Moment(now).toDate();
            this.setState({ mounted: true, data: sponsor });
            document.title = `New Sponsor | ${process.env.REACT_APP_TITLE}`;
        }

        EditionAPI.getEditions()
            .then(res => {
                this.setState({ editions: this.state.editions.concat(res.editions) });
            })
            .catch((err) => {
                console.log(err)
                this.sendNotification(err.toString(), 'error')
            });

        SponsorTypeAPI.getVisibleSponsorTypes()
            .then(res => {
                this.setState({ sponsorTypes: this.state.sponsorTypes.concat(res.sponsorTypes) });
            })
            .catch((err) => {
                console.log(err)
                this.sendNotification(err.toString(), 'error')
            });

        this.unblock = history.block((nextLocation) => {
            this.nextLocation = nextLocation;
            if (this.unsavedChanges.get()) {
                unsavedChangesPrompt(nextLocation, this.unsavedChanges, this.formik.current, this.submittingFromModal)
                return false;
            }
            else return true;
        });
    }

    componentWillUnmount() {
        this.isCancelled = true;
        this.unblock();
    }

    cleanSponsor(values) {
        let url = '';
        let method = 'post';
        if (this.state.id) {
            url += this.state.id;
            method = 'put';
        }

        const sponsor = { ...values };
        delete sponsor.logo;
        this.oldLogo = this.state.logo;

        if (sponsor.publish === 'publish' || (!sponsor.publish && sponsor.publishedOn)) {
            sponsor.publishedOn = Moment(sponsor.publishDate).toISOString();
        } else {
            sponsor.publishedOn = null;
        }
        delete sponsor.publish;

        sponsor.description = draftjsToMd(convertToRaw(values.editorState.getCurrentContent()));
        delete sponsor.editorState;
        delete sponsor.edition;
        if (sponsor.editionId === '') sponsor.editionId = null;

        sponsor.socialMedia.map((social) => {
            const obj = 'sponsor';
            return parseSocialMediaInput(social, obj);
        });

        if (this.state.logo) {
            MediaAPI.saveMedia(this.state.logo.id, "put", { ...this.state.logo })
        }

        return { url, method, sponsor };
    }

    onSubmit = (values) => {
        const { url, method, sponsor } = this.cleanSponsor(values);

        SponsorAPI.saveSponsor(method, url, sponsor)
            .then(data => {
                this.sendNotification(data.message, 'success')
                this.unsavedChanges.set(false);
                this.setState({ data: values, id: data.sponsor.id, message: data.message });
                scroll.scrollToTop();

                this.getBuildInformation(data.sponsor.id, data.sponsor.publishedOn);
                history.replace({
                    pathname: `/sponsors/${this.state.id}/edit`,
                    state: { notify: { message: data.message, type: 'success' } }
                });
            })
            .catch(error => {
                this.sendNotification(error.toString(), 'error')
                scroll.scrollToTop();
            })
            .then(() => {
                if (this.submittingFromModal.get()) {
                    changesSavedPrompt(this.nextLocation, this.unsavedChanges);
                }
                this.submittingFromModal.set(false);
            });
    };

    revertChanges = () => {
        this.formik.current.resetForm();
        this.sendNotification("Reverted changes", 'warning')
        this.setState({ logo: this.oldLogo });
        scroll.scrollToTop();
    }

    updateViewPublishState = () => {
        this.setState((state, props) => ({
            viewPublishState: !state.viewPublishState
        }));
    };

    updateViewPublishDate = () => {
        this.setState((state, props) => ({
            viewPublishDate: !state.viewPublishDate
        }));
    };

    publishChanges = () => {
        const values = this.formik.current.values;
        const { url, method, sponsor } = this.cleanSponsor(values);

        SponsorAPI.saveSponsor(method, url, sponsor)
            .then(data => {
                this.unsavedChanges.set(false);
                this.sendNotification(data.message, 'success');

                SponsorAPI.publishSponsor(data.sponsor.id)
                    .then(publishData => {
                        this.setState({
                            data: values,
                            id: data.sponsor.id,
                        });
                        this.sendNotification(publishData.message, 'success');
                        scroll.scrollToTop();
                        this.getBuildInformation(values.id, values.publishedOn);
                    })
                    .catch(error => {
                        this.setState({
                            data: values,
                            id: data.sponsor.id
                        });
                        scroll.scrollToTop();
                        this.sendNotification(`Publish ${error.toString()}`, 'error');
                    });
            })
            .catch(error => {
                this.sendNotification(error.toString(), 'error')
                scroll.scrollToTop();
            });
    }

    createSponsor = () => {
        this.setState({
            data: initialValues,
        })
        history.replace('/sponsors/new');
    }

    removeSponsor = () => {
        removeObjectPrompt(
            'sponsor',
            SponsorAPI.deleteSponsor,
            '/sponsors',
            this.state.id,
            (message) => this.sendNotification(message, "error")
        );
    }

    onDrop = (acceptedFiles, rejectedFiles, setFieldValue) => {
        if (acceptedFiles.length === 0) return;

        let formdata = new FormData();
        formdata.append('file', acceptedFiles[0]);

        MediaAPI.upload(formdata).then((res) => {
            let image = res.images[0]
            setFieldValue('imageId', image.id)
            image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
            this.setState({ logo: image });
        });
    }

    getBuildInformation = (id, date) => {
        const momentDate = Moment(date)
        if (momentDate.isValid()) {
            SponsorAPI.getSponsorBuilds(id, momentDate.format())
                .then(res => {
                    this.setState({ next: res.next, nextReady: true });
                })
                .catch(err => {
                    this.sendNotification(err.toString(), 'error')
                })
        }
    };

    removeLogo = (setFieldValue) => {
        setFieldValue('imageId', "");
        this.setState({
            logo: null,
        });
    };
    onChangeAltText = (e) => {
        e.preventDefault()
        let logo = this.state.logo
        logo.altText = e.target.value
        this.setState({ logo: logo })
    }

    setPhotoFromLibrary = (setFieldValue, newImage) => {
        const image = { ...newImage }
        setFieldValue('imageId', image.id);
        image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
        this.setState({
            logo: image,
        });
    }

    socialMediaForm = (values) => (
        <div id="socialMediaForm">
            <p>Social media</p>
            <FieldArray
                name="socialMedia"
                render={arrayHelpers => (
                    <div>
                        {values.socialMedia && values.socialMedia.map((social, index) => (
                            <div key={index} className="grid-x grid-padding-x">
                                <div className="medium-6 cell">
                                    <label>Type</label>
                                    <Field name={`socialMedia.${index}.type`} component="select">
                                        <option value=""></option>
                                        <option value="website">Website</option>
                                        <option value="twitter">Twitter</option>
                                        <option value="linkedin">LinkedIn</option>
                                        <option value="instagram">Instagram</option>
                                        <option value="facebook">Facebook</option>
                                    </Field>
                                </div>
                                <div className="medium-5 cell">
                                    <label htmlFor={`socialMedia.${index}.value`}>Value</label>
                                    <Field type="text" name={`socialMedia.${index}.value`} id={`socialMedia.${index}.value`} />
                                    <ErrorMessage name={`socialMedia.${index}.value`} />
                                </div>
                                <div className="medium-1 cell">
                                    {Auth.hasRole(['admin', 'editor']) ?
                                        (<button type="button" className="button alert tiny" onClick={() => arrayHelpers.remove(index)}>
                                            -
                                        </button>) : null
                                    }
                                </div>
                            </div>
                        ))}
                        {Auth.hasRole(['admin', 'editor']) ?
                            (<button
                                type="button"
                                className="button secondary"
                                onClick={() => arrayHelpers.push({ type: '', value: '' })}>
                                + add a social
                            </button>) : null
                        }
                    </div>
                )}
            />
        </div>
    );

    // This custom setValue lets us prevent publishing without required values
    customSetValue = (key, value, setValue, formValues) => {
      if(key === "publish" && value === "publish") {
        if(!formValues.imageId){
          this.sendNotification("Cannot publish a sponsor with no logo", "warning")
        } else if(!formValues.editionId){
          this.sendNotification("Cannot publish a sponsor with no edition", "warning")
        } else if(!formValues.sponsorType){
          this.sendNotification("Cannot publish a sponsor with no sponsor type", "warning")
        } else {
          setValue(key, value)
        }
      } else {
        setValue(key, value)
      }
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }

    renderForm = ({ dirty, values, handleBlur, handleSubmit, handleChange, errors, setFieldValue, setFieldTouched, isSubmitting }) => {
        this.unsavedChanges.set(dirty && !isSubmitting);
        return (
            <Form>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-8">
                        {this.state.id && <button type="button" className="hollow button" onClick={this.createSponsor}>New Sponsor</button>}
                        <label htmlFor="name">Name</label>
                        <Field type="text" name="name" id="name" />
                        <ErrorMessage name='name' />

                        <label>Sponsor type</label>
                        <Field component="select" name="sponsorTypeId">
                            {this.state.sponsorTypes.map((sponsorType, idx) => (
                                <option value={sponsorType.id} key={idx}>
                                    {sponsorType.name}
                                </option>
                            ))}
                        </Field>

                        <label>Edition</label>
                        <Field component="select" name="editionId">
                            {this.state.editions && this.state.editions.map((edition, idx) => (
                                <option value={edition.id} key={idx}>
                                    {edition.name}
                                </option>
                            ))}
                        </Field>

                        <label>Description</label>
                        <FluxRTE
                            editorState={values.editorState}
                            onChange={setFieldValue}
                            onBlur={handleBlur}
                        />

                        <Field type="hidden" name="imageId" />
                        <p>Logo</p>
                        {this.state.logo ? (
                            <>
                                <img
                                    alt="Preview"
                                    key={this.state.logo.preview}
                                    src={this.state.logo.preview}
                                    className="image-preview"
                                /><br />
                                {Auth.hasRole(['admin', 'editor']) ?
                                    <div>
                                        <div className="row">
                                            <label>Alt-text:</label>
                                        </div>
                                        <div className="row">
                                            <input type="text" id="altTextInput" value={this.state.logo.altText} onChange={this.onChangeAltText} />
                                        </div>
                                    </div>
                                    : ''}
                                {Auth.hasRole(['admin', 'editor']) && <button type="button" className="button alert" onClick={() => { this.removeLogo(setFieldValue) }}>Remove logo</button>}
                            </>
                        ) : (
                                <>
                                    {Auth.hasRole(['admin', 'editor']) &&
                                        <ImagePicker onDrop={this.onDrop} setImage={this.setPhotoFromLibrary} setFieldValue={setFieldValue} />
                                    }</>
                            )}

                        {this.socialMediaForm(values)}
                    </div>
                    <div className="cell small-4  sidebar">
                        <PublishModule
                            setFieldValue={(key, value) => this.customSetValue(key, value, setFieldValue, values)}
                            setFieldTouched={setFieldTouched}
                            values={values}
                            publishChanges={this.publishChanges}
                            revertChanges={this.revertChanges}
                            removeObject={this.removeSponsor}
                            objectId={this.state.id}
                            next={this.state.next}
                            nextReady={this.state.nextReady}
                            currentPublishState={values.publish}
                        />

                        <NotificationMessage message={this.state.message} ref={this.notify} />
                    </div>
                </div>
            </Form>
        )
    };

    render = () => (
        <div>
            {!this.state.mounted && <Loading />}
            {this.state.data ? (
                <Formik initialValues={this.state.data}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                    validationSchema={sponsorSchema}
                    innerRef={this.formik}
                >
                  {this.renderForm}
                </Formik>
            ) : null}
        </div>
    );
}

export default SponsorForm;
