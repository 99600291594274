import React, { Component } from 'react';
import ReactDropzone from 'react-dropzone';
import Modal from 'react-modal';
import $ from 'jquery';
import MediaLibrary from '../Components/MediaLibrary'

window.jQuery = window.$ = $;

Modal.setAppElement('#root')
const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width                 : '90%',
      maxWidth              : '75rem',
      height                : '90%',
      maxHeight             : '50rem'
    },
    overlay : {
        zIndex              : 5000,
    }
  };

export default class ImagePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false
        };
    
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }
     
    afterOpenModal() {
        $('#image-picker').foundation();
    }
     
    closeModal() {
        this.setState({modalIsOpen: false});
    }

    render() {
        return (
            <>
            <p><button onClick={this.openModal} type="button" className="button">{this.props.buttonText ? this.props.buttonText: 'Select an image'}</button></p>
            <Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                >
                <div className="image-picker" id="image-picker">
                    <h5>Select an image</h5>
                    <ul className="tabs" data-tabs id="image-picker-tabs">
                        <li className="tabs-title is-active"><a href="#dropzone">Upload file</a></li>
                        <li className="tabs-title"><a href="#library">Media library</a></li>
                    </ul>
                    <div className="tabs-content" data-tabs-content="image-picker-tabs">
                        <div className="tabs-panel is-active" id="dropzone">
                            <ReactDropzone accept="image/*" onDrop={(acceptedFiles, rejectedFiles) => {
                                this.props.onDrop(acceptedFiles, rejectedFiles, this.props.setFieldValue);
                                this.closeModal();
                                }}>
                                  {({getRootProps, getInputProps}) => (
                                    <div className="dropzoneImage" {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <span>Drag & drop an image here, or click to select an image file to upload.</span>
                                    </div>
                                  )}
                            </ReactDropzone>
                        </div>
                        <div className="tabs-panel" id="library">
                            <MediaLibrary setImage={this.props.setImage} closeModal={this.closeModal} setFieldValue={this.props.setFieldValue} />
                        </div>
                    </div>
                    <button onClick={this.closeModal} className="close-button" aria-label="Close modal" type="button">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                </Modal>
            </>
        );
    }
}