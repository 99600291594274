import React, { Component } from 'react';


class BuildInfoHeader extends Component {
    
 render() {
     return (
         <>
             <button type="button" className="button hollow" onClick={() => this.props.goTo('build/')}>Build info</button>
         </>
     )
 }
}

export default BuildInfoHeader;