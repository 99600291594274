import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Auth from '../Auth/Auth'
import UserAPI from './UserAPI'
import RoleAPI from '../Roles/RoleAPI'
import Loading from '../Components/Loading';
import FilterSelect from '../Components/FilterSelect'
import { verifyId, getRefineOptions } from '../Utilities/refineOptions';
import _ from 'lodash';
import NotificationMessage from "../Components/NotificationMessage";

const refineOptionList = ['filterBy', 'filterId'];
const filterBy = ['role'];
const filterByIndices = {role: 0};

class UserList extends Component {
    constructor(props) {
        super(props);
        let message = '';
        const refineOptions = getRefineOptions('user', refineOptionList);
        if (this.props.location.state && this.props.location.state.deletedObject) {
            message = 'User successfully deleted';
        }
        const filterId = ['All'];
        this.state = { 
            users: [],
            roles: [{"id": "none", "name": ""}],
            message,
            filterBy,
            filterId,
            refineOptions,
         };
         this.notify = React.createRef();
    }
    
    componentDidMount() {
        document.title = `Users | ${process.env.REACT_APP_TITLE}`;
        UserAPI.getUsers()
        .then(res => this.setState({ users: res.users, mounted: true }))
        .catch(err => {
          this.setState({mounted: true})
          this.sendNotification(`Could not load editions (${err.response.status})`, "error")
        });
        RoleAPI.getRoles()
        .then(res => this.setState({ roles: this.state.roles.concat(res.roles), mounted: true}))
        .catch(err => this.sendNotification(`Could not load role options (${err.response.status})`, "error"))

        for (const i in refineOptionList) {
            const option = refineOptionList[i];
            const key = 'user' + _.upperFirst(option);
            if (!localStorage[key]) {
                localStorage.setItem(key, this.state[option]);
            } else if (this.state[option] !== this.state.refineOptions[option]) {
                this.setState({[option]: this.state.refineOptions[option]});
            }
            if (option === 'filterId') {
                let verifiedIds = this.state.filterId.slice();
                const skipIds = ['All', 'none'];
                for (const j in this.state.filterId) {
                    if (filterBy[j] === 'edition' && !skipIds.includes(verifiedIds[j])) {
                        verifiedIds[j] = verifyId(this.state.editions, verifiedIds[j]);
                    }
                }
                if (verifiedIds !== this.state.filterId) {
                    this.setState({filterId: verifiedIds});
                    localStorage.setItem(key, verifiedIds);
                }
            }
        }
    }

    fetchUsers = async () => {
        UserAPI.filterUsers()
        .then(res => {
            this.setState({ users: res.users });
        })
        .catch(err => { console.log(err)});
    }

    setFilter = (value, type) => {
        if(value !== "none"){
            const index = filterByIndices[type];
            const ids = this.state.filterId;
            let newIds = ids;
            newIds[index] = value;
            localStorage.setItem('userFilterId', newIds);
            this.fetchUsers()
            .then(() => {
                this.setState({filterId: newIds});
            })
            .catch((err) => {
                localStorage.setItem('userFilterId', this.state.filterId);
                this.sendNotification('Could not filter users', "error")
            })
        }
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }

    render = () => {
        return (
            <div>
                {!this.state.mounted && <Loading />}

                <div className="grid-x">
                  <div className="cell small-4">
                    {Auth.hasRole(['admin']) ?
                      (<Link to="/users/new" className="button">Add User</Link>) : null
                    }
                  </div>
                  <div className="cell small-8">
                    <NotificationMessage message={this.state.message} small={true} ref={this.notify} />
                  </div>
                </div>

                <div className="grid-x grid-padding-x">
                    <div className="medium-3 cell">
                        <label>Filter by Role: 
                            <FilterSelect options={this.state.roles} setSelectedFilter={this.setFilter} type="role" value={this.state.filterId[filterByIndices.role]} />
                        </label>
                    </div>
                </div>
                <div>{this.state.message}</div>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.users.map(user => (
                            <tr key={user.id}>
                                <td><strong><Link to={`/users/${user.id}/edit`}>{user.name}</Link></strong></td>
                                <td>{user.email}</td>
                                <td>{user.role ? user.role.name : ''}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
    )};
}

export default UserList;
