import axios from 'axios'
import saveError from '../Utilities/saveError';
const API_URL = '/api/editions/';
const Edition = {
    getEditions: async () => {
      return new Promise((resolve, reject) => {
        axios(API_URL)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    deleteEdition: async (id) => {
      return new Promise((resolve, reject) => {
        axios.delete(API_URL + id)
            .then(response => resolve(response.data))
            .catch(error => saveError(error, reject));
      })
    },

    getEdition: async (id) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + id)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    saveEdition: async (url, method, values) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(values)
        })
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
      })
    },
}

export default Edition;
