import React from 'react';
import { Formik, Field, Form } from 'formik';
import {animateScroll as scroll} from 'react-scroll'; 

import Auth from '../Auth/Auth'
import SponsorTypeAPI from './SponsorTypeAPI';
import { ErrorMessage } from '../Components/ErrorMessage';
import Loading from '../Components/Loading';
import { sponsorTypeSchema } from 'shared';
import history from '../Utilities/history';
import { changesSavedPrompt, removeObjectPrompt, unsavedChangesPrompt, Wrapper } from '../Utilities/formPrompts';
import NotificationMessage from "../Components/NotificationMessage";

const initialValues = {
    name: '',
    visible: false,
    canPluralize: true,
    showDescription: true,
};

class SponsorTypeForm extends React.Component {
    constructor(props) {
        super(props);
        this.formik = React.createRef();
        this.unsavedChanges = new Wrapper(false);
        this.submittingFromModal = new Wrapper(false);
        this.nextLocation = history.location;
        this.state = { data: initialValues, message: '', id: '' };
        this.notify = React.createRef();
    }

    componentDidMount() {
        if (this.props.id) {
            SponsorTypeAPI.getSponsorType(this.props.id)
            .then(res => {
                if (!this.isCancelled) {
                    document.title = `${res.sponsorType.name} | Sponsor Type | ${process.env.REACT_APP_TITLE}`;
                    this.setState({ data: res.sponsorType, id: res.sponsorType.id, mounted: true });
                }
            })
            .catch(err => console.log(err));
        } else {
            document.title = `New Sponsor Type | ${process.env.REACT_APP_TITLE}`;
            this.setState({ mounted: true });
        }

        this.unblock = history.block((nextLocation) => {
            this.nextLocation = nextLocation;
            if(this.unsavedChanges.get()) {
                unsavedChangesPrompt(nextLocation, this.unsavedChanges, this.formik.current, this.submittingFromModal)
                return false;
            }
            else return true;
        });
    }

    componentWillUnmount() {
        this.isCancelled = true;
        this.unblock();
    }
    
    onSubmit = (values) => {
        let url = '';
        let method = 'post';

        if (this.state.id) {
            url += this.state.id;
            method = 'put';
        }

        SponsorTypeAPI.saveSponsorType(method, url, values)
            .then(data => {
                this.unsavedChanges.set(false);
                this.setState({ data: values, id: data.sponsorType.id});
                this.sendNotification(data.message, 'success')
                scroll.scrollToTop();
                history.replace(`/sponsorTypes/${this.state.id}/edit`)
            })
            .catch(error => {
                this.sendNotification(error.toString(), 'error')
                scroll.scrollToTop();
            })
            .then(() => {
                if (this.submittingFromModal.get()) {
                    changesSavedPrompt(this.nextLocation, this.unsavedChanges);
                }
                this.submittingFromModal.set(false);
            });
    };

    revertChanges = () => {
        this.formik.current.resetForm();
        this.sendNotification('Reverted changes', 'success')
        this.setState({ message: 'Reverted changes' });
        scroll.scrollToTop();
    }

    createSponsorType = () => {
        this.setState({
            data: initialValues,
        })
        history.replace('/sponsorTypes/new');
    }

    removeSponsorType = () => {
        removeObjectPrompt('sponsorType', SponsorTypeAPI.deleteSponsorType, '/sponsorTypes', this.state.id, (message) => this.sendNotification(message, 'error'));
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }

    renderForm = ({ dirty, values, handleSubmit, handleChange, errors, setFieldValue, isSubmitting }) => {
        this.unsavedChanges.set(dirty && !isSubmitting);
        return(
        <Form>
            {this.state.id && <button type="button" className="hollow button" onClick={this.createSponsorType}>New Sponsor Type</button>}
            <label htmlFor="name">Name: </label>
            <Field type="text" name="name" id="name" placeholder={'For example, "Fluxible Conference 40-minute presentation"'} />
            <ErrorMessage name='name' />
            <label><span>Visible: </span>
                <input
                    type="checkbox"
                    id="visible"
                    checked={values.visible}
                    onChange={() =>
                        setFieldValue('visible', !values.visible)
                    }
                />
            </label>
            <Field type="hidden" name="visible" />
            <label><span>Append "s" to the type name when this type has multiple sponsors: </span>
                <input
                    type="checkbox"
                    id="canPluralize"
                    checked={values.canPluralize}
                    onChange={() =>
                        setFieldValue('canPluralize', !values.canPluralize)
                    }
                />
            </label>
            <Field type="hidden" name="canPluralize" />
            <label><span>Show desciptions for these sponsors: </span>
                <input
                    type="checkbox"
                    id="showDescription"
                    checked={values.showDescription}
                    onChange={() =>
                        setFieldValue('showDescription', !values.showDescription)
                    }
                />
            </label>
            <Field type="hidden" name="showDescription" />
            <div className="grid-x">
              <div className="cell small-4">
                {Auth.hasRole(['admin', 'editor']) ?                
                    (<>
                    <button type="submit" className="button">Save</button>
                    <button type="button" className="hollow button" onClick={this.revertChanges}>Revert</button>
                    {this.state.id && <button type="button" className="button-delete" onClick={this.removeSponsorType}>Delete</button>}
                    </>) : null
                }
              </div>
              <div className="cell small-8">
                <NotificationMessage message={this.state.message} small={true} ref={this.notify} />
              </div>
            </div>
        </Form>
    )};

    render() {
        return (
            <div>
                {!this.state.mounted && <Loading />}
                    
                {this.state.data && (
                    <Formik initialValues={this.state.data}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={sponsorTypeSchema}
                        innerRef={this.formik}
                    >
                      {this.renderForm}
                    </Formik>
                )}
            </div>
        )
    }
}

export default SponsorTypeForm;
