import React from 'react';
import { Route } from 'react-router-dom';
import Auth from '../Auth/Auth'
import Loading from '../Components/Loading';

function SecuredRoute(props) {
  let { checkSession, component: Component, path, exact } = props;
  const Exact = exact ? exact : false;
  return (
    <Route exact={Exact} path={path} render={(props) => {
      if (path.includes('/:id')) {
        path = path.replace(':id', props.match.params.id);
      }
      localStorage.setItem('redirectPath', path);
      if (checkSession) return <Loading />
      if (!Auth.isAuthenticated()) {
        Auth.login();
        return <div></div>;
      }
      return <Component {...props} />
    }} />
  );
}

export default SecuredRoute;