import React, { Component } from 'react';
import { min, range, debounce } from 'lodash';
import Loading from "../Components/Loading";
import MediaAPI from '../Media/MediaAPI';

export default class MediaLibrary extends Component {
    constructor(props) {
        super(props);
        this.state = { media: [], currentPage: 1, isFirst: false, isLast: false, searchTerm: '', loading: true };
        this.setImage = this.setImage.bind(this);
        this.changePage = this.changePage.bind(this);
        this.searchLibrary = this.searchLibrary.bind(this);
        this.updateSearchTerm = this.updateSearchTerm.bind(this);
        this.debounceSearch = debounce(this.searchLibrary, 350)
    }

    setImage = (e, image) => {
        this.props.setImage(this.props.setFieldValue, image)
        this.props.closeModal()
    }

    componentDidMount() {
        MediaAPI.filterMedia({ imageOnly: true })
            .then(response => {
                this.setState({ isFirst: true, numPages: response.count, media: response.media, loading: false });
            })
            .catch(error => {
                throw Error(error.response ? error.response : error)
            });
    }

    searchLibrary = () => {
        this.setState({ loading: true })
        MediaAPI.filterMedia({ searchTerm: this.state.searchTerm, imageOnly: true })
            .then(response => {
                const isLast = (response.page === parseInt(response.count))
                this.setState({ isFirst: true, isLast, currentPage: 1, numPages: parseInt(response.count), media: response.media, loading: false });
            })
            .catch(error => {
                throw Error(error.response ? error.response : error)
            });
    }

    updateSearchTerm = (e) => {
        this.setState({
            searchTerm: e.target.value
        });
        this.debounceSearch()
    }

    changePage = (e) => {
        e.preventDefault();
        MediaAPI.filterMedia({ page: e.currentTarget.dataset.page, searchTerm: this.state.searchTerm, imageOnly: true })
            .then(response => {
                const page = parseInt(response.page)
                const isFirst = (page === 1)
                const isLast = (page === parseInt(response.count))
                this.setState({ isFirst, isLast, currentPage: page, numPages: parseInt(response.count), media: response.media });
            })
            .catch(error => {
                throw Error(error.response ? error.response : error)
            });
    }

    render() {
        let start = this.state.currentPage > 5 ? this.state.currentPage - 4 : 1
        let end = min([this.state.currentPage + 4, this.state.numPages]) + 1
        let pages = range(start, end)

        return (
            <>
                <div><input type="text" name="library-search" id="library-search" onChange={this.updateSearchTerm} value={this.state.searchTerm} /></div>
                <div className="gallery">
                    {this.state.loading && <Loading></Loading>}
                    {!this.state.loading ? this.state.media.map(image => (
                        <div className="gallery-item" key={image.id} data-image={image} data-id={image.id} data-name={image.name} data-close="" onClick={(e) => this.setImage(e, image)}>
                            <div className="thumbnail"><img alt={image.altText} src={process.env.REACT_APP_MEDIA_URL + image.name} /></div>
                        </div>
                    )) : ''}
                </div>
                <nav aria-label="Pagination">
                    <ul className="pagination">
                        <li className={`pagination-previous${!this.state.isFirst ? '' : ' disabled'}`}>
                            {this.state.isFirst ?
                                (<>Previous <span className="show-for-sr">page</span></>) :
                                <a data-page={this.state.currentPage - 1} href={`/${this.state.currentPage - 1}`} rel="prev" onClick={this.changePage}>
                                    Previous <span className="show-for-sr">page</span>
                                </a>
                            }
                        </li>
                        {(start !== 1) &&
                            <li className="disabled">...</li>
                        }
                        {Array.from(pages, i => (
                            (this.state.currentPage === i) ?
                                (<li className="current" key={`library${i}`}><span className="show-for-sr">You're on page</span> {i}</li>) :
                                (<li key={`library${i}`}><a key={`pagination-number${i}`} data-page={i} href={`/${i}`} onClick={this.changePage}>
                                    {i}
                                </a></li>)
                        ))}
                        {(end !== pages) &&
                            <li className="disabled">...</li>
                        }
                        <li className={`pagination-next${!this.state.isLast ? '' : ' disabled'}`}>
                            {this.state.isLast ?
                                (<>Next <span className="show-for-sr">page</span></>) :
                                <a data-page={this.state.currentPage + 1} href={`/${this.state.currentPage + 1}`} rel="next" onClick={this.changePage}>
                                    Next <span className="show-for-sr">page</span>
                                </a>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    }
}