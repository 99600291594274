import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import {animateScroll as scroll} from 'react-scroll'; 

import Auth from '../Auth/Auth'
import RoleAPI from '../Roles/RoleAPI'
import UserAPI from './UserAPI'
import { ErrorMessage } from '../Components/ErrorMessage';
import Loading from '../Components/Loading';
import { userSchema } from 'shared'
import history from '../Utilities/history';
import { changesSavedPrompt, unsavedChangesPrompt, Wrapper } from '../Utilities/formPrompts';

const userFields = {
    name: '',
    email: '',
    roleId: '',
};

class UserForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: userFields,
            id: '',
            message: '',
            roles: [{"id": "", "name": ""}],
        };
        this.formik = React.createRef();
        this.unsavedChanges = new Wrapper(false);
        this.submittingFromModal = new Wrapper(false);
        this.nextLocation = history.location;
    }
  
    async componentDidMount() {
        if (this.props.id){
            await UserAPI.getUser(this.props.id)
            .then(res => {
                document.title = `${res.user.name} | User | ${process.env.REACT_APP_TITLE}`;
                if(res.user.roleId === null) res.user.roleId = '';
                !this.isCancelled && this.setState({ data: res.user, id: res.user.id, mounted: true });
            })
            .catch(err=>console.log(err));
        } else {
            document.title = `New User | ${process.env.REACT_APP_TITLE}`
            this.setState({ mounted: true });
        }

        RoleAPI.getRoles()
        .then(res =>{
            this.setState({ roles: this.state.roles.concat(res.roles) });
        })
        .catch(err=>console.log(err));

        this.unblock = history.block((nextLocation) => {
            this.nextLocation = nextLocation;
            if(this.unsavedChanges.get()) {
                unsavedChangesPrompt(nextLocation, this.unsavedChanges, this.formik.current, this.submittingFromModal)
                return false;
            }
            else return true;
        });
    }
    
    componentWillUnmount() {
        this.isCancelled = true;
        this.unblock();
    }
    
    onSubmit = (values) => {
        const url = (this.state.id) ? this.state.id : '';
        const method = (this.state.id) ? 'put' : 'post'; 

        const user = {...values}
        if (user.roleId === '') user.roleId = null;
        
        UserAPI.saveUser(url, method, user)
            .then(data => {
                this.unsavedChanges.set(false);
                this.setState({ data: values, id: data.user.id, message: data.message});
                scroll.scrollToTop();
            })
            .catch(error => {
                this.setState({ message: error.toString() });
                scroll.scrollToTop();
            })
            .then(() => {
                if (this.submittingFromModal.get()) {
                    changesSavedPrompt(this.nextLocation, this.unsavedChanges);
                }
                this.submittingFromModal.set(false);
            });
    };

    revertChanges = () => {
        this.formik.current.resetForm();
        this.setState({ message: 'Reverted changes' });
        scroll.scrollToTop();
    }

    renderForm = ({ dirty, values, handleSubmit, handleChange, errors, setFieldValue, setFieldTouched, isSubmitting }) => {
        this.unsavedChanges.set(dirty && !isSubmitting);
        return(
        <Form>
            <label htmlFor="name">Name</label>
            <Field type="text" name="name"/>
            <ErrorMessage name='name'/>

            <label htmlFor="email">Email</label>
            <Field type="text" name="email"/>
            <ErrorMessage name='email'/>

            {Auth.hasRole(['admin']) ? (
                <>
                <label htmlFor="roleId">Role</label>
                <Field component="select" name="roleId">
                    {this.state.roles ? 
                    (this.state.roles.map((role) => (
                        <option value={role.id} key={role.id}>
                            {role.name}
                        </option>
                    ))): null}
                </Field>
                <ErrorMessage name='roleId'/>
                </>) : null }

            {Auth.hasRole(['admin']) ? (
                <>
                    <button className="button" type="submit">Save</button>
                    <button type="button" className="hollow button" onClick={this.revertChanges}>Revert</button>
                </>
            ) : null }
        </Form>
        )
    };

    render() {
        return (
            <div>
                {!this.state.mounted && <Loading />}
                <div id="message">{this.state.message}</div>
                {this.state.data? (
                    <Formik initialValues={this.state.data}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={ userSchema }
                        innerRef={this.formik}
                    >
                      {this.renderForm}
                    </Formik>
                ) : null}
            </div>
        )   
    };
}

export default UserForm;
