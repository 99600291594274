import React from 'react';
import { Formik, Field, Form } from 'formik';
import { animateScroll as scroll } from 'react-scroll';
import ReactDropzone from 'react-dropzone';

import Auth from '../Auth/Auth'
import { acceptedTypes } from './utils'
import MediaAPI from './MediaAPI';
import { ErrorMessage } from '../Components/ErrorMessage';
import Loading from '../Components/Loading';
import history from '../Utilities/history';
import { changesSavedPrompt, removeObjectPrompt, unsavedChangesPrompt, Wrapper } from '../Utilities/formPrompts';
import NotificationMessage from "../Components/NotificationMessage";
import { PDFObject } from 'react-pdfobject'

const initialValues = {
    name: '',
    altText: '',
    fileName: '',
    uploadedOn: ''
};

const contentTypes = {
    posts: { name: "Blog Post", slug: "blogPosts" },
    editions: { name: "Edition", slug: "editions" },
    events: { name: "Event", slug: "events" },
    pages: { name: "Page", slug: "pages" },
    speakers: { name: "Speaker", slug: "speakers" },
    sponsors: { name: "Sponsor", slug: "sponsors" },
    infrastructure: { name: "Festival Infrastructure", slug: "festivalInfrastructure" },
}

class MediaForm extends React.Component {
    constructor(props) {
        super(props);
        this.formik = React.createRef();
        this.notify = React.createRef();
        this.unsavedChanges = new Wrapper(false);
        this.submittingFromModal = new Wrapper(false);
        this.nextLocation = history.location;
        this.state = { message: '', id: '', processing: false, usedBy: {} };
    }

    componentDidMount() {
        if (this.props.id) {
            this.setState({ data: initialValues })
            MediaAPI.getMedia(this.props.id)
                .then(res => {
                    if (!this.isCancelled) {
                        document.title = `${res.media.filename} | Media | ${process.env.REACT_APP_TITLE}`;
                        this.setState({ data: res.media, id: res.media.id, mounted: true });
                        this.extension = this.getMediaExtension(res.media.filename)
                    }

                    MediaAPI.getUsage(this.props.id)
                        .then(usedBy => {
                            let usesExist = false;
                            Object.keys(usedBy).forEach(key => {
                              if (usedBy[key].length > 0) usesExist = true
                            })
                            if(usesExist) this.setState({ usedBy })
                            else this.setState({usedBy: {none: []}})
                        })
                        .catch(err => {
                            this.sendNotification(err.toString(), 'error')
                        })
                })
                .catch(err => {
                    console.log(err)
                    this.sendNotification(err.toString(), 'error')
                });
        } else {
            document.title = `New Media | ${process.env.REACT_APP_TITLE}`;
            this.setState({ mounted: true });
        }

        this.unblock = history.block((nextLocation) => {
            this.nextLocation = nextLocation;
            if (this.unsavedChanges.get()) {
                unsavedChangesPrompt(nextLocation, this.unsavedChanges, this.formik.current, this.submittingFromModal)
                return false;
            }
            else return true;
        });
    }

    componentWillUnmount() {
        this.isCancelled = true;
        this.unblock();
    }

    getMediaExtension(name) {
      const dotSections = name.split('.')
      return dotSections[dotSections.length -1]
    }

    sendNotification(message, type) {
        if (this.notify.current) this.notify.current.sendMessage(message, type)
    }

    onDrop = (files, rejected) => {
        this.setState({ processing: true })
        const data = new FormData();
        files.forEach(file => {
            data.append("file", file);
        });
        MediaAPI.multiUpload(data)
            .then(res => {
                this.setState({ processing: false })
                this.sendNotification(res.message, 'success')
            })
            .catch(err => {
                this.setState({ processing: false })
                this.sendNotification()
            });
    };

    onSubmit = values => {
        this.setState({ processing: true })
        let url = '';
        let method = 'post';

        if (this.state.id) {
            url += this.state.id;
            method = 'put';
        }

        const newExtension = this.getMediaExtension(values.filename)
        if(newExtension !== this.extension){
          values.filename = `${values.filename}.${this.extension}`
          values.name = `${values.name}.${this.extension}`
          this.sendNotification("Keeping original file extension", "warning")
        }

        MediaAPI.saveMedia(url, method, values)
            .then(data => {
                this.unsavedChanges.set(false);
                this.setState({ data: values, id: data.media.id, processing: false });
                this.sendNotification(data.message, 'success')
                scroll.scrollToTop();
                history.replace(`/media/${this.state.id}/edit`)
            })
            .catch(error => {
                this.setState({ processing: false })
                this.sendNotification(error.toString(), 'error')
                scroll.scrollToTop();
            })
            .then(() => {
                if (this.submittingFromModal.get()) {
                    changesSavedPrompt(this.nextLocation, this.unsavedChanges);
                }
                this.submittingFromModal.set(false);
            });
    };

    revertChanges = () => {
        this.formik.current.resetForm();
        this.sendNotification("Reverted Changes", 'success')
        scroll.scrollToTop();
    }

    createMedia = () => {
        this.setState({
            data: initialValues,
        })
        history.replace('/media/new');
    }

    removeMedia = () => {
        removeObjectPrompt(
            'media',
            MediaAPI.deleteMedia,
            '/media',
            this.state.id,
            (message) => this.sendNotification(message, 'error')
        );
    }

    changeName = (e, values, setFieldValue) => {
      const newName = values.name.replace(values.filename, e.target.value)
      setFieldValue("filename", e.target.value)
      setFieldValue("name", newName)
    }

    renderForm = ({ dirty, values, handleSubmit, handleChange, errors, setFieldValue, setFieldTouched, initialValues }) => {
        this.unsavedChanges.set(dirty);
        return (
            <Form>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-8">
                        {this.state.id && <button type="button" className="hollow button" onClick={this.createMedia}>New Media</button>}
                        <div className="panel clearfix">
                            {this.state.data.name && this.state.data.contentType.includes("image/") &&
                                (<img
                                    alt="Preview"
                                    key={this.state.data.name}
                                    src={`${process.env.REACT_APP_MEDIA_URL}${initialValues.name}`}
                                    className="image-preview"
                                />)
                            }
                            {this.state.data.name && this.state.data.contentType.includes("/pdf") &&
                                (<PDFObject url={`${process.env.REACT_APP_MEDIA_URL}${this.state.data.name}`} height="45rem" />)
                            }
                            {this.state.data.name && this.state.data.contentType.includes("audio/") && (
                                <audio
                                    controls
                                    src={`${process.env.REACT_APP_MEDIA_URL}${this.state.data.name}`}>
                                    Your browser does not support the
                                    <code>audio</code> element.
                                </audio>
                            )}
                            {this.state.data.name && this.state.data.contentType.includes("video/") && (
                                <video width="600" controls>
                                    <source src={`${process.env.REACT_APP_MEDIA_URL}${this.state.data.name}`} />
                               Your browser does not support the video tag.
                                </video>
                            )}
                            <br />
                        </div>
                        <label htmlFor="altText">File name:</label>
                        <Field type="text" name="filename" id="filename" onChange={(e) => this.changeName(e, values, setFieldValue)} placeholder={'Enter text which describes the content of the photo'} />
                        <ErrorMessage name='filename' />
                        <label htmlFor="altText">Alt-text:</label>
                        <Field type="text" name="altText" id="altText" placeholder={'Enter text which describes the content of the photo'} />
                        <label htmlFor="media-url">Media url:</label>
                            <p id="media-url">
                              <a href={`${process.env.REACT_APP_MEDIA_URL}${initialValues.name}`} target="_blank" rel="noopener noreferrer">
                                {`${process.env.REACT_APP_MEDIA_URL}${initialValues.name}`}
                              </a>
                            </p>
                        <label>This media is used by:</label>
                        {Object.keys(this.state.usedBy).map(key => {
                            if (key === "none") {
                              return (
                                <p key="none-key">Nothing</p>
                              )
                            }
                            const obj = this.state.usedBy[key]
                            return obj.map(elem => (
                                <p key={elem.id}>
                                    <a href={`/${contentTypes[key].slug}/${elem.id}/edit`}>{elem.name ? elem.name : elem.title}</a> ({contentTypes[key].name})
                                </p>
                            ))
                        })}
                    </div>
                    <div className="cell small-4  sidebar">
                        {Auth.hasRole(["admin", "editor", "writer", "contributor"]) && (
                            <>
                                {/* SAVE BUTTON */}
                                <button
                                    type="submit"
                                    className="button"
                                >
                                    Save
                        </button>

                                {/* REVERT AND DELETE BUTTONS */}
                                <button type="button" className="hollow button" onClick={this.revertChanges}>Revert</button>
                                {this.state.id && <button type="button" className="hollow button" onClick={this.removeMedia}>Move to trash</button>}
                            </>
                        )}

                        <NotificationMessage message={this.state.message} ref={this.notify} />

                    </div>
                </div>
            </Form>
        )
    };

    render() {
        return (
            <div>
                {(!this.state.mounted || this.state.processing) && <Loading />}

                {this.state.data && (
                    <Formik initialValues={this.state.data}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        innerRef={this.formik}
                    >
                      {this.renderForm}
                    </Formik>
                )}
                {!this.state.data && (
                    <div className="grid-x grid-padding-x">
                        <div className="cell small-8">
                            <ReactDropzone accept={acceptedTypes} className="dropzoneImage" multiple={true} onDrop={(acceptedFiles, rejectedFiles) => {
                                this.onDrop(acceptedFiles, rejectedFiles);
                            }}>
                              {({getRootProps, getInputProps}) => (
                                <div className="dropzoneImage" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <span>Drag & drop an image here, or click to select an image file to upload.</span>
                              </div>
                            )}
                            </ReactDropzone>
                        </div>
                        <div className="cell small-4  sidebar">
                            <NotificationMessage message={this.state.message} ref={this.notify} />
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default MediaForm;
