import _ from 'lodash';

function getRefineOptions (key, optionSuffixes = []) {
    const refineOptions = {};

    for (const i in optionSuffixes) {
        const suf = optionSuffixes[i];
        if (localStorage[key + _.upperFirst(suf)] && localStorage[key + _.upperFirst(suf)] !== 'undefined') {
            let value = localStorage[key + _.upperFirst(suf)];
            if (value.includes(',')) {
                value = _.split(value, ',');
            }
            refineOptions[suf] = value;
        }
    }
    return refineOptions;
}

function createQueryString(key, refineOptionList) {
    let query = []
    const refineOptions = getRefineOptions(key, refineOptionList);
    _.forEach(refineOptions, (val, key) => {
        query.push(key + '=' + val);
    });
    const queryString = query.length > 0 ? '?' + query.join('&') : '';
    return queryString;
}

function verifyId(objectArray = [], id) {
    id  = _.parseInt(id);
    let newId = 'All'
    for (const k in objectArray) {
        if (objectArray[k].id === id) {
            newId = id;
            break;
        }
    } 
    return newId;
}

export {
    createQueryString,
    getRefineOptions,
    verifyId,
}