import axios from 'axios'
import saveError from "../Utilities/saveError";

const API_URL = '/api/media/';
const MediaAPI = {
    upload: async (data) => {
      return new Promise((resolve, reject) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        axios.post(API_URL + 'multiUpload', data, config)
            .then(response => resolve(response.data))
            .catch((error) => {
                saveError(error, reject)
            });
          })
    },

    multiUpload: async (data) => {
      return new Promise((resolve, reject) => {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        axios.post(API_URL + 'multiUpload', data, config)
            .then(response => resolve(response.data))
            .catch((error) => {
                saveError(error, reject)
            });
        })
    },

    saveMedia: async (url, method, values) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + url, {
            method: method,
            data: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    getMedia: async (id) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + id)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    getUsage: async (id) => {
      return new Promise((resolve, reject) => {
        axios(`${API_URL}usedBy/${id}`)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    deleteMedia: async (id) => {
      return new Promise((resolve, reject) => {
        axios.delete(API_URL + id)
            .then(response => resolve(response))
            .catch(error => {
                saveError(error, reject)
            })
          })
    },

    filterMedia: async (params) => {
      return new Promise((resolve, reject) => {
        axios.get('/api/media', { params })
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    }
}

export default MediaAPI;