import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import Auth from '../Auth/Auth'
import FilterSelect from '../Components/FilterSelect';
import EditionAPI from '../Editions/EditionAPI';
import LocationAPI from '../Locations/LocationAPI';
import FestivalInfrastructureAPI from './FestivalInfrastructureAPI';
import Loading from '../Components/Loading';
import { getRefineOptions, verifyId } from '../Utilities/refineOptions';
import { getCurrentEdition } from '../Utilities/currentEdition';
import { showVisibilityState } from '../Utilities/format';
import NotificationMessage from "../Components/NotificationMessage";

const refineOptionList = ['filterBy', 'filterId'];
const filterBy = ['edition', 'locationId', 'status'];
const filterByIndices = { edition: 0, locationId: 1, status: 2 };

class FestivalInfrastructureList extends Component {
    constructor(props) {
        super(props);
        let message = '';
        const refineOptions = getRefineOptions('festivalInfrastructure', refineOptionList);
        if (this.props.location.state && this.props.location.state.deletedObject) {
            message = 'Infrastructure successfully deleted';
        }

        const filterId = ['All', 'All', 'All'];
        this.state = {
            festivalInfrastructure: [],
            message,
            filterBy,
            filterId,
            editions: [{ "id": "none", "name": "" }],
            locations: [{ "id": "none", "name": "" }],
            statuses: [{ "id": "draft", "name": "Draft" }, { "id": "published", "name": "Published" }, { "id": "scheduled", "name": "Scheduled" }],
            refineOptions
        }
        this.notify = React.createRef();
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    async componentDidMount() {
        document.title = "Festival Infrastructure | Fluxible Events CMS";

        await EditionAPI.getEditions()
            .then(async res => {
                await this.setStateAsync({ editions: this.state.editions.concat(res.editions) });
            })
            .catch(err => {
              this.sendNotification(`Could not load edition options (${err.response.status})`, "error")
            });

        await LocationAPI.getLocations()
            .then(async res => {
                await this.setStateAsync({ locations: this.state.locations.concat(res.locations) })
            })
            .catch(err => {
              this.sendNotification(`Could not load location options (${err.response.status})`, "error")
            })

        let filterId = this.state.filterId;
        filterId[filterByIndices.edition] = await getCurrentEdition();
        await this.setStateAsync({ filterId });

        for (const i in refineOptionList) {
            const option = refineOptionList[i];
            const key = 'festivalInfrastructure' + _.upperFirst(option);
            if (!localStorage[key]) {
                localStorage.setItem(key, this.state[option]);
            } else if (this.state[option] !== this.state.refineOptions[option]) {
                await this.setStateAsync({ [option]: this.state.refineOptions[option] });
            }
            if (option === 'filterBy' && localStorage[key] !== filterBy) {
                localStorage.setItem(key, filterBy)
            }
            if (option === 'filterId') {
                let verifiedIds = this.state.filterId.slice();
                const skipIds = ['All', 'none'];
                for (const j in this.state.filterId) {
                    if (filterBy[j] === 'edition' && !skipIds.includes(verifiedIds[j])) {
                        verifiedIds[j] = verifyId(this.state.editions, verifiedIds[j]);
                    }
                }
                if (verifiedIds !== this.state.filterId) {
                    this.setState({ filterId: verifiedIds });
                    localStorage.setItem(key, verifiedIds);
                }
            }
        }

        this.fetchFestivalInfrastructure()
            .then(() => this.setState({ mounted: true }))
            .catch(err => {
              this.setState({mounted: true})
              this.sendNotification(`Could not load festival infrastructure (${err.response.status})`, "error")
            });

    }

    fetchFestivalInfrastructure = async () => {
      return new Promise((resolve, reject) => {
        FestivalInfrastructureAPI.filterFestivalInfrastructure()
            .then(res => {
                this.setState({ festivalInfrastructure: res.festivalInfrastructure });
                resolve(res.festivalInfrastructure)
            })
            .catch(err => { reject(err) });
          })
    }

    setFilter = (value, type) => {
        const index = filterByIndices[type];
        const ids = this.state.filterId;
        let newIds = ids;
        newIds[index] = value;
        localStorage.setItem('festivalInfrastructureFilterId', newIds);
        this.fetchFestivalInfrastructure()
            .then(() => {
                this.setState({ filterId: newIds });
            })
            .catch((err) => {
                console.log(err);
                localStorage.setItem('festivalInfrastructureFilterId', this.state.filterId);
            })
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }

    render() {
        const Header = () => (
            <tr>
                <th>Name</th>
                <th>Edition</th>
                <th>Location</th>
            </tr>
        );
        return (
            <div>
                {!this.state.mounted && <Loading />}

                <div className="grid-x">
                  <div className="cell small-4">
                    {Auth.hasRole(['admin', 'editor']) ?
                      (<Link className="button" to="/festivalInfrastructure/new">Add Infrastructure</Link>) : null
                    }
                  </div>
                  <div className="cell small-8">
                    <NotificationMessage message={this.state.message} small={true} ref={this.notify} />
                  </div>
                </div>

                <div className="grid-x grid-padding-x">
                    <div className="medium-3 cell">
                        <label>Filter by Edition:
                            <FilterSelect options={this.state.editions} setSelectedFilter={this.setFilter} type='edition' value={this.state.filterId[filterByIndices.edition]} />
                        </label>
                    </div>
                    <div className="medium-3 cell">
                        <label>Filter by Location:
                            <FilterSelect options={this.state.locations} setSelectedFilter={this.setFilter} type='locationId' value={this.state.filterId[filterByIndices.locationId]} />
                        </label>
                    </div>
                    <div className="medium-3 cell">
                        <label>Filter by Status:
                            <FilterSelect options={this.state.statuses} setSelectedFilter={this.setFilter} type='status' value={this.state.filterId[filterByIndices.status]} />
                        </label>
                    </div>
                </div>
                <div> {this.state.message}</div>
                <table>
                    <thead>
                        <Header />
                    </thead>
                    <tbody>
                        {this.state.festivalInfrastructure && this.state.festivalInfrastructure.map(locDesc => (
                            <tr key={locDesc.id}>
                                <td><strong><Link to={`/festivalInfrastructure/${locDesc.id}/edit`}>{locDesc.title}</Link></strong> <span>{showVisibilityState(locDesc.publishedOn)}</span></td>
                                <td>{locDesc.editionId ?
                                    this.state.editions.find(x => x.id === locDesc.editionId).name : 'No edition'}</td>
                                <td>{locDesc.location ? locDesc.location.name : 'No location'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    };
}

export default FestivalInfrastructureList;
