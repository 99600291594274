import React, { Component } from "react";

export default class PaginationInput extends Component {

  constructor(props) {
    super(props)
    this.state = {
      inputValue: ""
    }
  }

  handleKeyDown = (e) => {
    if(e.keyCode === 13){
      this.sendNewPage()
    }
  }

  handleBlur = () => {
    this.sendNewPage()
  }

  sendNewPage = () => {
    const newPage = parseInt(this.state.inputValue)
    if(!isNaN(newPage) && this.props.updatePage) {
      this.props.updatePage(newPage)
    }
  }

  handleChange = (e) => {
    this.setState({inputValue: e.target.value});
  }

  inputStyle = () => {
    const str = this.state.inputValue ? this.state.inputValue : this.props.placeholder.toString()
    return {
      width: `${str.length + 0.2}ch`
    }
  }

  render() {
    return (
        <input 
          className="pagination-input" 
          onBlur={this.handleBlur} 
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          type="number"
          aria-hidden="true"
          placeholder={this.props.placeholder}
          style={this.inputStyle()}
          value={this.state.inputValue}
        />
    )
  }
}