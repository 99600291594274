import React from 'react';
import TagAPI from '../Tags/TagAPI'
import { tagSchema } from 'shared';
import { ButtonBlock } from './Icon';
import Auth from '../Auth/Auth'

class TagCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { savedName: props.name, name: props.name, id: props.id, deleteCard: props.delete, error: '', message: '' }
    }

    enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if(code === 13) { //13 is the enter keycode
            this.submit();
        } 
    }

    updateTag = (event) => {
        this.setState({ name: event.target.value, error: '', message: '' })
    }

    submit = () => {
        if (this.state.name === this.state.savedName) return
        tagSchema.validate({name: this.state.name})
        .then(value => {
            TagAPI.saveTag(this.state.id, 'PUT', value)
            .then(data => {
                this.setState({ savedName: data.tag.name, message: 'Tag updated'});
            })
            .catch(error => {
                this.setState({ error: error.toString() });
            })
        })
        .catch(error => {
            this.setState({ error: error.message})
        })
        
    }

    delete = () => {
        this.state.deleteCard();
    }

    Card = () => (
        <div className="column" data-name="tag">
            <div className="card">
                <div className="card-section">
                    <div className="input-group">
                        { Auth.hasRole(['admin', 'editor', 'writer', 'contributor']) ? (
                            <>
                            <input
                            name="tagInput"
                            className="input-group-field"
                            type='text'
                            placeholder=''
                            onChange={this.updateTag}
                            value={this.state.name}
                            onKeyPress={this.enterPressed}
                            onBlur={this.submit}
                            />
                            <ButtonBlock icon="times" action={this.delete} title="Delete Tag" name="tagDeleteButton"/>
                            </>
                        ) : (<p style={{marginBottom: 0}}>{this.state.name}</p>)
                        }
                    </div>
                    {this.state.message && <label>{this.state.message}</label>}
                    {this.state.error && <label data-name="tagError" className="form-error is-visible">{this.state.error}</label>}
                </div>
            </div>
        </div>
    );
    render () {
        return(
            this.Card()
        )
    }
}

export default TagCard;