import React from 'react';
import { Link } from 'react-router-dom'

import Auth from '../Auth/Auth'
import EventTypeAPI from './EventTypeAPI'
import Loading from '../Components/Loading';
import NotificationMessage from "../Components/NotificationMessage";

export default class EventTypeList extends React.Component {
    constructor(props) {
        super(props);
        let message = '';
        if (this.props.location.state && this.props.location.state.deletedObject) {
            message = 'Event Type successfully deleted';
        }
        this.state = { eventTypes: null, message };
        this.notify = React.createRef();
    }
    
    componentDidMount() {
        document.title = `Event Types | ${process.env.REACT_APP_TITLE}`
        EventTypeAPI.getEventTypes()
        .then(res => this.setState({ eventTypes: res.eventTypes, mounted: true }))
        .catch(err => {
          this.setState({mounted: true})
          this.sendNotification(`Could not load event types (${err.response.status})`, "error")
        });
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }

    render = () => {
        const Row = (eventType) => (
            <tr>
                <td><strong><Link to={`/eventTypes/${eventType.id}/edit`}>{eventType.name}</Link></strong></td>
                <td>{eventType.displayName}</td>
                <td>{eventType.visible ? 'True' : 'False'}</td>
            </tr>
        );
    
        const tableRows = this.state.eventTypes && this.state.eventTypes.map((eventType) =>
                <Row {...eventType} key={eventType.id} />);
        return (
            <div>
                {!this.state.mounted && <Loading />}

                <div className="grid-x">
                  <div className="cell small-4">
                    {Auth.hasRole(['admin', 'editor']) ?
                      (<Link to="/eventTypes/new" className="button">Add Event Type</Link>) : null
                    }
                  </div>
                  <div className="cell small-8">
                    <NotificationMessage message={this.state.message} small={true} ref={this.notify} />
                  </div>
                </div>

                <div>{this.state.message}</div>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Display Name</th>
                            <th>Visible</th>
                        </tr>
                    </thead>
                    <tbody>
                        { tableRows }
                    </tbody>
                </table>
            </div>
        )
    };
}
