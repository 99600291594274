import Moment from './moment';

const formatDate = date => {
    if (!Moment(date).isValid()) return ''
  
    return Moment(date).format('L')
}

const formatTime = date => {
    if (!Moment(date).isValid()) return ''
  
    return Moment(date).format('h:mm a')
}

const formatDateTime = date => {
    return formatDate(date) + ' ' + formatTime(date);
}

const currentTimeRounded = () => {
    const min5 = 1000 * 60 * 5;
    return new Date(Math.floor(new Date().getTime() / min5) * min5).toISOString();
}

const statusInfo = values => {
    return values.publishedOn && values.publish === '' && 
        `${Moment(values.publishedOn, 'L HH:mm:ss').isAfter() ? 'Scheduled for publish on ' : 'Published on '} ${values.publishedOn}`
}

const showVisibilityState = e => {
    if( !e ) return 'draft 📝';
        let date = Moment(e).format('X');
        let now = Moment(new Date()).format('X');
        if( date > now ) return `scheduled ⏱`;
        if( date <= now ) return 'published';
}

const slugify = (text) => {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w-]+/g, '')       // Remove all non-word chars
        .replace(/--+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

export {
    formatDate,
    formatTime,
    formatDateTime,
    currentTimeRounded,
    statusInfo,
    showVisibilityState,
    slugify
}
