import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import DateTimePicker from 'react-widgets/DatePicker';
import { animateScroll as scroll } from 'react-scroll';

import Auth from '../Auth/Auth'
import Moment from '../Utilities/moment';
import moment from 'moment-timezone'
import EditionAPI from './EditionAPI'
import EditionTypeAPI from "../EditionTypes/EditionTypesAPI";
import { ErrorMessage } from '../Components/ErrorMessage';
import ImagePicker from '../Components/ImagePicker'
import MediaAPI from '../Media/MediaAPI';
import { SelectField } from '../Components/SelectField';
import Loading from '../Components/Loading';
import { editionSchema } from 'shared'
import history from '../Utilities/history';
import { changesSavedPrompt, removeObjectPrompt, unsavedChangesPrompt, Wrapper } from '../Utilities/formPrompts';
import NotificationMessage from "../Components/NotificationMessage";

const editionFields = {
    name: '',
    editionTypeId: '',
    startDate: null,
    endDate: null,
    imageId: '',
    faviconId: '',
    timezone: ''
};

class EditionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: editionFields,
            id: '',
            message: '',
            logo: null,
            favicon: null,
            editionTypes: [{ id: "", name: "" }],
        };
        this.formik = React.createRef();
        this.unsavedChanges = new Wrapper(false);
        this.submittingFromModal = new Wrapper(false);
        this.nextLocation = history.location;
        this.notify = React.createRef();
    }

    async componentDidMount() {
        if (this.props.id) {
            await EditionAPI.getEdition(this.props.id)
                .then(res => {
                    const edition = { ...res.edition };
                    document.title = `${edition.name} | Edition | ${process.env.REACT_APP_TITLE}`;
                    edition.startDate = edition.startDate !== null ? Moment(edition.startDate).toDate() : null;
                    edition.endDate = edition.endDate !== null ? Moment(edition.endDate).toDate() : null;

                    if (edition.imageId === null) edition.imageId = '';
                    else edition.logo.preview = process.env.REACT_APP_MEDIA_URL + edition.logo.name;

                    if (edition.faviconId === null) edition.faviconId = '';
                    else edition.favicon.preview = process.env.REACT_APP_MEDIA_URL + edition.favicon.name;

                    if (edition.timezone === null || edition.timezone === "") {
                        edition.timezone = 'America/Toronto'
                    }
                    edition.timezone = { value: edition.timezone, label: edition.timezone }

                    this.oldLogo = edition.logo;
                    this.oldFavicon = edition.favicon;

                    !this.isCancelled && this.setState({ data: edition, id: edition.id, logo: edition.logo, favicon: edition.favicon, mounted: true }, this.finishSetup());
                })
                .catch(err => console.log(err));
        } else {
            this.finishSetup()
            document.title = `New Edition | ${process.env.REACT_APP_TITLE}`
            this.setState({ mounted: true });
        }

        this.unblock = history.block((nextLocation) => {
            this.nextLocation = nextLocation;
            if (this.unsavedChanges.get()) {
                unsavedChangesPrompt(nextLocation, this.unsavedChanges, this.formik.current, this.submittingFromModal)
                return false;
            }
            else return true;
        });
    }

    finishSetup() {

        EditionTypeAPI.getEditionTypes()
            .then(res => {
                this.setState({
                    editionTypes: this.state.editionTypes.concat(
                        res.editionTypes
                    )
                })
            })
            .catch(err => console.log(err));
    }

    componentWillUnmount() {
        this.isCancelled = true;
        this.unblock();
    }

    createEdition = () => {
        this.setState({
            data: editionFields,
        })
        history.replace('/editions/new');
    }

    onSubmit = (values) => {
        const url = (this.state.id) ? this.state.id : '';
        const method = (this.state.id) ? 'put' : 'post';

        const edition = { ...values };
        delete edition.logo;
        this.oldLogo = this.state.logo;

        delete edition.favicon;
        this.oldFavicon = this.state.favicon;

        edition.timezone = edition.timezone.label

        if (edition.startDate === '') edition.startDate = null;
        if (edition.endDate === '') edition.endDate = null;
        if (edition.imageId === '') edition.imageId = null;
        if (edition.faviconId === '') edition.faviconId = null;

        if (this.state.logo) {
            MediaAPI.saveMedia(this.state.logo.id, "put", { ...this.state.logo })
        }

        EditionAPI.saveEdition(url, method, edition)
            .then(data => {
                this.unsavedChanges.set(false);
                this.notify.current.sendMessage(data.message, "success")
                this.setState({ data: values, id: data.edition.id });
                scroll.scrollToTop();
                history.replace(`/editions/${this.state.id}/edit`)
            })
            .catch(error => {
                this.notify.current.sendMessage(error.toString(), 'error')
                scroll.scrollToTop();
            })
            .then(() => {
                if (this.submittingFromModal.get()) {
                    changesSavedPrompt(this.nextLocation, this.unsavedChanges);
                }
                this.submittingFromModal.set(false);
            });
    };

    revertChanges = () => {
        this.formik.current.resetForm();
        this.notify.current.sendMessage("Reverted changes", "success")
        this.setState({ logo: this.oldLogo, favicon: this.oldFavicon });
        scroll.scrollToTop();
    }

    removeEdition = () => {
        removeObjectPrompt('edition', EditionAPI.deleteEdition, '/editions', this.state.id, (message) => this.notify.current.sendMessage(message, "error"));
    }


    onDropLogo = (acceptedFiles, rejectedFiles, setFieldValue) => {
        if (acceptedFiles.length === 0) return;

        let formdata = new FormData();
        formdata.append('file', acceptedFiles[0]);

        MediaAPI.upload(formdata).then((res) => {
            let image = res.images[0]
            setFieldValue('imageId', image.id);
            image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
            this.setState({ logo: image });
        })
        .catch(err => this.sendNotification("Could not upload image", "error"));
    };

    onDropFavicon = (acceptedFiles, rejectedFiles, setFieldValue) => {
        if (acceptedFiles.length === 0) return;

        let formdata = new FormData();
        formdata.append('file', acceptedFiles[0]);

        MediaAPI.upload(formdata).then((res) => {
          let image = res.images[0]
          setFieldValue('faviconId', image.id);
          image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
          this.setState({ favicon: image })
        })
        .catch(err => this.sendNotification("Could not upload image", "error"));;
    };

    removeLogo = (setFieldValue) => {
        setFieldValue('imageId', "");

        this.setState({
            logo: null,
        });
    };

    removeFavicon = (setFieldValue) => {
        setFieldValue('faviconId', "");

        this.setState({
            favicon: null,
        });
    };

    setLogoFromLibrary = (setFieldValue, newImage) => {
        const image = { ...newImage }
        setFieldValue('imageId', image.id);
        image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
        this.setState({
            logo: image,
        });
    }

    setFaviconFromLibrary = (setFieldValue, newImage) => {
        const image = { ...newImage }
        setFieldValue('faviconId', image.id);
        image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
        this.setState({
            favicon: image,
        });
    }

    onChangeAltText = (e) => {
        e.preventDefault()
        let logo = this.state.logo
        logo.altText = e.target.value
        this.setState({ logo: logo })
    }

    changeTimezone = (e, setFieldValue) => {
        setFieldValue('timezone', e)
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }

    renderForm = ({ dirty, values, handleSubmit, handleChange, errors, setFieldValue, setFieldTouched, isSubmitting }) => {
        this.unsavedChanges.set(dirty && !isSubmitting);
        const timezoneOptions = moment.tz.names().map(timezone => (
            { 'value': timezone, 'label': timezone }
        ))
        return (
            <Form>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-8">
                        {this.state.id && <button type="button" className="hollow button" onClick={this.createEdition}>New Edition</button>}
                        <label htmlFor="name">Name</label>
                        <Field type="text" name="name" />
                        <ErrorMessage name='name' />

                        <label>Edition Type</label>
                        <Field
                            component="select"
                            name="editionTypeId"
                        >
                            {this.state.editionTypes &&
                                this.state.editionTypes.map((type, idx) => (
                                    <option value={type.id} key={idx}>
                                        {type.name}
                                    </option>
                                ))}
                        </Field>
                        <ErrorMessage name='editionTypeId' />

                        <label>Timezone</label>
                        <Field name='timezone' options={timezoneOptions} component={SelectField} isMulti={false} placeholder='Choose a timezone by typing or using the dropdown...'
                            onChange={this.changeTimezone} />

                        <label htmlFor="startDate">Start Date</label>
                        <DateTimePicker
                            name="startDate"
                            value={values.startDate}
                            valueEditFormat={{dateStyle: 'short'}}
                            valueDisplayFormat={{dateStyle: 'medium'}}
                            inputProps={{ id: "startDate", autoComplete: "off" }}
                            onChange={(value) => {
                                setFieldTouched('startDate');
                                setFieldValue('startDate', value)
                            }}
                        />
                        <ErrorMessage name="startDate" />

                        <label htmlFor="endDate">End date</label>
                        <DateTimePicker
                            name="endDate"
                            value={values.endDate}
                            valueEditFormat={{dateStyle: 'short'}}
                            valueDisplayFormat={{dateStyle: 'medium'}}
                            inputProps={{ id: "endDate", autoComplete: "off" }}
                            onChange={(v) => {
                                setFieldTouched('endDate');
                                setFieldValue('endDate', v);
                            }}
                        />
                        <ErrorMessage name="endDate" />

                        <Field type="hidden" name="imageId" />
                        <Field type="hidden" name="faviconId" />
                        <br />
                        <div className="row" style={{ margin: '0px' }}>
                            <div className="small-5 columns">
                                <p>Logo</p>
                                {this.state.logo ? (
                                    <>
                                        <div className="row">
                                            <img
                                                alt="Preview"
                                                key={this.state.logo.preview}
                                                src={this.state.logo.preview}
                                                className="image-preview"
                                            />
                                        </div>
                                        <div className="row">
                                            <label>Alt-text:</label>
                                        </div>
                                        <div className="row">
                                            <input type="text" value={this.state.logo.altText} onChange={this.onChangeAltText} />
                                        </div>
                                        <div className="row">
                                            {Auth.hasRole(['admin', 'editor']) && <button type="button" className="button alert" onClick={() => { this.removeLogo(setFieldValue) }}>Remove logo</button>}
                                        </div>
                                    </>
                                ) : (
                                        <>
                                            {Auth.hasRole(['admin', 'editor']) &&
                                                <ImagePicker onDrop={this.onDropLogo} setImage={this.setLogoFromLibrary} setFieldValue={setFieldValue} />
                                            }</>
                                    )}
                            </div>
                            <div className="small-5 columns end">
                                <p>Favicon</p>
                                {this.state.favicon ? (
                                    <>
                                        <img
                                            alt="Preview"
                                            key={this.state.favicon.preview}
                                            src={this.state.favicon.preview}
                                            className="image-preview"
                                        /><br />
                                        {Auth.hasRole(['admin', 'editor']) && <button type="button" className="button alert" onClick={() => { this.removeFavicon(setFieldValue) }}>Remove favicon</button>}
                                    </>
                                ) : (
                                        <>
                                            {Auth.hasRole(['admin', 'editor']) &&
                                                <ImagePicker onDrop={this.onDropFavicon} setImage={this.setFaviconFromLibrary} setFieldValue={setFieldValue} />
                                            }</>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="cell small-4  sidebar">
                        {Auth.hasRole(['admin', 'editor']) ?
                            (<>
                                <button className="button" type="submit">Save</button>
                                <button type="button" className="hollow button" onClick={this.revertChanges}>Revert</button>
                                {this.state.id && <button type="button" className="button button-delete" onClick={this.removeEdition}>Delete</button>}
                            </>)
                            : null
                        }
                        <NotificationMessage message={this.state.message} ref={this.notify} />
                    </div>
                </div>
            </Form>
        )
    };

    render() {
        return (
            <div>
                {!this.state.mounted && <Loading />}
                <div id="message">{this.state.message}</div>
                {this.state.data ? (
                    <Formik initialValues={this.state.data}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={editionSchema}
                        innerRef={this.formik}
                    >
                      {this.renderForm}
                    </Formik>
                ) : null}
            </div>
        )
    };
}

export default EditionForm;
