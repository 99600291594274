import React, { Component } from 'react';
import Auth from '../Auth/Auth'
import { setCurrentEdition, getCurrentEdition } from '../Utilities/currentEdition';
import EditionAPI from '../Editions/EditionAPI'
import Loading from '../Components/Loading';
import NotificationMessage from "../Components/NotificationMessage";
import EditionTypesAPI from '../EditionTypes/EditionTypesAPI';

class CurrentEditionPage extends Component {
    constructor(props) {
        super(props);
        this.notify = React.createRef();
        this.state = { error: '', editions: [{ id: "", name: "None" }], editionTypes: [], currentEditions: [] };
    }

    componentDidMount() {
        document.title = `Current Edition | ${process.env.REACT_APP_TITLE}`;
        this.setState({ mounted: true })
        this.fetchEditions();
        this.fetchEditionTypes();
    }

    fetchEditions = async () => {
        const response = await EditionAPI.getEditions();
        const sortedEditions = await response.editions.sort((a, b) => {
            return new Date(b.startDate) - new Date(a.startDate)
        })
        this.setState({ editions: this.state.editions.concat(sortedEditions) })
        //this.fetchCurrentEdition()
    }

    fetchEditionTypes = async () => {
        const response = await EditionTypesAPI.getEditionTypes();
        const currentEditions = response.editionTypes.map(type => type.currentEdition ? type.currentEdition : "")
        this.setState({ editionTypes: response.editionTypes, currentEditions })
    }

    fetchCurrentEdition = async () => {
        let currentEdition = await getCurrentEdition()
        this.setState({ currentEdition: parseInt(currentEdition), mounted: true });
    }

    onChange = (e, typeIdx) => {
        let { currentEditions } = this.state
        currentEditions[typeIdx] = e.target.value
        this.setState({ currentEditions })
    }

    submit = async (id, currentEditionId) => {
        if (isNaN(parseInt(currentEditionId))) {
          return this.notify.current.sendMessage(`Select a current edition before setting`, 'warning')
        }
        try{
          await setCurrentEdition(id, currentEditionId)
          this.notify.current.sendMessage(`Set ${this.state.editionTypes.find(x => x.id === parseInt(id)).name}'s edition to ${this.state.editions.find(x => x.id === parseInt(currentEditionId)).name}`, 'success')
        } catch(err){
          this.notify.current.sendMessage(err, "error")
        }
    }

    selectCard = () => {
        return Auth.hasRole(['admin', 'editor', 'writer', 'contributor']) ? (
            <div className="grid-x grid-padding-x">
                {this.state.editionTypes.map((type, typeIdx) => {
                    return (
                        <div className="cell small-8" key={type.id}>
                            <label htmlFor={`editionType-${typeIdx}`}>{type.name} Website: Current Edition</label>
                            <div className="row collapse">
                                <select id={`editionType-${typeIdx}`} name="editionId" className="small-8 columns" onChange={(e) => this.onChange(e, typeIdx)} value={this.state.currentEditions[typeIdx]}>
                                    {/* Below we're overriding the filtering for the Fluxible type (fluxible.ca) since it can accept any edition type*/}
                                    {this.state.editions.filter(ed => ed.editionTypeId === type.id || type.id === 1).map((edition, idx) => (
                                        <option value={edition.id} key={idx}>
                                            {edition.name}
                                        </option>
                                    ))}
                                </select>
                                <div className="small-2 columns">
                                    <button className="button postfix" onClick={() => this.submit(type.id, this.state.currentEditions[typeIdx])}>Set</button>
                                </div>
                            </div>
                            {this.state.error && <label className="form-error is-visible">{this.state.error}</label>}
                        </div>
                    )
                })}
                <div className="cell small-4  sidebar">
                    <NotificationMessage message={this.state.message} ref={this.notify} />
                </div>
            </div>
        ) : null
    }

    render = () => {
        return (
            <div>
                {!this.state.mounted && <Loading />}
                {this.state.mounted && this.selectCard()}
                {this.state.message && <><div>{this.state.message}</div><br /></>}
            </div>
        )
    };
}

export default CurrentEditionPage;
