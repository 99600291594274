import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import Auth from '../Auth/Auth'
import FilterSelect from '../Components/FilterSelect';
import EditionAPI from '../Editions/EditionAPI';
import LocationAPI from './LocationAPI';
import Loading from '../Components/Loading';
import { getRefineOptions, verifyId } from '../Utilities/refineOptions';
import {getCurrentEdition} from '../Utilities/currentEdition';
import { showVisibilityState } from '../Utilities/format';
import NotificationMessage from "../Components/NotificationMessage";

const refineOptionList = ['filterBy', 'filterId'];
const filterBy = ['edition', 'status'];
const filterByIndices = {edition: 0, status: 1};

class LocationList extends Component {
    constructor(props) {
        super(props);
        let message = '';
        const refineOptions = getRefineOptions('location', refineOptionList);
        if (this.props.location.state && this.props.location.state.deletedObject) {
            message = 'Location successfully deleted';
        }
        const filterId = ['All', 'All'];
        this.state = { 
            locations: [], 
            message,
            filterBy,
            filterId, 
            editions: [{"id": "none", "name": ""}],
            statuses: [{"id": "draft", "name": "Draft"}, {"id": "published", "name": "Published"}, {"id": "scheduled", "name": "Scheduled"}],
            refineOptions
        }
        this.notify = React.createRef();
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
          this.setState(state, resolve)
        });
    }

    async componentDidMount() {
        document.title = `Locations | ${process.env.REACT_APP_TITLE}`;
        
        await EditionAPI.getEditions()
        .then(async res => {
            await this.setStateAsync({ editions: this.state.editions.concat(res.editions)});
        })
        .catch(err => this.sendNotification(`Could not load edition options (${err.response.status})`, "error"));

        let filterId = this.state.filterId;
        filterId[filterByIndices.edition] = await getCurrentEdition();
        await this.setStateAsync({filterId});

        for (const i in refineOptionList) {
            const option = refineOptionList[i];
            const key = 'location' + _.upperFirst(option);
            if (!localStorage[key]) {
                localStorage.setItem(key, this.state[option]);
            } else if (this.state[option] !== this.state.refineOptions[option]) {
                await this.setStateAsync({[option]: this.state.refineOptions[option]});
            }
            if (option === 'filterId') {
                let verifiedIds = this.state.filterId.slice();
                const skipIds = ['All', 'none'];
                for (const j in this.state.filterId) {
                    if (filterBy[j] === 'edition' && !skipIds.includes(verifiedIds[j])) {
                        verifiedIds[j] = verifyId(this.state.editions, verifiedIds[j]);
                    }
                }
                if (verifiedIds !== this.state.filterId) {
                    this.setState({filterId: verifiedIds});
                    localStorage.setItem(key, verifiedIds);
                }
            }
        }

        this.fetchLocations()
        .then(() => this.setState({mounted: true}))
        .catch(err => {
          this.setState({mounted: true})
          this.sendNotification(`Could not load locations (${err.response.status})`, "error")
        });

    }

    fetchLocations = async () => {
      return new Promise((resolve, reject) => {
        LocationAPI.filterLocations()
        .then(res => {
            this.setState({ locations: res.locations });
            resolve(res.locations)
        })
        .catch(err => {reject(err)});
      })
    }

    setFilter = (value, type) => {
        const index = filterByIndices[type];
        const ids = this.state.filterId;
        let newIds = ids;
        newIds[index] = value;
        localStorage.setItem('locationFilterId', newIds);
        this.fetchLocations()
        .then(() => {
            this.setState({filterId: newIds});
        })
        .catch((err) => {
            console.log(err);
            localStorage.setItem('locationFilterId', this.state.filterId);
        })
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }
    
    render() {
        const Header = () => (
            <tr>
                <th>Name</th>
                <th>Editions</th>
            </tr>
        );

        return (
            <div>
                {!this.state.mounted && <Loading />}

                <div className="grid-x">
                  <div className="cell small-4">
                    {Auth.hasRole(['admin', 'editor']) ?
                      (<Link className="button" to="/locations/new">Add Location</Link>) : null
                    }
                  </div>
                  <div className="cell small-8">
                    <NotificationMessage message={this.state.message} small={true} ref={this.notify} />
                  </div>
                </div>

                <div className="grid-x grid-padding-x">
                    <div className="medium-3 cell">
                        <label>Filter by Edition: 
                            <FilterSelect options={this.state.editions} setSelectedFilter={this.setFilter} type='edition' value={this.state.filterId[filterByIndices.edition]} />
                        </label>
                    </div>
                    <div className="medium-3 cell">
                        <label>Filter by Status: 
                            <FilterSelect options={this.state.statuses} setSelectedFilter={this.setFilter} type='status' value={this.state.filterId[filterByIndices.status]} />
                        </label>
                    </div>
                </div>
                <div id="message"> {this.state.message}</div>
                <table>
                    <thead>
                        <Header />
                    </thead>
                    <tbody>
                        {this.state.locations && this.state.locations.map(loc => (
                            <tr key={loc.id}>
                                <td><strong><Link to={`/locations/${loc.id}/edit`}>{loc.name}</Link></strong> <span>{showVisibilityState(loc.publishedOn)}</span></td>
                                <td>{_.map(loc.editions, 'name').join(', ')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
    )};
}

export default LocationList;
