import React from 'react';
import { IconBlock } from '../Components/Icon'

const attrTitle = {
    'name': 'Name',
    'filename': 'Name',
    'eventType': 'Event Type',
    'startDate': 'Start Date/Time',
    'speakers': 'Speaker',
    'jobPosition': 'Role/Affiliation',
    'publishedOn': 'Publish Date',
    'uploadedOn': 'Upload Date',
    'title': 'Title',
    'author': 'Author',
}

const SortCard = ({ attribute, sortType, onSortAttribute }) => (
    <div className="cell shrink">
        <div className="card">
            <div className="card-section">
                <div>{attrTitle[attribute]}</div>
                <div className={`sort-button${attribute === sortType.attribute && sortType.direction === 'DESC' ? '' : ' hollow'} button`} onClick={() => onSortAttribute(attribute, 'DESC')}>
                    <IconBlock icon="chevron-down" size="xs" />
                </div>
                <div className={`sort-button${attribute === sortType.attribute && sortType.direction === 'ASC' ? '' : ' hollow'} button`} onClick={() => onSortAttribute(attribute, 'ASC')}>
                    <IconBlock icon="chevron-up" size="xs" />
                </div>
            </div>
        </div>
    </div>
);

export default SortCard;