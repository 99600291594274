import React from 'react';
import { UserForm, UserList } from "./index"

export const ListUsers = (props) => (
    <div>
        <h2>Users</h2>
        <UserList {...props} />
    </div>
);

export const AddUser = () => (
    <div>
        <h2>Add a user</h2>
        <UserForm />
    </div>
);

export const EditUser = ({ match }) => (
    <div>
        <h2>Edit a user</h2>
        <UserForm id={match.params.id} />
    </div>
);
