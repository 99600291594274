import React, { Component } from 'react';
import $ from 'jquery';

import { selectFirstOnEnter } from '../Utilities/map'

window.jQuery = window.$ = $;
let init = false;

class Map extends Component {
    componentDidUpdate() {
        if (!init && $('#gmaps-script').length === 0) {
            this.loaded = true;
            window.initMap = this.initMap;
            $('body').append(`<script id="gmaps-script" type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap" async defer />`)
        } else if ($('#pac-input').length > 0 && init && !this.loaded) {
            this.loaded = true;
            this.initMap();
        }
    }

    /* eslint-disable */

    initMap = () => {
        const noSearch = !this.props.latitude || parseFloat(this.props.latitude) === 0.00;
        
        const latLng = noSearch ? 
            { lat: 43.4509381, lng: -80.4982637 } 
            : { lat: parseFloat(this.props.latitude), lng: parseFloat(this.props.longitude) };

        const map = new google.maps.Map(document.getElementById('map'), {
            center: latLng,
            zoom: noSearch ? 14 : 17
        });

        const input = document.getElementById('pac-input');
        const autocomplete = new google.maps.places.Autocomplete(input);

        input.onkeypress = (e) => {
            const key = e.charCode || e.keyCode || 0;     
            if (key == 13) {
                e.preventDefault();
            }
        }
    
        // Bind the map's bounds (viewport) property to the autocomplete object,
        // so that the autocomplete requests use the current map bounds for the
        // bounds option in the request.
        autocomplete.bindTo('bounds', map);
    
        // Set the data fields to return when the user selects a place.
        autocomplete.setFields(['address_components', 'geometry', 'icon', 'name', 'formatted_address']);
    
        const infowindow = new google.maps.InfoWindow();
        const infowindowContent = document.getElementById('infowindow-content');
        infowindow.setContent(infowindowContent);

        const marker = new google.maps.Marker({
            map: map,
            anchorPoint: new google.maps.Point(0, -29),
        });

        if (!noSearch) {
            marker.setPosition(latLng);
            marker.setVisible(true);
        }

        selectFirstOnEnter(input);
    
        autocomplete.addListener('place_changed', () => {
            infowindow.close();
            marker.setVisible(false);
            const place = autocomplete.getPlace();

            if (!place.geometry) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                // window.alert("No details available for input: '" + place.name + "'");
                return;
            }
        
            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17);  // Why 17? Because it looks good.
            }

            marker.setPosition(place.geometry.location);
            marker.setVisible(true);
        
            let address = '';

            if (place.address_components) {
                address = [
                    (place.address_components[0] && place.address_components[0].short_name || ''),
                    (place.address_components[1] && place.address_components[1].short_name || ''),
                    (place.address_components[2] && place.address_components[2].short_name || '')
                ].join(' ');
            }

            const latitude = place.geometry.location.lat();
            const longitude = place.geometry.location.lng();
            this.props.onGetLocationData({ latitude, longitude, search: input.value, address: place.formatted_address, data: this.props.values })
        
            infowindowContent.children['place-icon'].src = place.icon;
            infowindowContent.children['place-name'].textContent = place.name;
            infowindowContent.children['place-address'].textContent = address;
            infowindow.open(map, marker);
        });

        init = true;
    }

    onChange = (e) => {
        if(this.props.setFieldValue){
            this.props.setFieldValue("search", e.target.value)
        }
    }

    render() {
        return (
            <div>
                <label htmlFor="pac-input">Location search</label>
                <div id="pac-container">
                    {(this.props.search || this.props.search === '') && <input id="pac-input" type="text" onChange={this.onChange} placeholder="Enter a location" defaultValue={this.props.search} />}
                </div>

                <div id="map" />

                <div id="infowindow-content">
                    <img alt="icon" src="" width="16" height="16" id="place-icon" />
                    <span id="place-name" className="title"></span><br />
                    <span id="place-address"></span>
                </div>
            </div>
        );
    }
}

export default Map;
