import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import { animateScroll as scroll } from 'react-scroll';
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { mdToDraftjs, draftjsToMd } from "draftjs-md-converter";

import EditionAPI from '../Editions/EditionAPI';
import LocationAPI from '../Locations/LocationAPI';
import FestivalInfrastructureAPI from './FestivalInfrastructureAPI';
import Loading from '../Components/Loading';
import FluxRTE from "../Components/FluxRTE";
import { ErrorMessage } from '../Components/ErrorMessage';
import PublishModule from "../Components/PublishModule"
import { SelectField } from '../Components/SelectField';
import { festivalInfrastructureSchema } from 'shared';
import history from '../Utilities/history';
import MediaAPI from '../Media/MediaAPI';
import ImagePicker from '../Components/ImagePicker';
import { changesSavedPrompt, removeObjectPrompt, unsavedChangesPrompt, Wrapper } from '../Utilities/formPrompts';
import { currentTimeRounded, formatDateTime } from '../Utilities/format';
import Moment from '../Utilities/moment';
import NotificationMessage from "../Components/NotificationMessage";

const festivalInfrastructureFields = {
    title: '',
    subtitle: '',
    description: '',
    location: '',
    locationId: null,
    edition: '',
    editionId: null,
    priority: 3,
    featuredImageIds: '',
    editorState: EditorState.createEmpty(),
    publish: 'draft',
    publishedOn: '',
    publishDate: '',
};

class FestivalInfrastructureForm extends Component {
    _mounted = false
    constructor(props) {
        super(props);
        this.state = this.defaultState = {
            data: festivalInfrastructureFields,
            id: '',
            message: '',
            editions: [{ 'id': "", 'name': "No edition" }],
            locationList: [{ 'id': "", 'name': "No location" }],
            viewPublishState: false,
            viewPublishDate: false,
            featuredImages: [],
            priorityTypes: [{ name: '1', id: 1 },
            { name: '2', id: 2 },
            { name: '3', id: 3 },
            { name: '4', id: 4 },
            { name: '5', id: 5 },],
            anchorTypes: [{ name: 'None', val: 'none' },
            { name: 'Venue', val: 'venue' },
            { name: 'Hotel', val: 'hotel' },
            { name: 'Travel Tips', val: 'tips' },],
            next: null,
            nextReady: false
        };
        this.formik = React.createRef();
        this.unsavedChanges = new Wrapper(false);
        this.submittingFromModal = new Wrapper(false);
        this.nextLocation = history.location;
        this.onSubmit = this.onSubmit.bind(this);
        this.formik = React.createRef();
        this.notify = React.createRef();
        this.haveSentErrors = false;
    }

    safeSetState(object, func) {
        if (this._mounted) {
            this.setState(object, func)
        }
    }

    componentDidMount() {
        this._mounted = true
        if (this.props.id) {
            FestivalInfrastructureAPI.getFestivalInfrastructure(this.props.id)
                .then(async (res) => {
                    if (!this.isCancelled) {
                        let infrastructure = { ...res.infrastructure };
                        this.getBuildInformation(infrastructure.id, infrastructure.publishedOn);
                        if (!infrastructure.editionId) infrastructure.editionId = this.state.editions[0].id
                        if (!infrastructure.location){
                          const loc = this.state.locationList[0]
                          infrastructure.locationId = loc.id
                          infrastructure.location = {value: loc.id, label: loc.name}
                        } else {
                          const loc = infrastructure.location
                          infrastructure.locationId = loc.id
                          infrastructure.location = {value: loc.id, label: loc.name}
                        }
                        document.title = `${infrastructure.title} | Description | Fluxible Events CMS`;

                        if (infrastructure.publishedOn !== null) {
                            infrastructure.publishDate = Moment(infrastructure.publishedOn).toDate();
                            infrastructure.publishedOn = formatDateTime(infrastructure.publishedOn);
                            infrastructure.publish = 'publish'
                        } else {
                            const now = currentTimeRounded();
                            infrastructure.publishDate = Moment(now).toDate();
                            infrastructure.publishedOn = '';
                            infrastructure.publish = 'draft';
                        }

                        infrastructure.editorState = infrastructure.description
                            ? EditorState.createWithContent(
                                convertFromRaw(mdToDraftjs(infrastructure.description))
                            )
                            : EditorState.createEmpty();

                        if (infrastructure.featuredImages === null || !infrastructure.featuredImages) {
                            infrastructure.featuredImages = [];
                        } else {
                            infrastructure.featuredImages.forEach(image => {
                                image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
                            });
                            let oldFeaturedImages = infrastructure.featuredImages.slice()
                            this.safeSetState({ featuredImages: infrastructure.featuredImages, oldFeaturedImages: oldFeaturedImages })
                        }
                        this.safeSetState({ data: infrastructure, id: infrastructure.id, mounted: true }, () => {
                            // send message when brand new blog post after history push happens.
                            if (this.state.mounted && history.location.state && history.location.state.notify.message !== null) {
                                this.sendNotification(history.location.state.notify.message, history.location.state.notify.type);
                                history.replace({ state: null });
                            }
                        })
                        this.initializeEdition()
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.sendNotification(err.toString(), 'error')
                });
        } else {
            document.title = "New Festival Infrastructure | Fluxible Events CMS";
            const festivalInfrastructure = { ...this.state.data };
            const now = currentTimeRounded();

            festivalInfrastructure.publishDate = Moment(now).toDate();
            this.safeSetState({ mounted: true, data: festivalInfrastructure });
            this.initializeEdition()
        }

        this.unblock = history.block((nextLocation) => {
            this.nextLocation = nextLocation;
            if (this.unsavedChanges.get()) {
                unsavedChangesPrompt(nextLocation, this.unsavedChanges, this.formik.current, this.submittingFromModal)
                return false;
            }
            else return true;
        });
    }

    initializeEdition() {
        EditionAPI.getEditions()
            .then(res => {
                let editions = this.state.editions.concat(res.editions)
                let data = this.state.data
                if(this.state.data.editionId) {
                  const edition = editions.find(ed => ed.id === data.editionId)
                  data.edition = {value: edition.id, label: edition.name}
                }
                this.safeSetState({ editions, data}, this.initializeLocation);
            })
            .catch((err) => {
                console.log(err)
                this.sendNotification(err.toString(), 'error')
            });
    }

    initializeLocation() {
        LocationAPI.getLocations()
            .then(res => {
                let locationList = this.state.locationList.concat(res.locations)
                this.safeSetState({ locationList})
            })
            .catch((err) => {
                console.log(err)
                this.sendNotification(err.toString(), 'error')
            })
    }

    componentWillUnmount() {
        this._mounted = false
        this.isCancelled = true;
        this.unblock();
    }

    cleanFestivalInfrastructure(dirtyInfrastructure) {
        let url = (this.state.id) ? this.state.id : '';
        let method = (this.state.id) ? 'put' : 'post';
        const infrastructure = { ...dirtyInfrastructure };

        infrastructure.description = draftjsToMd(
            convertToRaw(dirtyInfrastructure.editorState.getCurrentContent())
        );
        delete infrastructure.editorState

        delete infrastructure.edition

        infrastructure.location = infrastructure.locationId
        delete infrastructure.locationId

        if (infrastructure.location === "" || infrastructure.location === -1) {
            infrastructure.location = null;
        }

        if (infrastructure.editionId === "" || infrastructure.editionId === -1) {
            infrastructure.editionId = null
        }

        if (this.state.featuredImages) {
            infrastructure.featuredImageIds = this.state.featuredImages.map((image) => image.id)
            delete infrastructure.featuredImages
        }

        if (infrastructure.publish === 'publish' || (!infrastructure.publish && infrastructure.publishedOn)) {
            infrastructure.publishedOn = Moment(infrastructure.publishDate, 'L HH:mm:ss').toISOString();
        } else {
            infrastructure.publishedOn = null;
        }
        delete infrastructure.publish;

        this.state.featuredImages.forEach(image => {
            MediaAPI.saveMedia(image.id, "put", { ...image })
        })

        return { url, method, infrastructure };
    }

    removePhoto = (idx) => {
        let featuredImages = this.state.featuredImages
        featuredImages.splice(idx, 1)
        this.safeSetState({ featuredImages: featuredImages });
    };

    setPhotoFromLibrary = (setFieldValue, newImage) => {
        const image = { ...newImage }
        let featuredImages = this.state.featuredImages
        image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
        featuredImages.push(image)
        this.safeSetState({ featuredImages: featuredImages });
    }

    onSubmit(values) {
        const { url, method, infrastructure } = this.cleanFestivalInfrastructure(values);
        FestivalInfrastructureAPI.saveFestivalInfrastructure(url, method, infrastructure)
            .then(data => {
                this.sendNotification(data.message, 'success')
                this.unsavedChanges.set(false);
                this.safeSetState({ data: values, id: data.infrastructure.id, message: data.message });
                setTimeout(() => { this.safeSetState({ message: '' }) }, 10000)
                scroll.scrollToTop();
                this.getBuildInformation(data.infrastructure.id, data.infrastructure.publishedOn);
                history.replace({
                    pathname: `/festivalInfrastructure/${this.state.id}/edit`,
                    state: { notify: { message: data.message, type: 'success' } }
                });
            })
            .catch(error => {
                this.sendNotification(error.toString(), 'error')
                scroll.scrollToTop();
            })
            .then(() => {
                if (this.submittingFromModal.get()) {
                    changesSavedPrompt(this.nextLocation, this.unsavedChanges);
                }
                this.submittingFromModal.set(false);
            });
    }

    revertChanges = () => {
        this.formik.current.resetForm();
        this.sendNotification("Reverted changes", 'warning')
        let featuredImages = this.state.oldFeaturedImages.slice()
        this.safeSetState({featuredImages: featuredImages});
        scroll.scrollToTop();
    }

    updateViewPublishState = () => {
        this.safeSetState((state, props) => ({
            viewPublishState: !state.viewPublishState
        }));
    };

    updateViewPublishDate = () => {
        this.safeSetState((state, props) => ({
            viewPublishDate: !state.viewPublishDate
        }));
    };

    publishChanges = () => {
        const values = this.formik.current.values;
        const { url, method, infrastructure } = this.cleanFestivalInfrastructure(values);
        FestivalInfrastructureAPI.saveFestivalInfrastructure(url, method, infrastructure)
            .then(data => {
                this.unsavedChanges.set(false);
                this.sendNotification(data.message, 'success');

                FestivalInfrastructureAPI.publishFestivalInfrastructure(data.infrastructure.id)
                    .then(publishData => {
                        this.setState({
                            data: values,
                            id: data.infrastructure.id,
                        });
                        this.sendNotification(publishData.message, 'success');
                        scroll.scrollToTop();
                        this.getBuildInformation(values.id, values.publishedOn);
                    })
                    .catch(error => {
                        this.setState({
                            data: values,
                            id: data.infrastructure.id
                        });
                        scroll.scrollToTop();
                        this.sendNotification(`Publish ${error.toString()}`, 'error');
                    });
            })
            .catch(error => {
                this.sendNotification(error.toString(), 'error')
                scroll.scrollToTop();
            });
    }

    onDrop = (files, rejected, setFieldValue) => {
        const data = new FormData();
        files.forEach(file => {
          data.append("file", file);
        });
        MediaAPI.upload(data)
            .then((res) => {
                let featuredImages = this.state.featuredImages
                res.images.forEach(image => {
                  image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
                  featuredImages.push(image)
                })
                this.safeSetState({
                    featuredImages: featuredImages,
                });
            });
    }

    removeFestivalInfrastructure = () => {
        removeObjectPrompt(
            'festivalInfrastructure', 
            FestivalInfrastructureAPI.deleteFestivalInfrastructure, 
            '/festivalInfrastructure', 
            this.state.id, 
            (message) => this.sendNotification(message, 'error')
        );
    }

    createFestivalInfrastructure = () => {
        history.replace('/festivalInfrastructure/new');
    }

    onEditionChange = (editionId) => {
        this.edition = this.state.editions.find(
            ed => ed.id === parseInt(editionId)
        );

        if (
            this.edition &&
            this.edition.name !== "" &&
            this.edition.startDate &&
            this.edition.endDate
        ) {


            LocationAPI.filterLocations(
                "?filterBy=edition&filterId=" + this.edition.id
            )
                .then(res => {
                    this.safeSetState({
                        locationList: this.defaultState.locationList.concat(res.locations)
                    });
                })
                .catch(err => {
                    console.log(err)
                    this.sendNotification(err.toString(), 'error')
                });
        } else {
            this.editionStartDate = null;
            this.editionEndDate = null;
        }
    };

    reorderImages = (id, idx, dir) => {
        let featuredImages = this.state.featuredImages
        let toMove = featuredImages[idx]
        featuredImages.splice(idx, 1)
        featuredImages.splice(idx + dir, 0, toMove)
        this.safeSetState({ featuredImages: featuredImages })
    }

    changeEdition = (e, setFieldValue) => {
        setFieldValue('editionId', e.value)
        setFieldValue('edition', e)
        this.onEditionChange(e.value)
    }

    changeLocation(e, setFieldValue) {
        setFieldValue('locationId', e.value)
        setFieldValue('location', e)
    }

    onChangeAltText = (e, idx) => {
        e.preventDefault()
        let featuredImages = this.state.featuredImages
        featuredImages[idx].altText = e.target.value
        this.safeSetState({ featuredImages: featuredImages })
    }

    getBuildInformation = (id, date) => {
        const momentDate = Moment(date)
        if (momentDate.isValid()) {
            FestivalInfrastructureAPI.getFestivalInfrastructureBuilds(id, momentDate.format())
                .then(res => {
                    this.setState({ next: res.next, nextReady: true });
                })
                .catch(err => {
                    this.sendNotification(err.toString(), 'error')
                })
        }
    };

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }

    // This custom setValue lets us prevent publishing without required values
    customSetValue = (key, value, setValue, formValues) => {
      if(key === "publish" && value === "publish") {
        if(!formValues.editorState.getCurrentContent().hasText()){
          this.sendNotification("Infrastructure cannot be published without a description", "warning")
        } else if(!formValues.editionId){
          this.sendNotification("Infrastructure cannot be published without an edition", "warning")
        } else {
          setValue(key, value)
        }
      } else {
        setValue(key, value)
      }
    }

    renderForm = ({ dirty, values, handleSubmit, handleChange, handleBlur, errors, setFieldValue, setFieldTouched, isSubmitting }) => {
        this.unsavedChanges.set(dirty && !isSubmitting);

        if(isSubmitting && !this.haveSentErrors) {
          for (let [key, value] of Object.entries(errors)) {
            setTimeout(this.sendNotification(value, "error"), 400)
            this.haveSentErrors = true;
          }
        }
        if(this.haveSentErrors && !isSubmitting) this.haveSentErrors = false;

        const editionOptions = this.state.editions && this.state.editions.map(edition => (
            { 'value': edition.id, 'label': edition.name }
        ))
        const locationOptions = this.state.locationList && this.state.locationList.map(location => (
            { 'value': location.id, 'label': location.name }
        ))
        return (
            <Form>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-8">
                        <label>Title
                        <Field type="text" name="title" />
                        </label>
                        <ErrorMessage name='title' />

                        <label>Subtitle
                        <Field type="text" name="subtitle" />
                        </label>

                        <label>Description</label>
                        <FluxRTE
                            editorState={values.editorState}
                            onChange={setFieldValue}
                            onBlur={handleBlur}
                            placeHolder="For example, 'Friday’s workshops take place at the Communitech Hub in Kitchener’s innovation district. 
                                                    This beautiful space in the newly-renovated Lang Tannery building is the physical heart of the region’s buzzing tech scene.'"
                        />

                        <label>Rank on page</label>
                        <Field component="select" name="priority">
                            {this.state.priorityTypes
                                ? this.state.priorityTypes.map((priorityType, idx) => (
                                    <option value={priorityType.id} key={idx}>
                                        {priorityType.name}
                                    </option>
                                ))
                                : null}
                        </Field>

                        <label>Infrastructure type</label>
                        <Field component="select" name="anchor">
                            {this.state.anchorTypes
                                ? this.state.anchorTypes.map((anchorType, idx) => (
                                    <option value={anchorType.val} key={idx}>
                                        {anchorType.name}
                                    </option>
                                ))
                                : null}
                        </Field>

                        <Field type="hidden" name="featuredImageIds" />
                        <p>Featured image</p>
                        {this.state.featuredImages.map((image, idx, arr) => (
                            <div key={idx}>
                                <img
                                    alt="Preview"
                                    key={image.preview}
                                    src={image.preview}
                                    className="image-preview"
                                /><br />
                                <div className="row">
                                    <label>Alt-text:</label>
                                </div>
                                <div className="row">
                                    <input type="text" value={image.altText} onChange={(e) => this.onChangeAltText(e, idx)} />
                                </div>
                                <div className="row">
                                    <button id="removePhotoButton" type="button" className="button alert" onClick={() => { this.removePhoto(idx) }}>Remove photo</button>
                                    {(idx === 0) ? <button className="button" disabled>▲</button> : <button type="button" className="button" onClick={() => this.reorderImages(image.id, idx, -1)}>▲</button>}
                                    {(idx === (arr.length - 1)) ? <button className="button" disabled>▼</button> : <button type="button" className="button" onClick={() => this.reorderImages(image.id, idx, 1)}>▼</button>}
                                </div>
                            </div>
                        ))}
                        {this.state.featuredImages.length < 10 ?
                            <ImagePicker onDrop={this.onDrop} setImage={this.setPhotoFromLibrary} setFieldValue={setFieldValue} buttonText={'Add images'} />
                            : <button className="button" disabled>Add images</button>}

                        <label>Edition</label>
                        <Field name='edition' options={editionOptions} component={SelectField} isMulti={false} placeholder='Choose an edition by typing or using the dropdown...'
                            onChange={this.changeEdition} />

                        <label>Location</label>
                        <Field name='location' options={locationOptions} component={SelectField} isMulti={false} placeholder='Choose a location by typing or using the dropdown...'
                            onChange={this.changeLocation} />
                    </div>
                    <div className="cell small-4  sidebar">
                        <PublishModule
                            setFieldValue={(key, value) => this.customSetValue(key, value, setFieldValue, values)}
                            setFieldTouched={setFieldTouched}
                            values={values}
                            publishChanges={this.publishChanges}
                            revertChanges={this.revertChanges}
                            removeObject={this.removeFestivalInfrastructure}
                            objectId={this.state.id}
                            next={this.state.next}
                            nextReady={this.state.nextReady}
                            currentPublishState={values.publish}
                        />

                        <NotificationMessage message={this.state.message} ref={this.notify} />

                    </div>
                </div>
            </Form>
        )
    };

    render() {
        return (
            <div>
                {!this.state.mounted && <Loading />}

                {this.state.data ? (
                    <Formik initialValues={this.state.data}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={festivalInfrastructureSchema}
                        innerRef={this.formik}
                    >
                      {this.renderForm}
                    </Formik>
                ) : null}
            </div>
        )
    };
}

export default FestivalInfrastructureForm;
