import axios from 'axios'
import saveError from '../Utilities/saveError';
const API_URL='/api/testimonials/';
const TestimonialAPI = {
    getTestimonial: async (id) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + id)
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject)
        });
      })
    },
    
    saveTestimonial: async (url, method, values) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + url, {
            method: method,
            data: JSON.stringify(values),
            headers: {
              'Content-Type': 'application/json'
            },
        })
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject);
        });
      })
    },

    getTestimonials: async () => {
      return new Promise((resolve, reject) => {
        axios(API_URL)
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject)
        });
      })
    },

    deleteTestimonial: async (id) => {
      return new Promise((resolve, reject) => {
        axios.delete(API_URL + id, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject)
        });
      })
    },
}
export default TestimonialAPI;
