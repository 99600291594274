import axios from 'axios'
import saveError from '../Utilities/saveError';
const API_URL='/api/eventTypes/';
const EventType = {
    getEventTypes: async () => {
      return new Promise((resolve, reject) => {
        axios(API_URL)
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject)
        });
      })
    },

    deleteEventType: async (id) => {
      return new Promise((resolve, reject) => {
        axios.delete(API_URL + id)
        .then(response => resolve(response.data))
        .catch(error => saveError(error, reject));
      })
    },

    getEventType: async (id) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + id)
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject)
        });
      })
    },

    saveEventType: async (method, url, values) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(values)
        })
        .then( response => resolve(response.data))
        .catch(error => {
            saveError(error, reject);
        });
      })
    },
}

export default EventType;
