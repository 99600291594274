import React from "react";

import { EditionForm, EditionList } from "./index"

export const AddEdition = ()  => (
    <div>
        <h2>Add an edition</h2>
        <EditionForm />
    </div>
);

export const EditEdition = ({ match })  => (
    <div>
        <h2>Edit an edition</h2>
        <EditionForm id={match.params.id} />
    </div>
);

export const ListEditions = (props) => (
    <div>
        <h2>Editions</h2>
        <EditionList {...props} />
    </div>
)
