import axios from "axios";
import saveError from "../Utilities/saveError";
import { createQueryString } from "../Utilities/refineOptions";

const API_URL = "/api/sponsors/";
const config = { params: { relations: ["logo", "edition"] } };
const listConfig = { params: { relations: ["edition"] } };
const refineOptionList = [
  "filterBy",
  "filterId"
];

const SponsorAPI = {
  getSponsors: async () => {
    return new Promise((resolve, reject) => {
    axios(API_URL, { params: { relations: ["edition"] } })
      .then(response => resolve(response.data))
      .catch(error => {
        saveError(error, reject)
      });
    })
  },

  getSponsor: async id => {
    return new Promise((resolve, reject) => {
    axios(API_URL + id, config)
      .then(response => resolve(response.data))
      .catch(error => {
        saveError(error, reject)
      });
    })
  },

  filterSponsors: async customQuery => {
    return new Promise((resolve, reject) => {
    const queryString = customQuery
      ? customQuery
      : createQueryString("sponsor", refineOptionList);
    axios.get(API_URL + queryString, listConfig)
      .then(response => resolve(response.data))
      .catch(error => {
        saveError(error, reject)
      });
    })
  },

  saveSponsor: async (method, url, values) => {
    return new Promise((resolve, reject) => {
    axios(API_URL + url, {
      method: method,
      headers: {
        "Content-Type": "application/json"
      },
      data: JSON.stringify(values)
    })
      .then(response => resolve(response.data))
      .catch(error => {
        saveError(error, reject);
      });
    })
  },

  deleteSponsor: async id => {
    return new Promise((resolve, reject) => {
    axios.delete(API_URL + id)
    .then(response => resolve(response.data))
    .catch(error => saveError(error, reject));
    })
  },

  getSponsorBuilds: async (id, after = null) => {
    return new Promise((resolve, reject) => {
    axios(`${API_URL}build/${id}${after !== null ? "?after=" + encodeURI(after) : ""}`)
      .then(response => resolve(response.data))
      .catch(error => {
        saveError(error, reject)
      });
    })
  },

  publishSponsor: async id => {
    return new Promise((resolve, reject) => {
    axios.post(`${API_URL}publish/${id}`)
      .then(response => resolve(response.data))
      .catch(error => {
        saveError(error, reject)
      });
    })
  },
};

export default SponsorAPI;
