import React from "react";

import { BlogPostForm, BlogPostList } from "./index"

export const AddBlogPost = ()  => (
    <div>
        <h2>Add a blog post</h2>
        <BlogPostForm />
    </div>
);

export const EditBlogPost = ( { match } )  => (
    <div>
        <h2>Edit a blog post</h2>
        <BlogPostForm id={match.params.id} />
    </div>
);

export const ListBlogPosts = (props) => (
    <div>
        <h2>Blog Posts</h2>
        <BlogPostList {...props} />
    </div>
)