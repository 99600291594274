import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import Auth from '../Auth/Auth'
import BlogPostAPI from './BlogPostAPI';
import TagAPI from '../Tags/TagAPI';
import UserAPI from '../Users/UserAPI';
import FilterSelect from '../Components/FilterSelect'
import Loading from '../Components/Loading';
import SortCard from '../Components/SortCard'
import { formatDateTime, showVisibilityState } from '../Utilities/format';
import { getRefineOptions, verifyId } from '../Utilities/refineOptions';
import NotificationMessage from "../Components/NotificationMessage";


const refineOptionList = ['sortBy', 'sortDir', 'filterBy', 'filterId'];
const filterBy = ['author', 'status', 'tag', 'year'];
const filterByIndices = { author: 0, status: 1, tag: 2, year: 3 };

class BlogPostList extends Component {
    constructor(props) {
        super(props);
        let message = '';
        const refineOptions = getRefineOptions('blogPost', refineOptionList);
        if (this.props.location.state && this.props.location.state.deletedObject) {
            message = 'Blog post successfully deleted';
        }
        this.state = {
            blogPosts: [],
            message,
            sortType: { attribute: 'publishedOn', direction: 'desc' },
            authors: [{ "id": "none", "name": "" }],
            years: [],
            statuses: [{ "id": "draft", "name": "Draft" }, { "id": "published", "name": "Published" }, { "id": "scheduled", "name": "Scheduled" }],
            tags: [{ "id": "none", "name": "" }],
            sortBy: 'title',
            sortDir: 'ASC',
            filterBy,
            filterId: ['All', 'All', 'All', 'All'],
            refineOptions,
        }
        this.notify = React.createRef();
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    async componentDidMount() {
        document.title = `Blog Posts | ${process.env.REACT_APP_TITLE}`;

        await TagAPI.getTags()
            .then(async res => {
                await this.setStateAsync({ tags: this.state.tags.concat(res.tags) });
            })
            .catch(err => console.log(err));

        for (const i in refineOptionList) {
            const option = refineOptionList[i];
            const key = 'blogPost' + _.upperFirst(option);
            if (!localStorage[key]) {
                localStorage.setItem('blogPost' + _.upperFirst(option), this.state[option]);
            }
            else if (this.state[option] !== this.state.refineOptions[option]) {
                await this.setStateAsync({ [option]: this.state.refineOptions[option] });
            }
            //check if any of the selected filters have since been deleted and reset to All if so
            if (option === 'filterId') {
                let verifiedIds = this.state.filterId.slice();
                const skipIds = ['All', 'none'];
                for (const j in this.state.filterId) {
                    if (filterBy[j] === 'tag' && !skipIds.includes(verifiedIds[j])) {
                        verifiedIds[j] = verifyId(this.state.tags, verifiedIds[j]);
                    }
                }
                if (verifiedIds !== this.state.filterId) {
                    this.setState({ filterId: verifiedIds });
                    localStorage.setItem(key, verifiedIds);
                }
            }
        }

        BlogPostAPI.getBlogPosts()
            .then(res => {
                const yearList = res.blogPosts.map(post => post.publishedOn ? post.publishedOn.substring(0, 4) : '')
                    .filter((v, i, a) => a.indexOf(v) === i && v !== '');
                const years = yearList.map(y => ({ id: y, name: y }));
                this.setState({ years });
            })
            .catch(err => console.log(err));

        this.fetchBlogPosts()
        .then(() => this.setState({mounted: true}))
        .catch(err => {
          this.setState({mounted: true})
          this.sendNotification(`Could not load blog posts (${err.response.status})`, "error")
        });

        UserAPI.getUsers()
            .then(res => {
                this.setState({ authors: this.state.authors.concat(res.users) });
            })
            .catch(err => console.log(err));

    }

    fetchBlogPosts = async () => {
      return new Promise((resolve, reject) => {
        BlogPostAPI.filterBlogPosts()
        .then(res => {
            this.setState({ blogPosts: res.blogPosts });
            resolve(res.blogPosts)
        })
        .catch(err => { reject(err) });
      })
    }

    setFilter = (value, type) => {
        const index = filterByIndices[type];
        const ids = this.state.filterId;
        let newIds = ids;
        newIds[index] = value;
        localStorage.setItem('blogPostFilterId', newIds);
        this.fetchBlogPosts()
            .then(() => {
                this.setState({ filterId: newIds });
            })
            .catch((err) => {
                console.log(err);
                localStorage.setItem('blogPostFilterId', this.state.filterId);
            })
    }

    onSortAttribute = (attribute, direction) => {
        if (this.state.sortBy === attribute && this.state.sortDir === direction) return;

        localStorage.setItem('blogPostSortBy', attribute);
        localStorage.setItem('blogPostSortDir', direction);

        this.fetchBlogPosts()
            .then(res => this.setState({ sortBy: attribute, sortDir: direction }))
            .catch(err => {
                console.log(err);
                localStorage.setItem('blogPostSortBy', this.state.sortBy);
                localStorage.setItem('blogPostSortDir', this.state.sortDir);
            });
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }
    
    render() {
        const Header = () => (
            <tr>
                <th>Title</th>
                <th>Author</th>
                <th>Publish Date</th>
                <th>Tags</th>
            </tr>
        );

        const Row = (post) => (
            <tr>
                <td><strong><Link to={`/blogPosts/${post.id}/edit`}>{post.title}</Link></strong></td>
                <td>{post.author ? post.author.name : ''}</td>
                <td>
                    <div>{formatDateTime(post.publishedOn)}</div>
                    <strong>{showVisibilityState(post.publishedOn)}</strong>
                </td>
                <td>{post.tags.map(t => t.name).join(', ')}</td>
            </tr>
        );

        const sortAttributes = ['title', 'author', 'publishedOn'];
        const sortType = { attribute: this.state.sortBy, direction: this.state.sortDir }
        const sortCards = sortAttributes.map((attribute) => <SortCard attribute={attribute} sortType={sortType} onSortAttribute={this.onSortAttribute} key={`sortcard${attribute}`} />);

        return (
            <div>
                {!this.state.mounted && <Loading />}

                <div className="grid-x">
                  <div className="cell small-4">
                    { Auth.hasRole(['admin', 'editor', 'writer', 'contributor']) ?
                      (<Link to="/blogPosts/new" className="button">Add Blog Post</Link>) : null
                    }
                  </div>
                  <div className="cell small-8">
                    <NotificationMessage message={this.state.message} small={true} ref={this.notify} />
                  </div>
                </div>

                <label>
                    Sort by attribute:
                </label>
                <div className="grid-x grid-padding-x">
                    {sortCards}
                </div>

                <div className="grid-x grid-padding-x">
                    <div className="medium-3 cell">
                        <label>Filter by Author:
                            <FilterSelect options={this.state.authors} setSelectedFilter={this.setFilter} type='author' value={this.state.filterId[filterByIndices.author]} />
                        </label>
                    </div>

                    <div className="medium-3 cell">
                        <label>Filter by Status:
                            <FilterSelect options={this.state.statuses} setSelectedFilter={this.setFilter} type='status' value={this.state.filterId[filterByIndices.status]} />
                        </label>
                    </div>

                    <div className="medium-3 cell">
                        <label>Filter by Tag:
                            <FilterSelect options={this.state.tags} setSelectedFilter={this.setFilter} type='tag' value={this.state.filterId[filterByIndices.tag]} />
                        </label>
                    </div>

                    <div className="medium-3 cell">
                        <label>Filter by Year:
                            <FilterSelect options={this.state.years} setSelectedFilter={this.setFilter} type='year' value={this.state.filterId[filterByIndices.year]} />
                        </label>
                    </div>
                </div>

                <table>
                    <thead>
                        <Header />
                    </thead>
                    <tbody>
                        {this.state.blogPosts && this.state.blogPosts.map(post => (
                            <Row {...post} key={post.id} />
                        ))}
                    </tbody>
                </table>
            </div>
        )
    };
}

export default BlogPostList;
