import axios from 'axios'
import saveError from '../Utilities/saveError';
import { createQueryString } from '../Utilities/refineOptions';
const API_URL = '/api/festivalInfrastructure/';
const refineOptionList = ['filterBy', 'filterId'];
let config = ''//{params: {relations:['editions', 'festivalInfrastructure']}};
const FestivalInfrastructureAPI = {
    getFestivalInfrastructure: async (id) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + id)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    saveFestivalInfrastructure: async (url, method, values) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + url, {
            method: method,
            data: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject);
            });
          })
    },

    getAllFestivalInfrastructure: async () => {
      return new Promise((resolve, reject) => {
        axios(API_URL, config)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    deleteFestivalInfrastructure: async (id) => {
      return new Promise((resolve, reject) => {
        axios.delete(API_URL + id, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => resolve(response.data))
            .catch(error => saveError(error, reject));
      })
    },

    filterFestivalInfrastructure: async (customQuery) => {
      return new Promise((resolve, reject) => {
        const queryString = customQuery ? customQuery : createQueryString('festivalInfrastructure', refineOptionList);
        axios.get(API_URL + queryString, config)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    getFestivalInfrastructureBuilds: async (id, after = null) => {
      return new Promise((resolve, reject) => {
        axios(`${API_URL}build/${id}${after !== null ? "?after=" + encodeURI(after) : ""}`)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    publishFestivalInfrastructure: async id => {
      return new Promise((resolve, reject) => {
        axios.post(`${API_URL}publish/${id}`)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },
}
export default FestivalInfrastructureAPI;