import axios from 'axios'
import saveError from "../Utilities/saveError";
import { createQueryString } from '../Utilities/refineOptions';

const API_URL = '/api/pages/';
const singleConfig = { params: { relations: ['featuredImage'] } }
const config = { params: { relations: ['author'] } };
const refineOptionList = ['sortBy', 'sortDir', 'filterBy', 'filterId'];

const PageAPI = {
    getPages: async () => {
      return new Promise((resolve, reject) => {
        axios(API_URL, config)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    getPage: async (id) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + id, singleConfig)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    savePage: async (url, method, values) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + url, {
            method: method,
            data: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    deletePage: async (id) => {
      return new Promise((resolve, reject) => {
        axios.delete(API_URL + id, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => resolve(response.data))
            .catch(error => saveError(error, reject));
      })
    },

    filterPages: async () => {
      return new Promise((resolve, reject) => {
        const queryString = createQueryString('page', refineOptionList);

        axios.get(API_URL + queryString, config)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    getPageBuilds: async (id, after = null) => {
      return new Promise((resolve, reject) => {
        axios(`${API_URL}build/${id}${after !== null ? "?after=" + encodeURI(after) : ""}`)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },

    publishPage: async id => {
      return new Promise((resolve, reject) => {
        axios.post(`${API_URL}publish/${id}`)
            .then(response => resolve(response.data))
            .catch(error => {
                saveError(error, reject)
            });
          })
    },
}
export default PageAPI;
