import React, { Component } from "react";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Formik, Field, FieldArray, Form } from "formik";
import { mdToDraftjs, draftjsToMd } from "draftjs-md-converter";
import { animateScroll as scroll } from "react-scroll";

import Auth from "../Auth/Auth";
import FluxRTE from "../Components/FluxRTE";
import SpeakerAPI from "./SpeakerAPI";
import EditionAPI from "../Editions/EditionAPI";
import MediaAPI from "../Media/MediaAPI";
import EventTypeAPI from "../EventTypes/EventTypeAPI";
import Loading from "../Components/Loading";
import { speakerSchema } from "shared";
import { ErrorMessage } from "../Components/ErrorMessage";
import ImagePicker from "../Components/ImagePicker";
import { parseSocialMediaInput } from "shared";
import { currentTimeRounded, formatDateTime } from "../Utilities/format";
import history from "../Utilities/history";
import {
  changesSavedPrompt,
  removeObjectPrompt,
  unsavedChangesPrompt,
  Wrapper
} from "../Utilities/formPrompts";
import Moment from "../Utilities/moment";
import { SelectField } from "../Components/SelectField";
import NotificationMessage from "../Components/NotificationMessage";
import PublishModule from "../Components/PublishModule"

const initialValues = {
  name: "",
  jobPosition: "",
  bio: "",
  imageId: "",
  editionId: undefined,
  books: [],
  socialMedia: [],
  publish: "draft",
  publishedOn: "",
  publishDate: "",
  editorState: EditorState.createEmpty(),
  eventTypes: []
};

class SpeakerForm extends Component {
  _mounted = false
  constructor(props) {
    super(props);
    this.state = this.defaultState = {
      data: initialValues,
      message: "",
      id: "",
      photo: null,
      editions: [{ id: undefined, name: "" }],
      viewPublishState: false,
      viewPublishDate: false,
      eventTypes: [],
      next: null,
      nextReady: false
    };
    this.formik = React.createRef();
    this.unsavedChanges = new Wrapper(false);
    this.submittingFromModal = new Wrapper(false);
    this.nextLocation = history.location;
    this.notify = React.createRef();
    this.haveSentErrors = false;
  }

  safeSetState(object, func) {
    if (this._mounted) {
      this.setState(object, func)
    }
  }

  componentDidMount() {
    this._mounted = true
    if (this.props.id) {
      SpeakerAPI.getSpeaker(this.props.id)
        .then(res => {
          if (!this.isCancelled) {
            const speaker = { ...res.speaker };
            this.getBuildInformation(speaker.id, speaker.publishedOn);
            document.title = `${speaker.name} | Speaker | ${process.env.REACT_APP_TITLE}`;

            if (speaker.editionId === null) speaker.editionId = undefined;
            if (speaker.imageId === null) {
              speaker.imageId = "";
            } else {
              speaker.photo.preview =
                process.env.REACT_APP_MEDIA_URL + speaker.photo.name;
            }

            this.oldPhoto = speaker.photo;

            if (speaker.publishedOn !== null) {
              speaker.publishDate = Moment(speaker.publishedOn).toDate();
              speaker.publishedOn = formatDateTime(speaker.publishedOn);
              speaker.publish = "publish";
            } else {
              const now = currentTimeRounded();
              speaker.publishDate = Moment(now).toDate();
              speaker.publishedOn = "";
              speaker.publish = "draft";
            }

            speaker.editorState = speaker.bio
              ? EditorState.createWithContent(
                convertFromRaw(mdToDraftjs(speaker.bio))
              )
              : EditorState.createEmpty();

            this.safeSetState({
              data: speaker,
              id: speaker.id,
              photo: speaker.photo,
              mounted: true
            }, () => {
              // send message when brand new blog post after history push happens.
              if (this.state.mounted && history.location.state && history.location.state.notify.message !== null) {
                this.sendNotification(history.location.state.notify.message, history.location.state.notify.type);
                history.replace({ state: null });
              }
            });

            // convert the event types into values and labels for the dropdown
            if (speaker.eventTypes) {
              speaker.eventTypes = speaker.eventTypes.map(eventType => ({
                value: eventType.id,
                label: eventType.name
              })).sort((a, b) => { return a.label === b.label ? 0 : a.label > b.label ? 1 : -1 });
            }

            EventTypeAPI.getEventTypes()
              .then(res => {
                this.safeSetState({ eventTypes: res.eventTypes });
              })
              .catch(err => {
                console.log(err)
                this.sendNotification(err.toString(), 'error');
              });
          }
        })
        .catch(err => {
          console.log(err)
          this.sendNotification(err.toString(), 'error');
          this.setState({ mounted: true })
        });

    } else {
      const speaker = { ...this.state.data };
      const now = currentTimeRounded();

      speaker.publishDate = Moment(now).toDate();

      document.title = `New Speaker | ${process.env.REACT_APP_TITLE}`;

      this.safeSetState({ mounted: true, data: speaker });

      EventTypeAPI.getEventTypes()
        .then(res => {
          this.safeSetState({
            eventTypes: this.state.eventTypes.concat(res.eventTypes)
          });
        })
        .catch(err => console.log(err));
    }

    EditionAPI.getEditions()
      .then(res => {
        this.safeSetState({ editions: this.state.editions.concat(res.editions) });
      })
      .catch(err => console.log(err));

    this.unblock = history.block(nextLocation => {
      this.nextLocation = nextLocation;
      if (this.unsavedChanges.get()) {
        unsavedChangesPrompt(
          nextLocation,
          this.unsavedChanges,
          this.formik.current,
          this.submittingFromModal
        );
        return false;
      } else return true;
    });

  }

  componentWillUnmount() {
    this._mounted = false;
    this.isCancelled = true;
    this.unblock();
  }

  onDrop = (files, rejected, setFieldValue) => {
    const data = new FormData();
    data.append("file", files[0]);
    MediaAPI.upload(data).then(res => {
      let image = res.images[0]
      setFieldValue("imageId", image.id);
      image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
      this.safeSetState({
        photo: image
      });
    });
  };

  cleanSpeaker(values) {
    let url = "";
    let method = "post";

    if (this.state.id) {
      url += this.state.id;
      method = "put";
    }

    const speaker = { ...values };
    delete speaker.photo;
    this.oldPhoto = this.state.photo;

    // convert the event type to id and name to write to the database
    if (speaker.eventTypes) {
      speaker.eventTypes = speaker.eventTypes.map(eventType => ({
        id: eventType.value,
        name: eventType.label
      }));
    }

    // only set to 0 for the default avatar, and needs to be nullified to not attempt saving the default photo
    if (speaker.imageId === 0) {
      speaker.imageId = "";
    }
    if (speaker.editionId === "") speaker.editionId = null;

    speaker.bio = draftjsToMd(
      convertToRaw(values.editorState.getCurrentContent())
    );
    delete speaker.editorState;
    delete speaker.edition;
    delete speaker.eventIds;

    if (
      speaker.publish === "publish" ||
      (!speaker.publish && speaker.publishedOn)
    ) {
      speaker.publishedOn = Moment(speaker.publishDate).toISOString();
    } else {
      speaker.publishedOn = null;
    }
    delete speaker.publish;

    speaker.socialMedia.map(social => {
      const obj = "speaker";
      return parseSocialMediaInput(social, obj);
    });

    if (this.state.photo) {
      MediaAPI.saveMedia(this.state.photo.id, "put", { ...this.state.photo })
    }

    return { url, method, speaker };
  }

  onSubmit = values => {
    const { url, method, speaker } = this.cleanSpeaker(values);

    SpeakerAPI.saveSpeaker(method, url, speaker)
      .then(data => {
        this.sendNotification(data.message, 'success')
        this.unsavedChanges.set(false);

        this.safeSetState({
          data: values,
          id: data.speaker.id,
          message: data.message
        });

        scroll.scrollToTop();
        this.getBuildInformation(data.speaker.id, data.speaker.publishedOn);
        history.replace({
          pathname: `/speakers/${this.state.id}/edit`,
          state: { notify: { message: data.message, type: 'success' } }
        });
      })
      .catch(error => {
        this.sendNotification(error.toString(), 'error')
      })
      .then(() => {
        if (this.submittingFromModal.get()) {
          changesSavedPrompt(this.nextLocation, this.unsavedChanges);
        }
        this.submittingFromModal.set(false);
      });
  };

  revertChanges = () => {
    this.formik.current.resetForm();
    this.sendNotification("Reverted changes", 'warning')
    this.safeSetState({ photo: this.oldPhoto });
    scroll.scrollToTop();
  };

  updateViewPublishState = () => {
    this.safeSetState((state, props) => ({
      viewPublishState: !state.viewPublishState
    }));
  };

  updateViewPublishDate = () => {
    this.safeSetState((state, props) => ({
      viewPublishDate: !state.viewPublishDate
    }));
  };

  publishChanges = () => {
    const values = this.formik.current.values;
    const { url, method, speaker, } = this.cleanSpeaker(values);

    SpeakerAPI.saveSpeaker(method, url, speaker)
      .then(data => {
        this.unsavedChanges.set(false);
        this.sendNotification(data.message, 'success');

        SpeakerAPI.publishSpeaker(data.speaker.id)
          .then(publishData => {
            this.safeSetState({
              data: values,
              id: data.speaker.id,
            });
            this.sendNotification(publishData.message, 'success');
            scroll.scrollToTop();
            this.getBuildInformation(values.id, values.publishedOn);
          })
          .catch(error => {
            this.safeSetState({
              data: values,
              id: data.speaker.id,
            });
            this.sendNotification("Your changes are saved, but there was an error when trying to publish", 'error')
            scroll.scrollToTop();
            this.sendNotification(`Publish ${error.toString()}`, 'error');
          });
      })
      .catch(error => {
        this.sendNotification(error.toString(), 'error')
        scroll.scrollToTop();
      });
  };

  createSpeaker = () => {
    this.safeSetState({
      data: initialValues
    });
    history.replace("/speakers/new");
  };

  removeSpeaker = () => {
    removeObjectPrompt(
      "speaker",
      SpeakerAPI.deleteSpeaker,
      "/speakers",
      this.state.id,
      message => this.sendNotification(message, 'error')
    );
  };

  getBuildInformation = (id, date) => {
    const momentDate = Moment(date)
    if (momentDate.isValid()) {
      SpeakerAPI.getSpeakerBuilds(id, momentDate.format())
        .then(res => {
          this.safeSetState({ next: res.next, nextReady: true });
        })
        .catch(err => {
          this.sendNotification(err.toString(), 'error')
        })
    }
  };

  removePhoto = setFieldValue => {
    setFieldValue("imageId", "");
    this.safeSetState({
      photo: null
    });
  };

  setPhotoFromLibrary = (setFieldValue, newImage) => {
    const image = { ...newImage }
    setFieldValue("imageId", image.id);
    image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
    this.safeSetState({
      photo: image
    });
  };

  booksForm = values => (
    <div id="booksForm">
      <p>Books</p>
      <FieldArray
        name="books"
        render={arrayHelpers => (
          <div>
            {values.books &&
              values.books.map((book, index) => (
                <div key={index} className="grid-x grid-padding-x">
                  <div className="medium-6 cell">
                    <label htmlFor={`books.${index}.title`}>Title</label>
                    <Field
                      type="text"
                      name={`books.${index}.title`}
                      id={`books.${index}.title`}
                    />
                  </div>
                  <div className="medium-5 cell">
                    <label htmlFor={`books.${index}.url`}>Url</label>
                    <Field
                      type="text"
                      name={`books.${index}.url`}
                      id={`books.${index}.url`}
                    />
                  </div>
                  <div className="medium-1 cell">
                    {Auth.hasRole(["admin", "editor"]) ? (
                      <button
                        type="button"
                        className="button alert tiny"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        -
                      </button>
                    ) : null}
                  </div>
                </div>
              ))}
            {Auth.hasRole(["admin", "editor"]) ? (
              <button
                type="button"
                className="button secondary"
                onClick={() => arrayHelpers.push({ title: "", url: "" })}
              >
                + add a book
              </button>
            ) : null}
          </div>
        )}
      />
    </div>
  );

  onChangeAltText = (e) => {
    e.preventDefault()
    let photo = this.state.photo
    photo.altText = e.target.value
    this.safeSetState({ photo: photo })
  }

  // This custom setValue lets us prevent publishing without required values
  customSetValue = (key, value, setValue, formValues) => {
    if(key === "publish" && value === "publish") {
      if(formValues.eventTypes.length <= 0){
        this.sendNotification("Speakers cannot be published without an event type", "warning")
      } else if(!formValues.editionId){
        this.sendNotification("Speakers cannot be published without an edition", "warning")
      } else {
        setValue(key, value)
      }
    } else {
      setValue(key, value)
    }
  }

  socialMediaForm = values => (
    <div id="socialMediaForm">
      <p>Social media</p>
      <FieldArray
        name="socialMedia"
        render={arrayHelpers => (
          <div>
            {values.socialMedia &&
              values.socialMedia.map((social, index) => (
                <div key={index} className="grid-x grid-padding-x">
                  <div className="medium-6 cell">
                    <label>Type</label>
                    <Field
                      name={`socialMedia.${index}.type`}
                      component="select"
                    >
                      <option value="" />
                      <option value="website">Website</option>
                      <option value="twitter">Twitter</option>
                      <option value="linkedin">LinkedIn</option>
                      <option value="instagram">Instagram</option>
                      <option value="facebook">Facebook</option>
                    </Field>
                  </div>
                  <div className="medium-5 cell">
                    <label htmlFor={`socialMedia.${index}.value`}>Value</label>
                    <Field
                      type="text"
                      name={`socialMedia.${index}.value`}
                      id={`socialMedia.${index}.value`}
                      placeholder="URL (https://...) or Username"
                    />
                    <ErrorMessage name={`socialMedia.${index}.value`} />
                  </div>
                  <div className="medium-1 cell">
                    {Auth.hasRole(["admin", "editor"]) ? (
                      <button
                        type="button"
                        className="button alert tiny"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        -
                      </button>
                    ) : null}
                  </div>
                </div>
              ))}
            {Auth.hasRole(["admin", "editor"]) ? (
              <button
                type="button"
                className="button secondary"
                onClick={() => arrayHelpers.push({ type: "", value: "" })}
              >
                + add a social
              </button>
            ) : null}
          </div>
        )}
      />
    </div>
  );

  sendNotification(message, type) {
    if(this.notify.current) {
      this.notify.current.sendMessage(message, type)
    }
  }

  renderForm = ({
    dirty,
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    errors,
    setFieldValue,
    setFieldTouched,
    isSubmitting
  }) => {
    this.unsavedChanges.set(dirty && !isSubmitting);

    if(isSubmitting && !this.haveSentErrors) {
      for (let [key, value] of Object.entries(errors)) {
        setTimeout(this.sendNotification(value, "error"), 400)
        this.haveSentErrors = true;
      }
    }
    if(this.haveSentErrors && !isSubmitting) this.haveSentErrors = false;

    // create the options for the event types dropdown
    const eventTypeOptions =
      this.state.eventTypes &&
      this.state.eventTypes.map(eventType => ({
        value: eventType.id,
        label: eventType.name
      }));

    return (
      <Form>
        <div className="grid-x grid-padding-x">
          <div className="cell small-8">
            {this.state.id && (
              <button
                type="button"
                className="hollow button"
                onClick={this.createSpeaker}
              >
                New Speaker
              </button>
            )}
            <label htmlFor="name">Name</label>
            <Field type="text" name="name" id="name" />
            <ErrorMessage name="name" />
            <label htmlFor="jobPosition">Role/Affiliation</label>
            <Field
              type="text"
              name="jobPosition"
              id="jobPosition"
              placeholder={'For example, "UX Designer at Zeitspace"'}
            />

            <label>Edition</label>
            <Field component="select" name="editionId">
              {this.state.editions &&
                this.state.editions.map((edition, idx) => (
                  <option value={edition.id} key={idx}>
                    {edition.name}
                  </option>
                ))}
            </Field>
            <ErrorMessage name="editionId" />

            <label>Bio</label>
            <FluxRTE
              editorState={values.editorState}
              onChange={setFieldValue}
              onBlur={handleBlur}
            />

            <Field type="hidden" name="imageId" />
            <p>Photo</p>
            {this.state.photo ? (
              <>
                <img
                  alt="Preview"
                  key={this.state.photo.preview}
                  src={this.state.photo.preview}
                  className="image-preview"
                />
                <br />
                {Auth.hasRole(["admin", "editor"]) && (
                  <div>
                    <div className="row">
                      <label>Alt-text:</label>
                    </div>
                    <div className="row">
                      <input type="text" id="altTextInput" value={this.state.photo.altText} onChange={this.onChangeAltText} />
                    </div>
                    <div className="row">
                      <button
                        type="button"
                        id="removePhotoButton"
                        className="button alert"
                        onClick={() => {
                          this.removePhoto(setFieldValue);
                        }}
                      >
                        Remove photo
                    </button>
                    </div>
                  </div>
                )}
              </>
            ) : (
                <>
                  {Auth.hasRole(["admin", "editor"]) && (
                    <ImagePicker
                      onDrop={this.onDrop}
                      setImage={this.setPhotoFromLibrary}
                      setFieldValue={setFieldValue}
                    />
                  )}
                </>
              )}

            {this.booksForm(values)}

            {this.socialMediaForm(values)}

            <label>Event Types</label>
            <Field
              name="eventTypes"
              options={eventTypeOptions}
              component={SelectField}
              placeholder="Choose types of events this speaker will be speaking at from the dropdown..."
            />
          </div>
          <div className="cell small-4  sidebar">
            <PublishModule
              setFieldValue={(key, value) => this.customSetValue(key, value, setFieldValue, values)}
              setFieldTouched={setFieldTouched}
              values={values}
              publishChanges={this.publishChanges}
              revertChanges={this.revertChanges}
              removeObject={this.removeSpeaker}
              objectId={this.state.id}
              next={this.state.next}
              nextReady={this.state.nextReady}
              currentPublishState={values.publish}
            />

            <NotificationMessage message={this.state.message} ref={this.notify} />
          </div>
        </div>
      </Form>
    );
  };

  render = () => (
    <div>
      {!this.state.mounted && <Loading />}
      {this.state.data ? (
        <Formik
          initialValues={this.state.data}
          enableReinitialize={true}
          onSubmit={this.onSubmit}
          validationSchema={speakerSchema}
          innerRef={this.formik}
        >
          {this.renderForm}
        </Formik>
      ) : null}
    </div>
  );
}

export default SpeakerForm;
