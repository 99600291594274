import React, { Component } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Formik, Field, Form } from 'formik';
import { mdToDraftjs, draftjsToMd } from 'draftjs-md-converter';
import { animateScroll as scroll } from 'react-scroll';

import Auth from '../Auth/Auth'
import EditionTypesAPI from "../EditionTypes/EditionTypesAPI"
import PageAPI from './PageAPI';
import Loading from '../Components/Loading';
import { ErrorMessage } from '../Components/ErrorMessage';
import FluxRTE from '../Components/FluxRTE';
import PublishModule from "../Components/PublishModule"
import ImagePicker from '../Components/ImagePicker'
import { pageSchema } from 'shared';
import history from '../Utilities/history';
import { changesSavedPrompt, removeObjectPrompt, unsavedChangesPrompt, Wrapper } from '../Utilities/formPrompts';
import { currentTimeRounded, formatDateTime } from '../Utilities/format';
import Moment from '../Utilities/moment';
import MediaAPI from '../Media/MediaAPI';
import NotificationMessage from "../Components/NotificationMessage";
import { slugify } from "../Utilities/format"

const pageFields = {
  title: '',
  slug: '',
  content: '',
  publish: 'draft',
  category: '',
  publishedOn: '',
  publishDate: '',
  authorId: '',
  imageId: '',
  editionTypeId: "",
  editorState: EditorState.createEmpty(),
};

const zeitspaceCategories = [
  "",
  "Room"
]
const defaultCategories = []

class PageForm extends Component {
  _mounted = false
  constructor(props) {
    super(props);
    this.state = this.defaultState = {
      data: pageFields,
      id: '',
      message: '',
      editionTypes: [{ "id": null, "name": "" }],
      featuredImage: null, viewPublishState: false,
      viewPublishDate: false,
      next: null,
      nextReady: false,
      categories: process.env.REACT_APP_PRODUCT === 'zeitspace' ? zeitspaceCategories : defaultCategories
    };
    this.formik = React.createRef();
    this.unsavedChanges = new Wrapper(false);
    this.submittingFromModal = new Wrapper(false);
    this.nextLocation = history.location;
    this.onSubmit = this.onSubmit.bind(this);
    this.formik = React.createRef();
    this.notify = React.createRef();
    this.haveSentErrors = false;
  }

  safeSetState(object, func) {
    if (this._mounted) {
      this.setState(object, func)
    }
  }

  async componentDidMount() {
    this._mounted = true
    if (this.props.id) {
      await PageAPI.getPage(this.props.id)
        .then(res => {
          const page = { ...res.page };
          this.getBuildInformation(page.id, page.publishedOn);
          document.title = `${page.title} | Page | ${process.env.REACT_APP_TITLE}`;
          if (page.publishedOn !== null) {
            page.publishDate = Moment(page.publishedOn).toDate();
            page.publishedOn = formatDateTime(page.publishedOn);
            page.publish = 'publish'
          } else {
            const now = currentTimeRounded();
            page.publishDate = Moment(now).toDate();
            page.publishedOn = '';
            page.publish = 'draft';
          }

          if (page.imageId === null) {
            page.imageId = "";
          } else {
            page.featuredImage.preview = process.env.REACT_APP_MEDIA_URL + page.featuredImage.name;
          }
          this.oldFeaturedImage = page.featuredImage;

          page.editorState = page.content ?
            EditorState.createWithContent(convertFromRaw(mdToDraftjs(page.content)))
            : EditorState.createEmpty();

          !this.isCancelled && this.safeSetState({ data: page, id: page.id, mounted: true, featuredImage: page.featuredImage }, () => {
            // send message when brand new blog post after history push happens.
            if (this.state.mounted && history.location.state && history.location.state.notify.message !== null) {
              this.sendNotification(history.location.state.notify.message, history.location.state.notify.type);
              history.replace({ state: null });
            }
          });
        })
        .catch(err => {
          console.log(err)
          this.sendNotification(err.toString(), 'error')
        });
    } else {
      document.title = `New Page | ${process.env.REACT_APP_TITLE}`;
      const page = { ...this.state.data };
      const now = currentTimeRounded();

      page.publishDate = Moment(now).toDate();

      this.safeSetState({ mounted: true, data: page });
    }

    EditionTypesAPI.getEditionTypes()
      .then(res => {
        this.setState({ editionTypes: this.state.editionTypes.concat(res.editionTypes) });
      })
      .catch((err) => {
        console.log(err)
        this.sendNotification(err.toString(), 'error')
      });

    this.unblock = history.block((nextLocation) => {
      this.nextLocation = nextLocation;
      if (this.unsavedChanges.get()) {
        unsavedChangesPrompt(nextLocation, this.unsavedChanges, this.formik.current, this.submittingFromModal)
        return false;
      }
      else return true;
    });
  }

  componentWillUnmount() {
    this._mounted = false;
    this.isCancelled = true;
    this.unblock();
  }

  onDrop = (files, rejected, setFieldValue) => {
    const data = new FormData();
    data.append('file', files[0]);  
    MediaAPI.upload(data)
      .then((res) => {
        let image = res.images[0]
        setFieldValue('imageId', image.id);
        image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
        this.safeSetState({
          featuredImage: image,
        });
      });
  }

  cleanPage(values) {
    let url = (this.state.id) ? this.state.id : '';
    let method = (this.state.id) ? 'put' : 'post';

    const page = { ...values };

    if (page.publish === 'publish' || (!page.publish && page.publishedOn)) {
      page.publishedOn = Moment(page.publishDate).toISOString();
    } else {
      page.publishedOn = null;
    }

    if (page.slug !== "") {
      page.slug = slugify(page.slug)
    }

    if (page.editionTypeId === "") page.editionTypeId = null

    delete page.publish
    delete page.editorState
    delete page.featuredImage
    this.oldFeaturedImage = this.state.featuredImage;

    if (!page.authorId)
      page.authorId = Auth.getUser().id;

    page.content = draftjsToMd(convertToRaw(values.editorState.getCurrentContent()));

    if (this.state.featuredImage) {
      MediaAPI.saveMedia(this.state.featuredImage.id, "put", { ...this.state.featuredImage })
    }

    return { url, method, page };
  }

  onSubmit(values) {
    const { url, method, page } = this.cleanPage(values);

    PageAPI.savePage(url, method, page)
      .then(data => {
        this.sendNotification(data.message, 'success')
        this.unsavedChanges.set(false);
        this.safeSetState({
          data: values,
          id: data.page.id,
          message: data.message,
          slug: data.slug
        });
        scroll.scrollToTop();
        this.getBuildInformation(data.page.id, data.page.publishedOn);
        history.replace({
          pathname: `/pages/${this.state.id}/edit`,
          state: { notify: { message: data.message, type: 'success' } }
        });
      })
      .catch(error => {
        this.sendNotification(error.toString(), 'error')
        scroll.scrollToTop();
      })
      .then(() => {
        if (this.submittingFromModal.get()) {
          changesSavedPrompt(this.nextLocation, this.unsavedChanges);
        }
        this.submittingFromModal.set(false);
      });
  }

  removePhoto = (setFieldValue) => {
    setFieldValue('imageId', "");
    this.safeSetState({
      featuredImage: null,
    });
  };

  setPhotoFromLibrary = (setFieldValue, newImage) => {
    const image = { ...newImage }
    setFieldValue('imageId', image.id);
    image.preview = process.env.REACT_APP_MEDIA_URL + image.name;
    this.safeSetState({
      featuredImage: image,
    });
  }

  createPage = () => {
    this.safeSetState({
      data: pageFields,
    })
    history.replace('/pages/new');
  }

  revertChanges = () => {
    this.formik.current.resetForm();
    this.sendNotification("Reverted changes", 'warning');
    this.safeSetState({ message: 'Reverted changes', featuredImage: this.oldFeaturedImage });
    scroll.scrollToTop();
  }

  updateViewPublishState = () => {
    this.safeSetState((state, props) => ({
      viewPublishState: !state.viewPublishState
    }));
  };

  updateViewPublishDate = () => {
    this.safeSetState((state, props) => ({
      viewPublishDate: !state.viewPublishDate
    }));
  };

  publishChanges = () => {
    const values = this.formik.current.values;
    const { url, method, page } = this.cleanPage(values);

    PageAPI.savePage(url, method, page)
      .then(data => {
        this.unsavedChanges.set(false);
        this.sendNotification(data.message, 'success');

        PageAPI.publishPage(data.page.id)
          .then(publishData => {
            this.setState({
              data: values,
              id: data.page.id,
            });
            this.sendNotification(publishData.message, 'success');
            scroll.scrollToTop();
            this.getBuildInformation(data.page.id, data.page.publishedOn);
          })
          .catch(error => {
            this.setState({
              data: values,
              id: data.page.id
            });
            scroll.scrollToTop();
            this.sendNotification(`Publish ${error.toString()}`, 'error');
          });
      })
      .catch(error => {
        this.sendNotification(error.toString(), 'error')
        scroll.scrollToTop();
      });
  }

  removePage = () => {
    removeObjectPrompt('page', PageAPI.deletePage, '/pages', this.state.id, (message) => this.notify.current(message, "error"));
  }

  onChangeAltText = (e) => {
    e.preventDefault()
    let featuredImage = this.state.featuredImage
    featuredImage.altText = e.target.value
    this.safeSetState({ featuredImage: featuredImage })
  }

  getBuildInformation = (id, date) => {
    const momentDate = Moment(date)
    if (momentDate.isValid()) {
      PageAPI.getPageBuilds(id, momentDate.format())
        .then(res => {
          this.setState({ next: res.next, nextReady: true });
        })
        .catch(err => {
          this.sendNotification(err.toString(), 'error')
        })
    }
  };

  onPermalinkBlur = (e, setFieldValue) => {
    let slug = slugify(e.target.value)
    setFieldValue("slug", slug)
  }

  sendNotification(message, type) {
    if(this.notify.current) {
      this.notify.current.sendMessage(message, type)
    }
  }

  // This custom setValue lets us prevent publishing without required values
  customSetValue = (key, value, setValue, formValues) => {
    if(key === "publish" && value === "publish") {
      if(!formValues.editorState.getCurrentContent().hasText()){
        this.sendNotification("Page cannot be published without content", "warning")
      } else if(!formValues.editionTypeId){
        this.sendNotification("Page cannot be published without an edition type", "warning")
      } else {
        setValue(key, value)
      }
    } else {
      setValue(key, value)
    }
  }

  renderForm = ({ dirty, values, handleSubmit, handleChange, handleBlur, errors, setFieldTouched, setFieldValue, isSubmitting }) => {
    this.unsavedChanges.set(dirty && !isSubmitting);

    if(isSubmitting && !this.haveSentErrors) {
      for (let [key, value] of Object.entries(errors)) {
        setTimeout(this.sendNotification(value, "error"), 400)
        this.haveSentErrors = true;
      }
    }
    if(this.haveSentErrors && !isSubmitting) this.haveSentErrors = false;

    return (
      <Form>
        <div className="grid-x grid-padding-x">
          <div className="cell small-8">
            {this.state.id && <button type="button" className="hollow button" onClick={this.createPage}>New Page</button>}
            <label>Title
                        <Field type="text" name="title" placeholder="Speaker Spotlight" />
            </label>
            <ErrorMessage name='title' />
            <label>
              Permalink
              <Field
                type="text"
                name="slug"
                placeholder="If left empty it'll auto-generate from title"
                onBlur={e => this.onPermalinkBlur(e, setFieldValue)}
              />
            </label>

            <label>Edition Type</label>
            <Field component="select" name="editionTypeId">
              {this.state.editionTypes && this.state.editionTypes.map((type, idx) => (
                <option value={type.id} key={idx}>
                  {type.name}
                </option>
              ))}
            </Field>

            {this.state.categories.length !== 0 &&
              <>
                <label>Category</label>
                <Field
                  component="select"
                  name="category"
                  onChange={e => {
                    setFieldValue("category", e.target.value);
                  }}
                >
                  {this.state.categories.map((category, idx) => (
                    <option value={category} key={idx}>
                      {category}
                    </option>
                  ))}
                </Field>
              </>
            }

            <label>Content</label>
            <FluxRTE
              editorState={values.editorState}
              onChange={setFieldValue}
              onBlur={handleBlur}
              placeholder="We asked Di some questions about her work, philosophy, and her role as a design advocate. Here is what she said: "
            />

            <Field type="hidden" name="imageId" />
            <p>Featured image</p>
            {this.state.featuredImage ? (
              <>
                <img
                  alt="Preview"
                  key={this.state.featuredImage.preview}
                  src={this.state.featuredImage.preview}
                  className="image-preview"
                /><br />
                <div className="row">
                  <label>Alt-text:</label>
                </div>
                <div className="row">
                  <input type="text" value={this.state.featuredImage.altText} onChange={this.onChangeAltText} />
                </div>
                <div className="row">
                  <button
                    type="button"
                    id="removePhotoButton"
                    className="button alert"
                    onClick={() => {
                      this.removePhoto(setFieldValue);
                    }}
                  >
                    Remove photo
                  </button>
                </div>
              </>
            ) : (
                <ImagePicker onDrop={this.onDrop} setImage={this.setPhotoFromLibrary} setFieldValue={setFieldValue} />
              )}
          </div>
          <div className="cell small-4  sidebar">
            <PublishModule
              setFieldValue={(key, value) => this.customSetValue(key, value, setFieldValue, values)}
              setFieldTouched={setFieldTouched}
              values={values}
              publishChanges={this.publishChanges}
              revertChanges={this.revertChanges}
              removeObject={this.removePage}
              objectId={this.state.id}
              type="page"
              next={this.state.next}
              nextReady={this.state.nextReady}
              currentPublishState={values.publish}
            />

            <NotificationMessage message={this.state.message} ref={this.notify} />
          </div>
        </div>
      </Form>
    )
  };

  render() {
    return (
      <div>
        {!this.state.mounted && <Loading />}

        {this.state.data ? (
          <Formik initialValues={this.state.data}
            enableReinitialize={true}
            onSubmit={this.onSubmit}
            validationSchema={pageSchema}
            innerRef={this.formik}
          >
            {this.renderForm}
          </Formik>
        ) : null}
      </div>
    )
  };
}

export default PageForm;