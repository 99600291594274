import history from '../Utilities/history';
import auth0 from 'auth0-js';
import axios from 'axios';

import UserAPI from '../Users/UserAPI';

class AuthService {
  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_DOMAIN,
    audience: process.env.REACT_APP_AUDIENCE,
    clientID: process.env.REACT_APP_CLIENT_ID,
    redirectUri: process.env.REACT_APP_CALLBACK,
    responseType: 'token id_token',
    scope: 'openid email profile'
  });

  constructor() {

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.silentAuth = this.silentAuth.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
    this.user = {id: ""}
    this.updateOnAuth = []
    localStorage.setItem('authErrorMessage', '');
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash(async (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        await this.setUser();

        this.updateComponents();
        if (localStorage['redirectPath']) {
          history.push(localStorage['redirectPath']);
        } else {
          history.push('/')
        }
      } else if (err) {
        history.push('/');
        localStorage.setItem('authErrorMessage', `Error: ${err.error}. Check the console for further details.`);
      } else {
        history.push('/')
      }
    });
  }

  getErrorMessage() {
    return localStorage['authErrorMessage'];
  }

  getIdToken() {
    return this.idToken;
  }

  getProfile() {
    return this.profile;
  }

  getUser() {
    return this.user;
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    this.idToken = authResult.idToken;
    this.state = authResult.state;
    this.profile = authResult.idTokenPayload;
    this.expiresAt = (authResult.expiresIn * 1000) + Date.now();
    axios.defaults.headers.common['authorization'] = 'Bearer ' + authResult.idToken;
  }

  async setUser() {
    if (this.user && this.user.email === this.profile.email) return;
    await UserAPI.getUserByEmail(this.profile.email)
      .then(result => {
        if (result.status === 200) {
          this.user = result.data.user;
          localStorage.setItem('authErrorMessage', '');
        }
      })
      .catch(err => {
        localStorage.setItem('authErrorMessage', 'Error: ' + err.message);
        this.logout();
      });
  }

  logout() {
    // Clear access token and ID token from local storage
    axios.defaults.headers.common['authorization'] = 'Bearer: no_ID_Token_Present';
    this.expiresAt = null;
    this.idToken = null;
    this.profile = null;
    this.user = null;
    sessionStorage.clear()
    localStorage.setItem('redirectPath', '/');
  }

  forceLogout() {
    // navigate to the home route
    // the user is logged out of the app, but this will force an auth0 logout as well
    this.auth0.logout({
      returnTo: process.env.REACT_APP_INDEX_URL,
      clientID: process.env.REACT_APP_CLIENT_ID,
    });
    sessionStorage.clear()
  }

  isAuthenticated() {
    // Check whether the current time is past the 
    // access token's expiry time
    return Date.now() < this.expiresAt;
  }

  hasRole(roles) {
    if (!this.user) return false;
    const user = this.user;
    return roles.some(role => user.role.slug === role);
  }

  hasOwnerRole(roles, id, isCreate) {
    if (!this.user) return false;
    const user = this.user;
    if (isCreate)
      return roles.some(role => user.role.slug === role);

    if (id === this.user.id)
      return roles.some(role => user.role.slug === role);

    return ['admin', 'editor'].some(role => user.role.slug === role);
  }

  silentAuth() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, async (err, authResult) => {
        if (err) return reject(err);
        this.setSession(authResult);
        await this.setUser();
        resolve();
      });
    });
  }

   registerComponentToUpdate(cb) {
    this.updateOnAuth.push(cb)
   }

   updateComponents(cb) {
    this.updateOnAuth.forEach(cb => cb())
   }
}

const Auth = new AuthService();

export default Auth;