import React from 'react';
import { LocationForm, LocationList }from './index';

export const AddLocation = () => (
    <div>
        <h2>Add a location</h2>
        <LocationForm />
    </div>
);

export const EditLocation = ({ match }) => (
    <div>
        <h2>Edit a location</h2>
        <LocationForm id={match.params.id} />
    </div>
);

export const ListLocations = (props) => (
    <div>
        <h2>Locations</h2>
        <LocationList {...props} />
    </div>
);
