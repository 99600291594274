import axios from "axios";
import saveError from "../Utilities/saveError";
import { createQueryString } from "../Utilities/refineOptions";
const API_URL = "/api/speakers/";
const listConfig = { params: { relations: ["edition", "eventTypes"] } };
const singleConfig = {
  params: { relations: ["photo", "edition", "eventTypes"] }
};
const refineOptionList = [
  "keyword",
  "sortBy",
  "sortDir",
  "filterBy",
  "filterId"
];
const SpeakerAPI = {
  getSpeakers: async () => {
    return new Promise((resolve, reject) => {
      axios.get(API_URL, listConfig)
        .then(response => resolve(response.data))
        .catch(error => {
          saveError(error, reject)
        });
    })
  },

  filterSpeakers: async customQuery => {
    return new Promise((resolve, reject) => {
      const queryString = customQuery
        ? customQuery
        : createQueryString("speaker", refineOptionList);

      axios.get(API_URL + queryString, listConfig)
        .then(response => resolve(response.data))
        .catch(error => {
          saveError(error, reject)
        });
    })
  },

  deleteSpeaker: async id => {
    return new Promise((resolve, reject) => {
      axios.delete(API_URL + id)
        .then(response => resolve(response))
        .catch(err => saveError(err, reject));
    })
  },

  getSpeaker: async id => {
    return new Promise((resolve, reject) => {
      axios(API_URL + id, singleConfig)
        .then(response => resolve(response.data))
        .catch(error => {
          saveError(error, reject)
        });
    })
  },

  getSpeakerBuilds: async (id, after = null) => {
    return new Promise((resolve, reject) => {
      axios(`${API_URL}build/${id}${after !== null ? "?after=" + after : ""}`)
        .then(response => resolve(response.data))
        .catch(error => {
          saveError(error, reject)
        });
    })
  },

  publishSpeaker: async id => {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}publish/${id}`)
        .then(response => resolve(response.data))
        .catch(error => {
          saveError(error, reject)
        });
    })
  },

  saveSpeaker: async (method, url, values) => {
    return new Promise((resolve, reject) => {
      axios(API_URL + url, {
        method: method,
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(values)
      })
        .then(response => resolve(response.data))
        .catch(error => {
          saveError(error, reject);
        });
    })
  }
};

export default SpeakerAPI;
