import React from 'react'

export const mediaBlockRenderer = (block) => {
    if (block.getType() === 'atomic') {
        return {
            component: Media,
            editable: false,
        };
    }

    return null;
}

const Image = (props) => {
    if (props.src) {
        return <img src={props.src} alt="" />;
    }
    return null
};

const Media = (props) => {
    const entity = props.contentState.getEntity(
        props.block.getEntityAt(0)
    );
    let { src } = entity.getData();
    const type = entity.getType();

    let media;
    if (type === 'IMAGE') {
        if (!/^(f|ht)tps?:\/\//i.test(src)) {
            src = process.env.REACT_APP_MEDIA_URL + src;
        }
        media = <Image src={src} />;
    }

    return media;
};
