import React, { Component } from 'react';
import '../Styles/app.scss';
import Auth from '../Auth/Auth';
import Loading from '../Components/Loading';

class Callback extends Component {
  async componentDidMount() {
    await Auth.handleAuthentication();
  }

  render() {
    return (
        <Loading />
    );
  }
}

export default Callback;