import axios from 'axios'
import saveError from '../Utilities/saveError';
const API_URL='/api/roles/';

const RoleAPI = {
    getRoles: async () => {
      return new Promise((resolve, reject) => {
        axios(API_URL)
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject)
        });
      })
    },
}
export default RoleAPI;
