import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from 'lodash';

import Auth from "../Auth/Auth";
import SponsorAPI from "./SponsorAPI";
import Loading from "../Components/Loading";
import { showVisibilityState } from "../Utilities/format";
import { getCurrentEdition } from '../Utilities/currentEdition';
import { getRefineOptions, verifyId } from '../Utilities/refineOptions';
import FilterSelect from "../Components/FilterSelect";
import EditionAPI from "../Editions/EditionAPI";
import NotificationMessage from "../Components/NotificationMessage";

const refineOptionList = ['filterBy', 'filterId'];
const filterBy = ['edition'];
const filterByIndices = { edition: 0 };

class SponsorList extends Component {
  constructor(props) {
    super(props);
    let message = "";
    const refineOptions = getRefineOptions('sponsor', refineOptionList);
    if (this.props.location.state && this.props.location.state.deletedObject) {
      message = "Sponsor successfully deleted";
    }
    let filterId = []
    this.state = {
      filterBy,
      filterId,
      sponsors: [],
      message,
      editions: [{ "id": "none", "name": "" }],
      refineOptions
    };
    this.notify = React.createRef();

    this.setFilter = this.setFilter.bind(this);
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  async componentDidMount() {
    document.title = `Sponsors | ${process.env.REACT_APP_TITLE}`;

    await EditionAPI.getEditions()
      .then(async res => {
        await this.setStateAsync({ editions: this.state.editions.concat(res.editions) });
      })
      .catch(err => this.sendNotification(`Could not load edition options (${err.response.status})`, "error"));

    let filterId = this.state.filterId;
    filterId[filterByIndices.edition] = await getCurrentEdition();
    await this.setStateAsync({ filterId });

    for (const i in refineOptionList) {
      const option = refineOptionList[i];
      const key = 'sponsor' + _.upperFirst(option);
      if (!localStorage[key]) {
        localStorage.setItem(key, this.state[option]);
      } else if (this.state[option] !== this.state.refineOptions[option]) {
        if (Array.isArray(this.state.refineOptions[option])) {
          await this.setStateAsync({ [option]: this.state.refineOptions[option] });
        } else {
          await this.setStateAsync({ [option]: [this.state.refineOptions[option]] });
        }
      }
      if (option === 'filterId') {
        let verifiedIds = this.state.filterId.slice();
        const skipIds = ['All', 'none'];
        for (const j in verifiedIds) {
          if (filterBy[j] === 'edition' && !skipIds.includes(verifiedIds[j])) {
            verifiedIds[j] = verifyId(this.state.editions, verifiedIds[j]);
          }
        }
        if (verifiedIds !== this.state.filterId) {
          this.setState({ filterId: verifiedIds });
          localStorage.setItem(key, verifiedIds);
        }
      }
    }
    this.fetchSponsors()
      .catch(err => {
        this.setState({mounted: true})
        this.sendNotification(`Could not load sponsors (${err.response.status})`, "error")
      });
  }

  fetchSponsors = async () => {
    return new Promise((resolve, reject) => {
      SponsorAPI.filterSponsors()
      .then(res => {
        this.setState({ sponsors: res.sponsors, mounted: true });
        resolve(res.sponsors)
      })
      .catch(err => {reject(err)});
    })
  }

  setFilter = (value, type) => {
    const index = filterByIndices[type];
    const ids = this.state.filterId;
    let newIds = ids;
    newIds[index] = value;
    localStorage.setItem('sponsorFilterId', newIds);
    this.fetchSponsors()
      .then(() => {
        this.setState({ filterId: newIds });
      })
      .catch((err) => {
        console.log(err);
        localStorage.setItem('sponsorFilterId', this.state.filterId);
      })
  }

  sendNotification(message, type) {
    if(this.notify.current) {
      this.notify.current.sendMessage(message, type)
    }
  }

  render = () => {
    return (
      <div>
        {!this.state.mounted && <Loading />}

        <div className="grid-x">
          <div className="cell small-4">
            {Auth.hasRole(["admin", "editor"]) ? (
              <Link to="/sponsors/new" className="button">
                Add Sponsor
              </Link>
            ) : null}
          </div>
          <div className="cell small-8">
            <NotificationMessage message={this.state.message} small={true} ref={this.notify} />
          </div>
        </div>

        <div>{this.state.message}</div>
        <div className="grid-x grid-padding-x">
          <div className="medium-3 cell">
            <label>
              Filter by Edition
              <FilterSelect
                options={this.state.editions}
                setSelectedFilter={this.setFilter}
                value={this.state.filterId[filterByIndices.edition]}
                type="edition"
              />
            </label>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Edition</th>
              <th>Visibility</th>
            </tr>
          </thead>
          <tbody>
            {this.state.sponsors.map(sponsor => (
              <tr key={sponsor.id}>
                <td>
                  <strong>
                    <Link to={`/sponsors/${sponsor.id}/edit`}>
                      {sponsor.name}
                    </Link>
                  </strong>
                </td>
                <td>{sponsor.edition && sponsor.edition.name}</td>
                <td>{showVisibilityState(sponsor.publishedOn)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
}

export default SponsorList;
