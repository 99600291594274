import React from 'react'
import Select from 'react-select'

export function SelectField(FieldProps) {
    let changeHandler
    if (FieldProps.onChange){
      changeHandler = option => FieldProps.onChange(option, FieldProps.form.setFieldValue)
    }else {
      changeHandler = option => FieldProps.form.setFieldValue(FieldProps.field.name, option)
    }

    return (
      <Select
        placeholder={FieldProps.placeholder}
        options={FieldProps.options}
        isSearchable={true}
        isMulti={(FieldProps.isMulti === false) ?  false : true}
        {...FieldProps.field}
        onChange={changeHandler}
        className="form-margin-bottom"
        menuPlacement={FieldProps.menuPlacement ? FieldProps.menuPlacement : "auto"}
      />
    )
  }

