export const acceptedTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.oasis.opendocument.spreadsheet',
    'text/csv',
    'application/x-bzip',
    'application/x-bzip2',
    'application/gzip',
    'application/java-archive',
    'application/vnd.rar',
    'application/x-tar',
    'application/zip',
    'application/x-7z-compressed',
    'image/*',
    'audio/*',
    'video/*'
]

export const typeIcons = {
    'application/pdf': 'pdf',
    'application/msword': 'word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
    'application/vnd.oasis.opendocument.text': 'word',
    'application/vnd.ms-powerpoint': 'powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'powerpoint',
    'application/vnd.oasis.opendocument.presentation': 'powerpoint',
    'application/vnd.ms-excel': 'excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
    'application/vnd.oasis.opendocument.spreadsheet': 'excel',
    'text/csv': 'csv',
    'application/x-bzip': 'archive',
    'application/x-bzip2': 'archive',
    'application/gzip': 'archive',
    'application/java-archive': 'archive',
    'application/vnd.rar': 'archive',
    'application/x-tar': 'archive',
    'application/zip': 'archive',
    'application/x-7z-compressed': 'archive',
}