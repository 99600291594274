import axios from 'axios'
import saveError from '../Utilities/saveError';
const API_URL='/api/tags/';
const TagAPI = {
    getTag: async (id) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + id)
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject)
        });
      })
    },
    
    saveTag: async (url, method, values) => {
      return new Promise((resolve, reject) => {
        axios(API_URL + url, {
            method: method,
            data: JSON.stringify(values),
            headers: {
              'Content-Type': 'application/json'
            },
        })
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject);
        });
      })
    },

    getTags: async () => {
      return new Promise((resolve, reject) => {
        axios(API_URL)
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject)
        });
      })
    },

    deleteTag: async (id) => {
      return new Promise((resolve, reject) => {
        axios.delete(API_URL + id, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => resolve(response.data))
        .catch(error => {
            saveError(error, reject)
        });
      })
    },
}
export default TagAPI;
