import React from 'react';
import TestimonialAPI from '../Testimonials/TestimonialAPI'
import { testimonialSchema } from 'shared';
import { ButtonBlock } from './Icon';
import Auth from '../Auth/Auth'

class TestimonialCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { savedContent: props.content, content: props.content, id: props.id, deleteCard: props.delete, error: '', message: '' }
    }

    enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if(code === 13) { //13 is the enter keycode
            this.submit();
        } 
    }

    updateTestimonial = (event) => {
        this.setState({ content: event.target.value, error: '', message: '' })
    }

    submit = () => {
        if (this.state.content === this.state.savedContent) return
        testimonialSchema.validate({content: this.state.content})
        .then(value => {
            TestimonialAPI.saveTestimonial(this.state.id, 'PUT', value)
            .then(data => {
                this.setState({ savedContent: data.testimonial.content, message: 'Testimonial updated'});
            })
            .catch(error => {
                this.setState({ error: error.toString() });
            })
        })
        .catch(error => {
            this.setState({ error: error.message})
        })
        
    }

    delete = () => {
        this.state.deleteCard();
    }

    Card = () => (
        <div className="column">
            <div className="card">
                <div className="card-section">
                    <div className="input-group">
                        { Auth.hasRole(['admin', 'editor', 'writer', 'contributor']) ? (
                            <>
                            <textarea
                            className="input-group-field testimonial-input"
                            placeholder=''
                            onChange={this.updateTestimonial}
                            value={this.state.content}
                            onKeyPress={this.enterPressed}
                            onBlur={this.submit}
                            rows="3"
                            />
                            <ButtonBlock icon="times" action={this.delete}/>
                            </>
                        ) : (<p style={{marginBottom: 0}}>{this.state.name}</p>)
                        }
                    </div>
                    {this.state.message && <label>{this.state.message}</label>}
                    {this.state.error && <label className="form-error is-visible">{this.state.error}</label>}
                </div>
            </div>
        </div>
    );
    render () {
        return(
            this.Card()
        )
    }
}

export default TestimonialCard;