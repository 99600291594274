function saveError(error, reject = null) {
  const response = error.response;
  if (response && response.data && response.data.errors) {
    if(reject) return reject(response.data.errors.join(', '))
    else throw Error(response.data.errors.join(', '))
  }
  else if(response && response.data && response.data.message){
    if(reject) return reject(response.data.message)
    else throw Error(response.data.message)
  }
  if(reject) return reject(error)
  else throw Error(error)
}

module.exports = saveError;