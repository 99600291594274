import EditionAPI from '../Editions/EditionAPI';
import EditionTypesAPI from '../EditionTypes/EditionTypesAPI';

function getCurrentEdition() {
    let promise = new Promise(async (resolve) => {
        if (localStorage["current-edition"]) {
            resolve(localStorage["current-edition"])
        } else {
            let response = await EditionAPI.getEditions()
            let sortedEditions = await response.editions.sort((a, b) => {
                return new Date(b.startDate) - new Date(a.startDate)
            })
            localStorage.setItem("current-edition", sortedEditions[0].id)
            resolve(sortedEditions[0].id)
        }
    })

    return promise
}

async function setCurrentEdition(id, currentEditionId) {
  return new Promise(async (resolve, reject) => {
    EditionTypesAPI.setCurrentEdition(id, currentEditionId)
        .then((res) => {
            localStorage.setItem("current-edition", currentEditionId)
            resolve(res.value)
        })
        .catch(error => {
          reject(error.message ? error.message : error.toString())
        })
  })
}

export {
    setCurrentEdition,
    getCurrentEdition
}
