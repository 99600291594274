import React from "react";

import { PageForm, PageList } from "./index"

export const AddPage = ()  => (
    <div>
        <h2>Add a page</h2>
        <PageForm />
    </div>
);

export const EditPage = ( { match } )  => (
    <div>
        <h2>Edit a page</h2>
        <PageForm id={match.params.id} />
    </div>
);

export const ListPages = (props) => (
    <div>
        <h2>Pages</h2>
        <PageList {...props} />
    </div>
)