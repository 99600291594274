import React from "react";

import { EventTypeForm, EventTypeList } from "./index"

export const AddEventType = ()  => (
    <div>
        <h2>Add an event type</h2>
        <EventTypeForm />
    </div>
);

export const EditEventType = ({ match })  => (
    <div>
        <h2>Edit an event type</h2>
        <EventTypeForm id={match.params.id} />
    </div>
);

export const ListEventTypes = (props) => (
    <div>
        <h2>Event Types</h2>
        <EventTypeList {...props} />
    </div>
)
