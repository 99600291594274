import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import Auth from '../Auth/Auth'
import PageAPI from './PageAPI';
import UserAPI from '../Users/UserAPI';
import FilterSelect from '../Components/FilterSelect'
import Loading from '../Components/Loading';
import SortCard from '../Components/SortCard'
import { showVisibilityState } from '../Utilities/format';
import { getRefineOptions } from '../Utilities/refineOptions';
import NotificationMessage from "../Components/NotificationMessage";

const refineOptionList = ['sortBy', 'sortDir', 'filterBy', 'filterId'];
const filterBy = ['author', 'status'];
const filterByIndices = { author: 0, status: 1 };

class PageList extends Component {
    constructor(props) {
        super(props);
        let message = '';
        const refineOptions = getRefineOptions('page', refineOptionList);
        if (this.props.location.state && this.props.location.state.deletedObject) {
            message = 'Page successfully deleted';
        }
        this.state = {
            pages: [],
            message,
            sortType: { attribute: 'publishedOn', direction: 'desc' },
            authors: [{ "id": "none", "name": "" }],
            statuses: [{ "id": "draft", "name": "Draft" }, { "id": "published", "name": "Published" }, { "id": "scheduled", "name": "Scheduled" }],
            sortBy: 'title',
            sortDir: 'ASC',
            filterBy,
            filterId: ['All', 'All'],
            refineOptions,
        }
        this.notify = React.createRef();
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    async componentDidMount() {
        document.title = `Pages | ${process.env.REACT_APP_TITLE}`;

        for (const i in refineOptionList) {
            const option = refineOptionList[i];
            const key = 'page' + _.upperFirst(option);
            if (!localStorage[key]) {
                localStorage.setItem(key, this.state[option]);
            }
            else if (this.state[option] !== this.state.refineOptions[option]) {
                await this.setStateAsync({ [option]: this.state.refineOptions[option] });
            }
        }

        this.fetchPages()
        .then(() => this.setState({mounted: true}))
        .catch(err => {
          this.setState({mounted: true})
          this.sendNotification(`Could not load pages (${err.response.status})`, "error")
        });

        UserAPI.getUsers()
        .then(res => {
            this.setState({ authors: this.state.authors.concat(res.users)});
        })
        .catch(err => this.sendNotification(`Could not load author options (${err.response.status})`, "error"));

    }

    fetchPages = async () => {
      return new Promise((resolve, reject) => {
        PageAPI.filterPages()
        .then(res => {
            this.setState({ pages: res.pages });
            resolve(res.pages)
        })
        .catch(err => {reject(err)});
      })
    }

    setFilter = (value, type) => {
        const index = filterByIndices[type];
        const ids = this.state.filterId;
        let newIds = ids;
        newIds[index] = value;
        localStorage.setItem('pageFilterId', newIds);
        this.fetchPages()
            .then(() => {
                this.setState({ filterId: newIds });
            })
            .catch((err) => {
                localStorage.setItem('pageFilterId', this.state.filterId);
            })
    }

    onSortAttribute = (attribute, direction) => {
        if (this.state.sortBy === attribute && this.state.sortDir === direction) return;

        localStorage.setItem('pageSortBy', attribute);
        localStorage.setItem('pageSortDir', direction);

        this.fetchPages()
            .then(res => this.setState({ sortBy: attribute, sortDir: direction }))
            .catch(err => {
                localStorage.setItem('pageSortBy', this.state.sortBy);
                localStorage.setItem('pageSortDir', this.state.sortDir);
            });
    }

    sendNotification(message, type) {
      if(this.notify.current) {
        this.notify.current.sendMessage(message, type)
      }
    }
    
    render() {
        const Header = () => (
            <tr>
                <th>Title</th>
                <th>Author</th>
                <th>Publish Date</th>
            </tr>
        );

        const Row = (page) => (
            <tr>
                <td><strong><Link to={`/pages/${page.id}/edit`}>{page.title}</Link></strong></td>
                <td>{page.author ? page.author.name : ''}</td>
                <td>{showVisibilityState(page.publishedOn)}</td>
            </tr>
        );

        const sortAttributes = ['title', 'author', 'publishedOn'];
        const sortType = { attribute: this.state.sortBy, direction: this.state.sortDir }
        const sortCards = sortAttributes.map((attribute) => <SortCard attribute={attribute} sortType={sortType} onSortAttribute={this.onSortAttribute} key={`sortcard${attribute}`} />);

        return (
            <div>
                {!this.state.mounted && <Loading />}

                <div className="grid-x">
                  <div className="cell small-4">
                    { Auth.hasRole(['admin', 'editor', 'writer', 'contributor']) ?
                      (<Link to="/pages/new" className="button">Add Page</Link>) : null
                    }
                  </div>
                  <div className="cell small-8">
                    <NotificationMessage message={this.state.message} small={true} ref={this.notify} />
                  </div>
                </div>
                <div> {this.state.message}</div>

                <label>
                    Sort by attribute:
                </label>
                <div className="grid-x grid-padding-x">
                    {sortCards}
                </div>

                <div className="grid-x grid-padding-x">
                    <div className="medium-3 cell">
                        <label>Filter by Author:
                            <FilterSelect options={this.state.authors} setSelectedFilter={this.setFilter} type='author' value={this.state.filterId[filterByIndices.author]} />
                        </label>
                    </div>

                    <div className="medium-3 cell">
                        <label>Filter by Status:
                            <FilterSelect options={this.state.statuses} setSelectedFilter={this.setFilter} type='status' value={this.state.filterId[filterByIndices.status]} />
                        </label>
                    </div>
                </div>

                <table>
                    <thead>
                        <Header />
                    </thead>
                    <tbody>
                        {this.state.pages && this.state.pages.map(page => (
                            <Row {...page} key={page.id} />
                        ))}
                    </tbody>
                </table>
            </div>
        )
    };
}

export default PageList;
