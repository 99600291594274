import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import { animateScroll as scroll } from 'react-scroll';

import EditionAPI from '../Editions/EditionAPI';
import LocationAPI from './LocationAPI';
import Loading from '../Components/Loading';
import { ErrorMessage } from '../Components/ErrorMessage';
import PublishModule from "../Components/PublishModule"
import Map from '../Components/Map';
import { SelectField } from '../Components/SelectField';
import { locationSchema } from 'shared';
import history from '../Utilities/history';
import { changesSavedPrompt, removeObjectPrompt, unsavedChangesPrompt, Wrapper } from '../Utilities/formPrompts';
import { currentTimeRounded, formatDateTime } from '../Utilities/format';
import Moment from '../Utilities/moment';
import NotificationMessage from "../Components/NotificationMessage";

const locationFields = {
  name: '',
  address: '',
  room: '',
  latitude: 0.0000,
  longitude: 0.0000,
  search: '',
  parking: '',
  publicTransit: '',
  buildingEntrance: '',
  additionalNotes: '',
  editions: '',
  locationEditionData: '',
  publish: 'draft',
  publishedOn: '',
  publishDate: '',
};

class LocationForm extends Component {
  _mounted = false
  constructor(props) {
    super(props);
    this.state = this.defaultState = {
      data: locationFields,
      id: '',
      message: '',
      editions: [], viewPublishState: false,
      viewPublishDate: false,
      next: null,
      nextReady: false
    };
    this.formik = React.createRef();
    this.unsavedChanges = new Wrapper(false);
    this.submittingFromModal = new Wrapper(false);
    this.nextLocation = history.location;
    this.onSubmit = this.onSubmit.bind(this);
    this.formik = React.createRef();
    this.notify = React.createRef();
  }

  safeSetState(object, func) {
    if (this._mounted) {
      this.setState(object, func)
    }
  }

  componentDidMount() {
    this._mounted = true;
    if (this.props.id) {
      LocationAPI.getLocation(this.props.id)
        .then((res) => {
          if (!this.isCancelled) {
            const location = { ...res.location };
            this.getBuildInformation(location.id, location.publishedOn);
            document.title = `${res.location.name} | Location | ${process.env.REACT_APP_TITLE}`;
            this.search = location.search;
            if (location.editions) location.editions = location.editions.map((edition) => (
              { 'value': edition.id, 'label': edition.name }))
              .sort((a, b) => { return a.label === b.label ? 0 : a.label > b.label ? 1 : -1 });

            if (location.publishedOn !== null) {
              location.publishDate = Moment(location.publishedOn).toDate();
              location.publishedOn = formatDateTime(location.publishedOn);
              location.publish = 'publish'
            } else {
              const now = currentTimeRounded();
              location.publishDate = Moment(now).toDate();
              location.publishedOn = '';
              location.publish = 'draft';
            }

            this.safeSetState({ data: location, id: res.location.id, mounted: true }, () => {
              // send message when brand new blog post after history push happens.
              if (this.state.mounted && history.location.state && history.location.state.notify.message !== null) {
                this.notify.current.sendMessage(history.location.state.notify.message, history.location.state.notify.type);
                history.replace({ state: null });
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.notify.current.sendMessage(err.toString(), 'error')
        });
    } else {
      document.title = `New Location | ${process.env.REACT_APP_TITLE}`;
      const location = { ...this.state.data };
      const now = currentTimeRounded();

      location.publishDate = Moment(now).toDate();
      this.safeSetState({ mounted: true, data: location });
    }

    EditionAPI.getEditions()
      .then(res => {
        this.safeSetState({ editions: this.state.editions.concat(res.editions) });
      })
      .catch((err) => {
        console.log(err)
        this.notify.current.sendMessage(err.toString(), 'error')
      });

    this.unblock = history.block((nextLocation) => {
      this.nextLocation = nextLocation;
      if (this.unsavedChanges.get()) {
        unsavedChangesPrompt(nextLocation, this.unsavedChanges, this.formik.current, this.submittingFromModal)
        return false;
      }
      else return true;
    });
  }

  componentWillUnmount() {
    this._mounted = false
    this.isCancelled = true;
    this.unblock();
  }

  cleanLocation(values) {
    let url = (this.state.id) ? this.state.id : '';
    let method = (this.state.id) ? 'put' : 'post';

    const location = { ...values };
    if (location.editions) location.editions = location.editions.map(e => e.value)
    this.search = location.search;

    if (location.search === "") {
      location.longitude = 0.000
      location.latitude = 0.000
    }

    if (location.publish === 'publish' || (!location.publish && location.publishedOn)) {
      location.publishedOn = Moment(location.publishDate, 'L HH:mm:ss').toISOString();
    } else {
      location.publishedOn = null;
    }
    delete location.publish;

    return { url, method, location };
  }

  onSubmit(values) {
    const { url, method, location } = this.cleanLocation(values);

    LocationAPI.saveLocation(url, method, location)
      .then(data => {
        this.notify.current.sendMessage(data.message, 'success')
        this.unsavedChanges.set(false);
        this.safeSetState({ data: values, id: data.location.id, message: data.message });
        scroll.scrollToTop();
        this.getBuildInformation(data.location.id, data.location.publishedOn);
        history.replace({
          pathname: `/locations/${this.state.id}/edit`,
          state: { notify: { message: data.message, type: 'success' } }
        });
      })
      .catch(error => {
        this.notify.current.sendMessage(error.toString(), 'error')
        scroll.scrollToTop();
      })
      .then(() => {
        if (this.submittingFromModal.get()) {
          changesSavedPrompt(this.nextLocation, this.unsavedChanges);
        }
        this.submittingFromModal.set(false);
      });
  }

  revertChanges = () => {
    this.formik.current.resetForm();
    this.notify.current.sendMessage("Reverted changes", 'warning');
    document.getElementById('pac-input').value = this.search;
    scroll.scrollToTop();
  }

  updateViewPublishState = () => {
    this.safeSetState((state, props) => ({
      viewPublishState: !state.viewPublishState
    }));
  };

  updateViewPublishDate = () => {
    this.safeSetState((state, props) => ({
      viewPublishDate: !state.viewPublishDate
    }));
  };

  publishChanges = () => {
    const values = this.formik.current.values;
    const { url, method, location } = this.cleanLocation(values);

    LocationAPI.saveLocation(url, method, location)
      .then(data => {
        this.unsavedChanges.set(false);
        this.notify.current.sendMessage(data.message, 'success');

        LocationAPI.publishLocation(data.location.id)
          .then(publishData => {
            this.safeSetState({
              data: values,
              id: data.location.id,
            });
            this.notify.current.sendMessage(publishData.message, 'success');
            scroll.scrollToTop();
            this.getBuildInformation(values.id, values.publishedOn);
          })
          .catch(error => {
            this.safeSetState({
              data: values,
              id: data.location.id
            });
            scroll.scrollToTop();
            this.notify.current.sendMessage(`Publish ${error.toString()}`, 'error');
          });
      })
      .catch(error => {
        this.notify.current.sendMessage(error.toString(), 'error')
        scroll.scrollToTop();
      });
  }

  removeLocation = () => {
    removeObjectPrompt(
      'location',
      LocationAPI.deleteLocation,
      '/locations',
      this.state.id,
      (message) => this.notify.current.sendMessage(message, "error")
    );
  }

  createLocation = () => {
    this.safeSetState({
      data: locationFields,
    })
    history.replace('/locations/new');
  }

  onGetLocationData = (values) => {
    let data = { ...values.data }
    data.address = values.address;
    data.latitude = values.latitude;
    data.longitude = values.longitude;
    data.search = values.search;
    this.safeSetState({ data });
  }

  getBuildInformation = (id, date) => {
    const momentDate = Moment(date)
    if (momentDate.isValid()) {
      LocationAPI.getLocationBuilds(id, momentDate.format())
        .then(res => {
          this.safeSetState({ next: res.next, nextReady: true });
        })
        .catch(err => {
          this.notify.current.sendMessage(err.toString(), 'error')
        })
    }
  };

  renderForm = ({ dirty, values, handleSubmit, handleChange, errors, setFieldValue, setFieldTouched, isSubmitting }) => {
    this.unsavedChanges.set(dirty && !isSubmitting);
    const editionOptions = this.state.editions && this.state.editions.map(edition => (
      { 'value': edition.id, 'label': edition.name }
    ))
    return (
      <Form>
        <div className="grid-x grid-padding-x">
          <div className="cell small-8">
            {this.state.id && <button type="button" className="hollow button" onClick={this.createLocation}>New Location</button>}
            <label>Name</label>
            <Field type="text" name="name" />
            <ErrorMessage name='name' />

            <label>Room</label>
            <Field type="text" name="room" />

            <label>Editions</label>
            <Field name='editions' options={editionOptions} component={SelectField} placeholder='Choose an edition by typing or using the dropdown...' />

            <label>Parking information</label>
            <Field component="textarea" name="parking" />
            

            <label>Public transit</label>
            <Field component="textarea" name="publicTransit" />

            <label>Building entrance</label>
            <Field component="textarea" name="buildingEntrance" />

            <label>Additional notes</label>
            <Field component="textarea" name="additionalNotes" />

            {values.address && values.address !== '' ?
                <>
                  <label>Address</label>
                  <Field component="textarea" name="address" />
                </>
              : <Field type="hidden" name="address" />}

            <Field type="hidden" name="latitude" value={this.state.data.latitude} />
            <Field type="hidden" name="longitude" value={this.state.data.longitude} />
            <Field type="hidden" name="search" value={this.state.data.search} />

            <Map
              values={values}
              search={this.state.data.search}
              latitude={this.state.data.latitude}
              longitude={this.state.data.longitude}
              onGetLocationData={this.onGetLocationData}
              setFieldValue={setFieldValue}
            />
          </div>
          <div className="cell small-4  sidebar">
            <PublishModule
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              values={values}
              publishChanges={this.publishChanges}
              revertChanges={this.revertChanges}
              removeObject={this.removeLocation}
              objectId={this.state.id}
              next={this.state.next}
              nextReady={this.state.nextReady}
              currentPublishState={values.publish}
            />

            <NotificationMessage message={this.state.message} ref={this.notify} />

          </div>
        </div>
      </Form>
    )
  };

  render() {
    return (
      <div>
        {!this.state.mounted && <Loading />}

        {this.state.data ? (
          <Formik initialValues={this.state.data}
            enableReinitialize={true}
            onSubmit={this.onSubmit}
            validationSchema={locationSchema}
            innerRef={this.formik}
          >
            {this.renderForm}
          </Formik>
        ) : null}
      </div>
    )
  };
}

export default LocationForm;
