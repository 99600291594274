import React from "react";

import { EventForm, EventList } from "./index"

export const AddEvent = ()  => (
    <div>
        <h2>Add an event</h2>
        <EventForm />
    </div>
);

export const EditEvent = ({ match })  => (
    <div>
        <h2>Edit an event</h2>
        <EventForm id={match.params.id} />
    </div>
);

export const ListEvents = (props) => (
    <div>
        <h2>Events</h2>
        <EventList {...props}/>
    </div>
)
