import axios from "axios";
import saveError from "../Utilities/saveError";
import { createQueryString } from "../Utilities/refineOptions";

const refineOptionList = [
  "keyword",
  "sortBy",
  "sortDir",
  "filterBy",
  "filterId"
];
const API_URL = "/api/events/";
let singleConfig = { params: { relations: ['speakers', 'eventType', 'edition', 'location', "featuredImage"] } };
let config = {
  params: { relations: ["speakers", "eventType", "edition"] }
};

const Event = {
  getEvents: async (relation = config) => {
    return new Promise((resolve, reject) => {
      axios(API_URL, relation)
      .then(response => resolve(response.data))
      .catch(error => {
        saveError(error, reject)
      });
    })
  },

  getEvent: async id => {
    return new Promise((resolve, reject) => {
      axios(API_URL + id, singleConfig)
        .then(response => resolve(response.data))
        .catch(error => {
          saveError(error, reject)
        });
    })
  },

  filterEvents: async () => {
    const queryString = createQueryString("event", refineOptionList);

    return new Promise((resolve, reject) => {
      axios.get(API_URL + queryString, config)
        .then(response => resolve(response.data))
        .catch(error => {
          saveError(error, reject)
        });
    })
  },

  deleteEvent: async id => {
    return new Promise((resolve, reject) => {
      axios.delete(API_URL + id)
        .then(response => resolve(response.data))
        .catch(error => {
          saveError(error, reject)
        });
    })
  },

  saveEvent: async (method, url, values) => {
    return new Promise((resolve, reject) => {
      axios(API_URL + url, {
        method: method,
        headers: {
          "Content-Type": "application/json"
        },
        data: JSON.stringify(values)
      })
        .then(response => resolve(response.data))
        .catch(error => {
          saveError(error, reject);
        });
    })
  },

  getEventBuilds: async (id, after = null) => {
    return new Promise((resolve, reject) => {
      axios(`${API_URL}build/${id}${after !== null ? "?after=" + after : ""}`)
        .then(response => resolve(response.data))
        .catch(error => {
          saveError(error, reject)
        });
    })
  },

  publishEvent: async id => {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}publish/${id}`)
        .then(response => resolve(response.data))
        .catch(error => {
          saveError(error, reject)
        });
    })
  },
};

export default Event;
