import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class PublishText extends Component {

    render() {
        return (
            <>
                <div className="sidebar__section">
                    {this.props.nextReady && this.props.next && this.props.id && this.props.currentPublishState === 'publish' && (
                        <div className="spacer-bottom  sidebar__message">Your changes will appear on the website with the next build scheduled on{" "}
                            {Array.isArray(this.props.next) ?
                                (
                                    <>
                                        {this.props.next.map((next, idx) => <strong key={idx}>{next}</strong>)}
                                    </>
                                ) :
                                <strong>{this.props.next}</strong>
                            }
                            {" "}<Link to="/build">(build info)</Link>
                        </div>
                    )}
                    {this.props.nextReady && !this.props.next && this.props.id && this.props.currentPublishState === 'publish' && (
                        <div className="spacer-bottom  sidebar__message">There is no build scheduled. Your changes will not show on the website.</div>
                    )}
                </div>
            </>
        )
    }
}