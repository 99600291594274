import React, { Component, Fragment } from 'react';

import '../Styles/app.scss';
import Auth from '../Auth/Auth';

class Home extends Component {
    componentDidMount(){
      document.title = `Home | ${process.env.REACT_APP_TITLE}`
  }

  login = () => {
    Auth.login();
  }

  changeEmail = () => {
    Auth.forceLogout();
  }

  render() {
    return (
      <Fragment>
        <div className="container">
          <h2>{process.env.REACT_APP_TITLE}</h2>
          {
            Auth.isAuthenticated() && (
                <h4>
                  You are logged in!
                </h4>
              )
          }
          {
            !Auth.isAuthenticated() && (
              <button type="button" className="button" onClick={this.login} >
                Log In
              </button>
              )
          }
          {
            Auth.getErrorMessage() && (
              <>
                <p>{Auth.getErrorMessage()}</p>
                <button type="button" className="button" onClick={this.changeEmail} >
                  Change email
                </button>
              </>
            )
          }
        </div>
      </Fragment>
    );
  }
}

export default Home;
